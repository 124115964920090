import React, {useState, useEffect,useRef} from 'react';
import axios from 'axios';
import {useNavigate,useParams, useRouteLoaderData} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
import {
    Card,
    Image,
    Navbar,
    Nav,
    Button,
    ButtonGroup,
    Offcanvas,
    Form,
    Collapse,
    InputGroup,
    FormGroup,
    Container,
    FormControl,
    Carousel,
    Overlay,
    Tooltip,
    Modal

} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagram, faFacebook, faGithub, faSnapchat, faTwitter, faReddit,faTelegram, faWhatsapp,faSpotify,faDiscord, faLinkedin, faTiktok, faYoutube, faKickstarter, faPaypal, faSoundcloud, faTwitch,faTumblr,faSteam,faPinterest,faEtsy,faAmazon, faGoogle} from '@fortawesome/free-brands-svg-icons';
import { FaFacebook, FaInstagram, FaGithub, FaTwitter, FaWhatsapp, FaReddit, FaTelegram, FaSpotify, FaDiscord, FaSnapchat, FaLinkedin,FaTiktok, FaYoutube, FaKickstarter, FaPaypal, FaSoundcloud,FaTwitch, FaTumblr,FaSteam,FaPinterest,FaEtsy,FaAmazon, FaLink,FaCheck, FaLock, FaLockOpen, FaTemperatureLow } from "react-icons/fa";
import {faPencil,faPlus,faCirclePlus,faCheck, faXmark, faSpinner, faLink, faEdit, faMoneyBill, faSquarePlus } from '@fortawesome/free-solid-svg-icons'
import { BsBank, BsFillGearFill } from "react-icons/bs";
import {IoMdColorPalette} from "react-icons/io";
import {MdPhotoCamera} from "react-icons/md";
import {CiSquarePlus} from "react-icons/ci";
import {BiUser} from "react-icons/bi";
import {BsCheckCircleFill} from "react-icons/bs";
import {GrContact, GrGallery} from "react-icons/gr";
import {MdCancel} from "react-icons/md";
import imageCompression from 'browser-image-compression';
import {ImUnlocked} from "react-icons/im";
import { ToastContainer, toast } from 'react-toastify';
import {MdBusinessCenter} from "react-icons/md";
import './UserDashboard.css';
import firebase from "firebase/app";
import 'firebase/auth'
import "firebase/storage";
import {ref,uploadBytes, getDownloadURL} from 'firebase/storage';
import {storage} from './firebase-config';
import { loadStripe } from '@stripe/stripe-js';
import AvatarEditor from 'react-avatar-editor';
import icon from './icon.svg';
import icon2 from './icon2.svg';
import PinInput from 'react-pin-input';
import { set } from 'mongoose';
import e from 'cors';






function UserDashboard(profile) {
    
    const [authorized,
        setAuthorized] = useState(false);
    const [videoUrl,setVideoUrl] = useState();
    const [contactPinSet,setContactPinSet] = useState(false);
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const [type, setType] = useState("");
    const [title, setTitle] = useState("");
    const [countPhoto,setCountPhoto] = useState(0);
    const [firmName,setFirmName] = useState('');
    const [personalFirstName,setPersonalFirstName]= useState('');
    const [personalLastName,setPersonalLastName] = useState('');
    const [firmAdress,setAdress] = useState('');
    const [firmPost,setFirmPost] = useState('');
    const [jobDisplay,setJobDisplay] = useState('');
    const [firmCity,setFirmCity] = useState('');
    const [firmNip,setNip] = useState('');
    const [firmRegon,setRegon] = useState('');
    const [firmBio,setFirmBio] = useState('');
    const [billing1,setBilling1] = useState('');
    const [billing2, setBilling2] = useState('');
    const [billing3, setBilling3] = useState('');
    const [displayBankLogo1,setDisplayBankLogo1] = useState('');
    const [displayBankLogo2,setDisplayBankLogo2] = useState('');
    const [displayBankLogo3,setDisplayBankLogo3] = useState('');
    const [language,setLanguage] = useState('');
    
    const words = {
      pl: {
        pinLength: 'Twój pin musi zawierać 4 cyfry',
        pinUpdate: 'Zaktualizowano twój PIN!',
        uncorrectContactEmail: 'Niepoprawny format email!',
        saveContactData: 'Dane kontaktowe zostały zapisane!',
        pinReset: 'Zresetowałeś PIN!',
        uncorrectPostlink: 'Podany link nie jest prawidłowy!',
        savePostlink: 'Post został zaktualizowany',
        firmName: 'Nazwa firmy',
        firmButtonSave: 'Zapisz',
        firmButtonCancel: 'Anuluj',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        firmBio: 'Opis firmy',
        firmAddress: 'Adres',
        firmStreet : 'Ulica',
        addJob : "Dodaj stanowisko",
        
        billing1: 'Rachunek bankowy nr 1',
        billing2: 'Rachunek bankowy nr 2',
        billing3: 'Rachunek bankowy nr 3',
        ownLinkPlaceholder : "Własny link 1",
        ownLink1Placeholder : "Własny link 2",
        firmHeaderValidation : "Za duża ilość znaków",
        saveFirmHeader : "Nagłówek firmy został zaktualizowany!",
        photo2Size : "Zdjęcie jest za duże",
        photo3Size : "Zdjęcie jest za duże",
        pictureDelete : "Zdjęcie zostało usunięte",
        blockVisitor : "Twoja wizytówka została zablokowana",
        unBlockVisitor : "Twoja wizytówka została odblokowana",
        savePersonalData : "Dane personalne zostały zaktualizowane",
        saveCustomLink : "Drugi link został zaktualizowany",
        nullLink : "Link nie może być pusty",
        validCustomLink : "Nieprawidłowy format linku",
        maxLengthLink : "Link może mieć maksymalnie 16 znaków",
        minLengthLink : "Link musi mieć minimalnie 4 znaki",
        existLink : "Link jest już zajęty",
        pictureSize : "Zdjęcie jest za duże",
        pictureUpdate : "Zdjęcie zostało zaktualizowane",
        navbarEdit : "Edytuj wizytówkę",
        logout : "Wyloguj",
        edit : "Edytuj",
        contactEditButton : "Edytuj kontakt",
        linkEditButton : "Edytuj linki",
        personalData : "Dane personalne",
        firmData : "Dane firmy",
        profilePicture : "Zdjęcie profilowe",
        changePhoto : "Zmień zdjęcie",
        choosePhoto : "Wybrano zdjęcie",
        deletePhoto : "Usuń zdjęcie",
        addPhoto : "Dodaj zdjęcie",
        noChoosePhoto : "Nie wybrano zdjęcia",
        save : "Zapisz",
        bio : "Opis",
        delete : "Usuń",
        addFirmName : "Dodaj nazwę firmy",
        addPersonalName : "Dodaj imię",
        addLastName : "Dodaj nazwisko",
        addFirmBio : "Dodaj opis firmy",
        addAddress : "Dodaj adres",
        addNip : "Dodaj NIP",
        addRegon : "Dodaj Regon",
        addBilling1 : "Dodaj rachunek bankowy 1",
        addBilling2 : "Dodaj rachunek bankowy 2",
        addBilling3 : "Dodaj rachunek bankowy 3",
        links : "Linki",
        currentLink : "Obecny link wizytówki",
        copyClick : "Kliknij link, aby go skopiować",
        copied : "Skopiowano link!",
        changeLink : "Zmień link na wygenerowany lub personalizowany",
        chooseLink : "Wybierz link",
        generatedLink : "Generowany",
        personalLink : "Personalizowany",
        generate : "Generuj",
        changeHandler : "Zmień",
        placeholderCustom : "Personalizuj końcówkę adresu https://tagu.pl/users/",
        linkIcons : "Ikonki linków",
        addIcon : "Dodaj ikonkę",
        own : "Własny 1",
        own1 : "Własny 2",
        contact : "Kontakt",
        buttonContact : "Przycisk kontakt",
        toggleContact : "Powyżej możesz włączyć lub wyłączyć przycisk kontakt",
        secureContact : "Zabezpieczony kontakt",
        resetPin : "Resetuj hasło",
        savePin : "Zapisz PIN",
        phone : "Telefon",
        company : "Firma/Organizacja",
        jobtitle : "Stanowisko",
        url : "Link URL",
        photoAndOthers : "Zdjęcia i inne",
        firmHeader : "Nagłówek firmy",
        photos : "Zdjęcia",
        sectionPhoto : "Sekcja zdjęć",
        toggleGallery : "Powyżej możesz włączyć lub wyłączyć galerię zdjęć",
        photo : "Zdjęcie",
        posts : "Posty",
        videos : "Filmy",
        igpost : "Post na Instagramie",
        ytvideo : "Film na Youtube",
        togglePost : "Powyżej możesz włączyć lub wyłączyć post na Instagramie",
        toggleYt : "Powyżej możesz włączyć lub wyłączyć film z Youtube'a",
        ytlink : "Link do filmu z youtube'a",
        iglink : "Link do postu z instagrama",
        settings : "Ustawienia",
        visitorSettings : "Ustawienia widoku",
        userView : "Widok prywatny",
        firmView : "Widok firmowy",
        togglePersonalChange : "Zmiana wyglądu wizytówki na personalny",
        togglePersonalText : "Czy na pewno chcesz zmienić wygląd swojej wizytówki? Spowoduje to zresetowanie twoich firmowych danych",
        toggleFirmChange : "Zmiana wyglądu wizytówki na firmowy",
        toggleFirmText : "Czy na pewno chcesz zmienić wygląd swojej wizytówki? Spowoduje to zresetowanie twoich danych użytkownika",
        secure : "Zastrzeż wizytówkę",
        secureText : "Zgubiłeś swoją wizytówkę lub nie chcesz, żeby była ona dostępna - zastrzeż ją, klikając przycisk poniżej",
        unSecure : "Odblokuj wizytówkę",
        toggleBlock : "Zastrzeż wizytówkę",
        toggleBlockText : "Czy na pewno chcesz zastrzec swoją wizytówkę? Nie będzie ona widoczna dla żadnego odwiedzającego użytkownika",
        toggleUnblock : "Odblokuj wizytówkę",
        toggleUnblockText : "Czy na pewno chcesz odblokować swoją wizytówkę? Będzie ona z powrotem widoczna dla odwiedzających",
        block : "Zastrzeż",
        unblock : "Odblokuj",
        language : "Preferowany język",
        languageChoose : "Poniżej możesz wybrać preferowany język swojej wizytówki.",
        videos : "Filmy",
        incorrectYT : 'Nieprawidłowy link',
        saveVideoLink : 'Film został zapisany',
        firmPost : 'Kod pocztowy',
        firmCity : 'Miasto',
        generateLinkError : "Musisz najpierw wygenerować link!",
        step1Configuration : 'Konfiguracja wizytówki',
        step1Text : 'Przejdź do następnego etapu, aby dopasować zawartość, oraz wygląd Twojej wizytówki do osobistych preferencji. W każdej chwili możesz opuścić konfigurator i edytować wizytówkę w panelu edycji.',
        changeStep : 'Przejdź dalej',
        step2Configuration : 'Wybór widoku',
        step2Text : 'Wybierz preferowany widok swojej wizytówki',
        step3Configuration : 'Wybór motywu',
        step3Text : 'Wybierz motyw, który przyciągnie Twoje zainteresowanie i podkreśli Twój osobisty styl.',
        step4Configuration : 'Dane personalne',
        step4Text : 'Zacznij od podstawowych danych personalnych. W panelu edycji znajdziesz więcej możliwości, aby uzupełnić dodatkowe informację.',
        step5Configuration : 'Zdjęcie profilowe',
        step5Text : "Prześlij swoje zdjęcie profilowe i dostosuj je dzięki funkcji przycinania, aby idealnie pasowało dla Twojej wizytówki",
        step6Configuration : "Ustawienia kontaktu",
        step6Text : "Poniżej możesz umożliwić odwiedzającym Twojej wizytówki zapisanie Twojego kontaktu. Więcej danych kontaktowych do uzupełnienia znajdziesz w panelu edycji.",
        step7Configuration : 'Sekcja zdjęć',
        step7Text : "Poniżej możesz włączyć sekcje zdjęć i dodać unikalne zdjęcia, które będzie się wyświetlać na Twojej wizytówce. Pozostałe dwa zdjęcia możesz dodać w panelu edycji.",
        step8Configuration : 'Gotowe!',
        step8Text : 'Z powodzeniem przeszedłeś przez wszystkie etapy wczesnej konfiguracji Twojej wizytówki. Aby dokończyć konfigurację Twojej wizytówki przejdź do panelu edycji, gdzie czekają na Ciebie dodatkowe możliwości personalizacji, między innymi dodanie linków do portali społecznościowych ',
        cancelConfiguration : "Anuluj",
        cancelText : "Czy chcesz na pewno zrezygnować z etapu wczesnej konfiguracji? Twoje dotychczasowe zmiany zostaną zapisane, a konfigurację będziesz mógł dokonczyć w panelu edycji.",
        cancelStep : "Zakończ",
        cancelBack : 'Wróć',
        reviews : 'Oceny',
        googlereview : 'Oceny google',
        reviewtext : 'Poniżej możesz włączyć lub wyłączyć wyświetlanie ocen z google dla Twojej firmy.',
        reviewus : 'Oceń nas',
        reviewlink : 'Link do strony z ocenami',
        saveReviewlink : 'Zaktualizowano link do strony z ocenami!',
        subscription : 'Subskrypcja',
        status : 'Status subsrypcji',
        expiring : 'Data wygaśnięcia subskrypcji',
        relong : "Przedłuż subskrypcję",
        renew : "Odnów subskrypcję",
        jobContent: 'Stanowisko:'

        // inne tłumaczenia po polsku
      },
      en: {
        pinLength: 'Your PIN must contain 4 digits',
  pinUpdate: 'Your PIN has been updated!',
  uncorrectContactEmail: 'Incorrect email format!',
  saveContactData: 'Contact data has been saved!',
  pinReset: 'You have reset the PIN!',
  uncorrectPostlink: 'The provided link is not valid!',
  savePostlink: 'The post has been updated',
  firmName: 'Company name',
  firmButtonSave: 'Save',
  firmButtonCancel: 'Cancel',
  firstName: 'First name',
  lastName: 'Last name',
  firmBio: 'Company bio',
  firmAddress: 'Address',
  billing1: 'Bank account 1',
  billing2: 'Bank account 2',
  billing3: 'Bank account 3',
  ownLinkPlaceholder: 'Custom link 1',
  ownLink1Placeholder: 'Custom link 2',
  firmHeaderValidation: 'Too many characters',
  saveFirmHeader: 'The company header has been updated!',
  photo2Size: 'The photo is too large',
  photo3Size: 'The photo is too large',
  pictureDelete: 'The picture has been deleted',
  blockVisitor: 'Your business card has been blocked',
  unBlockVisitor: 'Your business card has been unblocked',
  savePersonalData: 'Personal data has been updated',
  saveCustomLink: 'The second link has been updated',
  nullLink: 'The link cannot be empty',
  validCustomLink: 'Invalid link format',
  maxLengthLink: 'The link can have a maximum of 16 characters',
  minLengthLink: 'The link must have at least 4 characters',
  existLink: 'The link is already taken',
  pictureSize: 'The picture is too large',
  pictureUpdate: 'The picture has been updated',
  navbarEdit: 'Edit business card',
  logout: 'Logout',
  edit: 'Edit',
  contactEditButton: 'Edit contact',
  linkEditButton: 'Edit links',
  personalData: 'Personal data',
  firmData: 'Company data',
  profilePicture: 'Profile picture',
  changePhoto: 'Change photo',
  choosePhoto: 'Photo selected',
  deletePhoto: 'Delete photo',
  addPhoto: 'Add photo',
  noChoosePhoto: 'No photo selected',
  save: 'Save',
  bio: 'Bio',
  delete: 'Delete',
  addFirmName: 'Add company name',
  addPersonalName: 'Add first name',
  addLastName: 'Add last name',
  addFirmBio: 'Add company bio',
  addAddress: 'Add address',
  addNip: 'Add NIP',
  addRegon: 'Add REGON',
  addJob : "Add job title",
  addBilling1: 'Add bank account 1',
  addBilling2: 'Add bank account 2',
  addBilling3: 'Add bank account 3',
  links: 'Links',
  currentLink: 'Current business card link',
  copyClick: 'Click the link to copy it',
  copied: 'Link copied!',
  changeLink: 'Change the link to generated or custom',
  chooseLink: 'Choose link',
  generatedLink: 'Generated',
  personalLink: 'Custom',
  generate: 'Generate',
  changeHandler: 'Change',
  placeholderCustom: 'Customize address ending https://tagu.pl/users/',
  linkIcons: 'Link icons',
  addIcon: 'Add icon',
  own: 'Custom 1',
  own1: 'Custom 2',
  contact: 'Contact',
  buttonContact: 'Contact button',
  toggleContact: 'You can enable or disable the contact button above',
  secureContact: 'Secure contact',
  resetPin: 'Reset PIN',
  savePin: 'Save PIN',
  phone: 'Phone',
  company: 'Company/Organization',
  jobtitle : "Job title",
  url: 'URL link',
  photoAndOthers: 'Photos and others',
  firmHeader: 'Company header',
  photos: 'Photos',
  sectionPhoto: 'Photo section',
  toggleGallery: 'You can enable or disable the photo gallery above',
  photo: 'Photo',
  posts: 'Posts',
  videos : 'Videos',
  igpost: 'Instagram post',
  togglePost: 'You can enable or disable the Instagram post above',
  iglink: 'Link to Instagram post',
  settings: 'Settings',
  visitorSettings: 'Visitor settings',
  userView: 'User view',
  firmView: 'Company view',
  togglePersonalChange: 'Change the appearance of the business card to personal',
  togglePersonalText: 'Are you sure you want to change the appearance of your business card? This will reset your company data',
  toggleFirmChange: 'Change the appearance of the business card to company',
  toggleFirmText: 'Are you sure you want to change the appearance of your business card? This will reset your user data',
  secure: 'Secure the business card',
  secureText: 'If you have lost your business card or do not want it to be available, secure it by clicking the button below',
  unSecure: 'Unsecure the business card',
  toggleBlock: 'Block the business card',
  toggleBlockText: 'Are you sure you want to block your business card? It will not be visible to any visiting user',
  toggleUnblock: 'Unblock the business card',
  toggleUnblockText: 'Are you sure you want to unblock your business card? It will be visible to visiting users again',
  block: 'Block',
  unblock: 'Unblock',
  language: "Preferred language",
  languageChoose: "Below you can choose the preferred language of your business card.",
  ytvideo : "Video on Youtube",
  toggleYt : "Above, you can toggle the YouTube video on or off.",
  videos : "Videos",
  incorrectYT : 'Uncorrect link',
  saveVideoLink : '"The video has been saved."',
  firmPost : 'Zip code',
  firmCity : 'City',
  firmStreet : 'Street',
  generateLinkError : 'You need to generate the link first!',
  step1Configuration: 'Business Card Configuration',
  step1Text: 'Proceed to the next step to customize the content and appearance of your business card to your personal preferences. You can exit the configurator anytime and edit your business card in the editing panel.',
  changeStep: 'Continue',
  step2Configuration: 'View Selection',
  step2Text: 'Choose your preferred view for your business card.',
  step3Configuration: 'Theme Selection',
  step3Text: 'Select a theme that catches your interest and reflects your personal style.',
  step4Configuration: 'Personal Information',
  step4Text: 'Start with basic personal information. You will find more options to add additional details in the editing panel.',
  step5Configuration: 'Profile Picture',
  step5Text: "Upload your profile picture and adjust it using the cropping feature to fit perfectly on your business card.",
  step6Configuration: "Contact Settings",
  step6Text: "Below, you can enable visitors to your business card to save your contact information. More contact details can be added in the editing panel.",
  step7Configuration: 'Photo Section',
  step7Text: "You can activate the photo section below and add unique images that will be displayed on your business card. You can add two more images in the editing panel.",
  step8Configuration: 'Done!',
  step8Text: 'You have successfully completed all the steps of the initial configuration of your business card. To finish configuring your business card, proceed to the editing panel where additional customization options, such as adding social media links, await you.',
  cancelConfiguration: "Cancel",
  cancelText: "Are you sure you want to cancel the early configuration step? Your changes so far will be saved, and you can complete the configuration in the editing panel.",
  cancelStep: "Finish",
  reviews : 'Oceny',
  googlereview : 'Oceny google',
  reviewtext : "Below, you can enable or disable the display of Google reviews for your business.",
  reviewus : 'Rate us',
  reviewlink : "Link to the reviews page.",
  saveReviewLink : "Updated link to the reviews page!",
  subscription : 'Subscription',
  status : "Status of subscription",
  expiring : "Expiring date",
  relong : "Extend your subscription",
  renew : "Renew subscription",
  jobContent: 'Job title:'


        // inne tłumaczenia po angielsku
      },
    };

    

    
    
    
    

    const handleChangeLanguage = async (language) => {
      setLanguage(language);
      localStorage.setItem("language", language)
      
      try {
        const token = localStorage.getItem("token"); // Replace with your actual bearer token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    
        const response = await axios.patch('https://api.tagu.pl/updateLanguage', { language }, config);
        // Handle the response data or perform any additional actions
        
      } catch (error) {
        // Handle the error
        
      }
    };
    const [isPremium, setIsPremium] = useState();
    
    
    const [cancelConfiguration,setCancelConfiguration] = useState(false);
    const [stepConfiguration1,setStepConfiguration1] = useState(false);
    const [stepConfiguration2,setStepConfiguration2] = useState(false);
    const [stepConfiguration3,setStepConfiguration3] = useState(false);
    const [stepConfiguration4,setStepConfiguration4] = useState(false);
    const [stepConfiguration5,setStepConfiguration5] = useState(false);
    const [stepConfiguration6,setStepConfiguration6] = useState(false);
    const [stepConfiguration7,setStepConfiguration7] = useState(false);
    const [stepConfiguration8,setStepConfiguration8] = useState(false);
    const [rememberedStep,setRememberedStep] = useState('');
      /*user object with all datas */
    const [user,
      setUser] = useState({});
      const token = localStorage.getItem("token");
        useEffect(() => {
          const token = localStorage.getItem("token");
         
          
          if (token) {
              axios
                  .get("https://api.tagu.pl/protected", {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              })
                  .then(res => {
                      
                      setAuthorized(true);
                      setUser(res.data.user);
                      if(res.data.user.firstName === 'Imię') {
                        setStepConfiguration1(true);
                      }
                      if (res.data.user.contactpin && res.data.user.contactpin !== '') {
                        setContactPinSet(true);
                        
                        const { contactpin, ...updatedUser } = res.data.user;
                        setUser(updatedUser);
                      }
                      
                      setIsPremium(res.data.user.premium);
                      setReviewUrl(res.data.user.reviewUrl);
                      setLanguage(res.data.user.language);
                      
                      
                      

                      
                      if(res.data.user.videoURL) {
                        setVideoUrl(convertEmbedToNormalLink(res.data.user.videoURL));
                      }
                      
                      localStorage.setItem("language", res.data.user.language);
                      localStorage.setItem("keylink",res.data.user.keylink);
                      
                      
                      const { contactname, contactsurname, contactphone, contactemail, company, url, contactpin,jobtitle } = res.data.user;
                      
                      setContactUser({
                          contactname: contactname || '',
                          contactsurname: contactsurname || '',
                          contactphone: contactphone || '',
                          contactemail: contactemail || '',
                          company: company || '',
                          url: url || '',
                          pin: contactpin || '',
                          jobtitle : jobtitle || ''
                      });
                      setFirmName(res.data.user.firmName !== ' ' ? res.data.user.firmName : '')
                      setFirmHeader(res.data.user.firmHeader !== '' ? res.data.user.firmHeader : '');
                      setPersonalFirstName(res.data.user.firstName !== ' ' ? res.data.user.firstName : '')
                      setPersonalLastName(res.data.user.lastName !== ' ' ? res.data.user.lastName : '')
                      setFirmBio(res.data.user.firmBio !== ' ' ? res.data.user.firmBio : '')
                      setAdress(res.data.user.firmAdress !== ' ' ? res.data.user.firmAdress : '');
                      setJobDisplay(res.data.user.jobDisplay !== ' ' ? res.data.user.jobDisplay : '');
                      setFirmPost(res.data.user.firmPost !== ' ' ? res.data.user.firmPost : '');
                      setFirmCity(res.data.user.firmCity !== ' ' ? res.data.user.firmCity : '');
                      setNip(res.data.user.firmNip !== ' ' ? res.data.user.firmNip : '')
                      setRegon(res.data.user.firmRegon !== ' ' ? res.data.user.firmRegon : '')
                      setBilling1(res.data.user.billing1 !== ' ' ? res.data.user.billing1 : '')
                      setBilling2(res.data.user.billing2 !== ' ' ? res.data.user.billing2 : '')
                      setBilling3(res.data.user.billing3 !== ' ' ? res.data.user.billing3 : '')
                      
                      const firstCharIsLetter = isNaN(res.data.user.billing1[0]);

                      const startIndex = firstCharIsLetter ? 5 : 3;
                      const endIndex = firstCharIsLetter ? 9 : 7;

                      const firstCharIsLetter2 = isNaN(res.data.user.billing2[0]);

                      const startIndex2 = firstCharIsLetter2 ? 5 : 3;
                      const endIndex2 = firstCharIsLetter2 ? 9 : 7;

                      const firstCharIsLetter3 = isNaN(res.data.user.billing3[0]);

                      const startIndex3 = firstCharIsLetter3 ? 5 : 3;
                      const endIndex3 = firstCharIsLetter3 ? 9 : 7;

                      const bankCode1 = res.data.user.billing1.substring(startIndex, endIndex);
                      const bankCode2 = res.data.user.billing2.substring(startIndex2,endIndex2);
                      const bankCode3 = res.data.user.billing3.substring(startIndex3,endIndex3);
                      let bankImageUrl1 = '';
                      let bankImageUrl2 = '';
                      let bankImageUrl3 = '';
                      switch (bankCode1) {
                        case '1010' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl1 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl1 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(res.data.user.billing1.startsWith('LT')) {
                            bankImageUrl1 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      setBankLogo1(bankImageUrl1);
                      setDisplayBankLogo1(bankImageUrl1);
                      switch (bankCode2) {
                        case '1010' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl2 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl2 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl2 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(res.data.user.billing2.startsWith('LT')) {
                            bankImageUrl2 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      setBankLogo2(bankImageUrl2)
                      setDisplayBankLogo2(bankImageUrl2);
                      switch (bankCode3) {
                        case '1010' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
                            break;
                          case '1160' : 
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
                            break;
                          case '1020':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
                            break;
                          case '1050':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
                            break;
                          case '1090':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
                            break;
                          case '1140':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
                            break;
                          case '1240':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
                            break;
                          case '1320' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
                            break;
                          case '1870' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
                            break;
                          case '1930' :
                            bankImageUrl3 =
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
                            break;
                          case '1940':
                            bankImageUrl3 =
                              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
                            break;
                          case '2030' :
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
                            break;
                          case '2490' :
                            bankImageUrl3 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
                            break;
                          case '3250' :
                            if(res.data.user.billing3.startsWith('LT')) {
                            bankImageUrl3 = 
                            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
                            }
                          default:
                            break;
                        }
                      setBankLogo3(bankImageUrl3)
                      setDisplayBankLogo3(bankImageUrl3);
                      
                      if (res.data.user.photo1 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }
                    
                      if (res.data.user.photo2 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }
                    
                      if (res.data.user.photo3 !== '') {
                        setCountPhoto(prevCount => prevCount + 1);
                      }
                    
                      

                      
                     
                      
                      
                      
                      
                      
                      
                  })
                  .catch(err => {
                      
                      localStorage.removeItem("token");
                      navigate('/zaloguj');
                  });
          } else {
              navigate('/zaloguj');
          }
          
      }, [navigate]);
    
    const [blockInput,setBlockInput] = useState(false);
    const [unBlockInput,setUnBlockInput]= useState(false);
    
     
    /* alerty */
    const notify2 = (message) => {toast.info(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
    const notify3 = (message) => {
      toast.error(message,{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
    const [selectedOption, setSelectedOption] = useState('');
    const [modalChange,setModalChange] = useState(false);
    const [modal2Change,setModal2Change] = useState(false);
    
    
    
    

    /*input values in edit panel */
    const [tempName,
        setTempName] = useState();
    const [tempLastName,
        setTempLastName] = useState();
    const [tempBio,
        setTempBio] = useState();


/*edit-panel*/

    /*otwarcie poszczególnych skolapsowanych elementów*/
    const [open,
        setOpen] = useState(false);
    const [open1,
        setOpen1] = useState(false);
    const [open2,
        setOpen2] = useState(false);
    const [open3,
        setOpen3] = useState(false);
    const [open4,
        setOpen4] = useState(false);

    const [open5,
        setOpen5] = useState(false);

    /*zamknięcie poszczególnych skolapsowanych elementów*/
    const toggleCollapse = () => {
      setOpen(!open);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
    };
    
    const toggleCollapse1 = () => {
      setOpen(false);
      setOpen1(!open1);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
    };
    
    const toggleCollapse2 = () => {
      setOpen(false);
      setOpen1(false);
      setOpen2(!open2);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
    };
    
    const toggleCollapse3 = () => {
      setOpen(false);
      setOpen1(false);
      setOpen2(false);
      setOpen3(!open3);
      setOpen4(false);
      setOpen5(false);
    };
    
    const toggleCollapse4 = () => {
      setOpen(false);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(!open4);
      setOpen5(false)
    };
    const toggleCollapse5 = () => {
      setOpen(false);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(!open5)
    };


    /*otworzenie edit-panelu*/
    const [showOffcanvas,
        setShowOffcanvas] = useState(false);

    /*otworznenie edit-panelu i skolapsowanych*/
    const openEditPanel = () => {
      setShowOffcanvas(true)
      setOpen(false);
      setOpen1(false);
      setOpen2(false);
      setOpen3(false);
      setOpen4(false);
      setOpen5(false);
    }
    const openPersonalInfo = () => {
        setShowOffcanvas(true);
        setOpen(true);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
    };
    const openLinks = () => {
        setShowOffcanvas(true);
        setOpen(false);
        setOpen1(true);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
    };
    const openContact = () => {
        setShowOffcanvas(true);
        setOpen(false);
        setOpen1(false);
        setOpen2(true);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
    };
    const openOthers = () => {
        setShowOffcanvas(true);
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(true);
        setOpen4(false);
        setOpen5(false);
    };
    


    

    
    
    
    /* stan switcha false/true */
    const [openContactButton, setOpenContactButton] = useState(false);
    const [openVideoButton,setOpenVideoButton] = useState(false);
    const [openReviewButton,setOpenReviewButton] = useState(false);

    const [contactUser, setContactUser] = useState({
        contactname: '',
        contactsurname: '',
        contactphone : '',
        contactemail : '',
        company : '',
        url : '',
        pin : '',
        jobtitle : '',


      });

      const handleContactUserChange = (e) => {
        const { name, value } = e.target;
      
        // Usunięcie wszystkich znaków niebędących cyframi
       
      
          
       
          setContactUser((prevContactUser) => ({
            ...prevContactUser,
            [name]: value
          }));
        
      };
      
      

      const savePinData = async (event) => {
        event.preventDefault();
        const pinVal = pins.join('');
      
        try {
          if (pinVal.length < 4) {
            if (toast) {
              notify3(words[language].pinLength);
            }
            return;
          }
      
          const response = await axios.patch(
            `https://api.tagu.pl/updateContactPin`,
            {
              contactpin: pinVal
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
      
          setUser((prevState) => ({
            ...prevState,
            contactpin: response.data.contactpin
          }));
      
          setContactPinSet(true);
      
          if (toast) {
            notify2(words[language].pinUpdate);
          }
      
          // Obsłuż odpowiedź po zapisaniu pina
        } catch (error) {
          console.error('An error occurred while updating contact pin.', error);
        }
      };
      

      //axios request to update photo image in mongodb

      useEffect(() => {
        const storedShowOffcanvas = localStorage.getItem('showOffcanvas');
        const storedOpen = localStorage.getItem('open');
    
        if (storedShowOffcanvas === 'true') {
          setShowOffcanvas(true);
          if(storedOpen==='contact') {
            openContact();
          }
          else if(storedOpen==='personal') {
            openPersonalInfo();
          }
          else if(storedOpen==='links') {
            openLinks();
          }
          else if(storedOpen==='others') {
            openOthers();
          }
        }
    
        
      }, []);


      const [saveCalled, setSaveCalled] = useState(false);

      useEffect(() => {
        const timeout = setTimeout(() => {
          if (!saveCalled) {
            localStorage.removeItem('showOffcanvas');
            localStorage.removeItem('open');
          }
        }, 2000);
    
        return () => clearTimeout(timeout);
      }, [saveCalled]);

      const saveContactData = async (e) => {
        e.preventDefault();
        localStorage.setItem('showOffcanvas', showOffcanvas.toString());
        localStorage.setItem('open', 'contact');
        setTimeout(() => {
            if (!saveCalled) {
              localStorage.removeItem('showOffcanvas');
              localStorage.removeItem('open');
            }
          }, 2000);
        
          if (contactUser.contactemail && !contactUser.contactemail.includes('@')) {
            if(toast) {
            notify3(words[language].uncorrectContactEmail);
            }
            return;
          }
          

          
        
    
        
        try {
          if(toast) {
          notify2(words[language].saveContactData)
          }
          const response = await axios.patch(
            `https://api.tagu.pl/updateContactUser`,
            {
              contactname: contactUser.contactname,
              contactsurname: contactUser.contactsurname,
              contactphone: contactUser.contactphone,
              contactemail: contactUser.contactemail,
              company: contactUser.company,
              url: contactUser.url,
              jobtitle : contactUser.jobtitle
              
              
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              },
            }
          );
            const storedShowOffcanvas = localStorage.getItem('showOffcanvas');
            const storedOpen = localStorage.getItem('open');

            if (storedShowOffcanvas === 'true') {
            setShowOffcanvas(true);
            
            }

            

            
            
              
             
          } catch (error) {
            console.error('An error occurred while updating contact user.', error);
          }

      }
      
      const editPinInput =() => {
        setContactPinSet(false)
        
        
        
      }
      const deletePinValue = () => {
        try {
          const response =  axios.patch(
            `https://api.tagu.pl/updateContactPin`,
            {
              contactpin: ''
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              },
            }
            
          );
          setUser((prevState) => ({
            ...prevState,
            contactpin: '',
            
            
          }));
          setContactPinSet(false);
          if(toast) {
            notify2(words[language].pinReset)
          }
          

          // Obsłuż odpowiedź po zapisaniu pina
        } catch (error) {
          console.error('An error occurred while updating contact pin.', error);
        }

      }
      const [theme,setSelectedTheme] = useState('');
      useEffect(() => {
        if (user._id) {
          const fetchData = async () => {
            try {
              const token = localStorage.getItem('token');
              const themeResponse = await axios.get(`https://api.tagu.pl/getAuthTheme`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

              const { theme } = themeResponse.data;
      
              // Ustawienie tematu jako wybrany temat
              setSelectedTheme(theme);
      
              // Ustawienie odpowiednich stylów karty na podstawie tematu
              if (theme === 'white') {
                setCardStyles({
                  coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlack%20Minimalist%20Linktree%20Background2.png?alt=media&token=37f0f254-9cc3-4d0b-ba54-56060d4675ca',
                  cardBackground: '#EBEBEB',
                  textCardColor: '#001014',
                  buttonContactColor: '#001014',
                  textButtonContactColor: '#EBEBEB',
                  navbarBackground: '#001014',
                  navbarTextColor: '#EBEBEB',
                  editLinksButtonColor: '#001014',
                  profilePictureBorder: '5px solid #001014',
                  iconsColor: '#001014'
                });
              } else if (theme === 'lightgrey') {
                setCardStyles({
                  coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlue%20Watercolor%20Linktree%20Background%20(3).png?alt=media&token=e5c3cf34-bd5a-449e-a987-0e3b765f5baf',
                  cardBackground: '#E1E3D5',
                  textCardColor: '#323358',
                  buttonContactColor: '#323358',
                  textButtonContactColor: '#e1b94b',
                  navbarBackground: '#323358',
                  navbarTextColor: '#e1b94b',
                  editLinksButtonColor: '#323358',
                  profilePictureBorder: '5px solid #323358',
                  iconsColor: '#323358'
                });
              } else if (theme === 'purple') {
                setCardStyles({
                  coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FProjekt%20bez%20nazwy%20(11).png?alt=media&token=4762d3cb-4f26-4aa7-818c-8eb3140e1bbb',
                  cardBackground: '#FCECC9',
                  textCardColor: '#814692',
                  buttonContactColor: '#814692',
                  textButtonContactColor: '#FCECC9',
                  navbarBackground : '#814692',
                  navbarTextColor : '#FCECC9',
                  editLinksButtonColor : '#814692',
                  profilePictureBorder : '5px solid #814692',
                  iconsColor : '#814692'
                });
              }
              else if (theme === 'green') {
                setCardStyles({
                  coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FUntitled%20design%20(5).png?alt=media&token=d141591d-2fca-45d2-bf26-bd094c2c9153',
                  cardBackground: '#F6EDD9',
                  textCardColor: '#567956',
                  buttonContactColor: '#567956',
                  textButtonContactColor: '#E1E3D5',
                  navbarBackground: '#567956',
                  navbarTextColor: '#F6EDD9',
                  editLinksButtonColor: '#567956',
                  profilePictureBorder: '5px solid #567956',
                  iconsColor: '#567956'
                });
              }
              
            } catch (error) {
              console.error('An error occurred while fetching user theme from API.', error);
            }
          };
      
          fetchData();
        }
      }, [user._id]);
      

      

        

        


    
     
      const [contactSpinner, setContactSpinner] = useState(false);
      /* funkcja ktora jest wywolywana w momencie wcisniecia przycisku i zaladowaniu calego komponentu - update'uje wartosc contactswitch w bazie danych od stanu klikniecia przyciska */
      useEffect(() => {
        const handleUpdateContactSwitch = async () => {

          try {
            setContactSpinner(true)
            if(user._id) {
            const response = await axios.patch(`https://api.tagu.pl/updateUserContactSwitch`, {
          openContactButton: openContactButton
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });

           


            setOpenContactButton(response.data.contactswitch);
            
      }
          } catch (error) {
            console.error('An error occurred while updating user contact switch.', error);
          }
        };
    
        const timeout = setTimeout(() => {
          handleUpdateContactSwitch();
          setContactSpinner(false)
        }, 1000); // 
    
        return () => {
          clearTimeout(timeout); // Czyszczenie timera przy odmontowywaniu komponentu
        };
      }, [openContactButton, user._id]);

      const configuratorRadioChange = (event) => {
        if(event.target.value==='userView') {
          setSelectedOption('userView');
          const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
        
          // Wysłanie żądania do API z nagłówkiem uwierzytelniającym
          axios.patch('https://api.tagu.pl/configureVisitorType', { visitorType: event.target.value }, config)
          .then((response) => {
            setUser((prevState) => ({
              ...prevState,
              visitorType: response.data.visitorType,
              
              
            }));
            
            
          })
          .catch((error) => {
            // Obsłuż błąd tutaj
            console.error('Błąd podczas wykonywania żądania:', error);
          });
          
        }
        else {
          setSelectedOption('firmView');
          const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
        
          // Wysłanie żądania do API z nagłówkiem uwierzytelniającym
          axios.patch('https://api.tagu.pl/configureVisitorType', { visitorType: event.target.value }, config)
          .then((response) => {
            setUser((prevState) => ({
              ...prevState,
              visitorType: response.data.visitorType,
              
              
            }));
            
            
          })
          .catch((error) => {
            // Obsłuż błąd tutaj
            console.error('Błąd podczas wykonywania żądania:', error);
          });
        }

      }

      const step1Step2 = () => {
        setStepConfiguration1(false);
        setStepConfiguration2(true);
      }
      const step2Step3 = () => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(true);
      }
      const step3Step4 = () => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(true);
      }
      const step4Step5 = async (e) => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(false);
        setStepConfiguration5(true);
        e.preventDefault()

        try {
        const response = await fetch(`https://api.tagu.pl/updateUsers`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ firstName: tempName, lastName: tempLastName}),
        });
        
        const updatedUser = await response.json();
        setUser(updatedUser);
        
        
        
      } catch (err) {
        console.error(err);
      }
      }
      const step5Step6 = async (e) => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(false);
        setStepConfiguration5(false);
        setStepConfiguration6(true);
        e.preventDefault();
    
        setProfilePictureSpinner(true);
        if (!selectedImage)  {
          setProfilePictureSpinner(false);
          setSpinner(false);
          return;
        }
      
        const file = selectedImage;
        const maxSizeInBytes = 6 * 1024 * 1024; // 8 MB
      
        setSpinner(true);
      
        const canvas = editorRef.current.getImage();
      
        const options = {
          maxSizeMB: 3, // Ustal maksymalny rozmiar po kompresji w megabajtach
          
          alwaysKeepResolution : true, 
        };
      
        try {
          // Utwórz obiekt Blob z danych obrazu w formacie PNG
          const blob = await new Promise((resolve) => {
            canvas.toBlob((blob) => {
              resolve(blob);
            }, "image/png");
          });
      
          const compressedFile = await imageCompression(blob, options);
      
          if (compressedFile.size > maxSizeInBytes) {
            setSelectedImage(null);
            if (toast) {
              notify3(words[language].pictureSize);
            }
            setSpinner(false);
            setProfilePictureSpinner(false);
            return;
          }
      
          const generatedHash = generateRandomHash();
          const fileExtension = selectedImage.name.split('.').pop();
          const newFileName = `${generatedHash}.${fileExtension}`;
      
          const formData = new FormData();
          formData.append('image', compressedFile, newFileName);
      
          axios
            .post('https://api.tagu.pl/upload', formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              const pictureURL = response.data.pictureURL;
      
              setSelectedImage(null);
              if (user._id) {
                axios
                  .patch(
                    'https://api.tagu.pl/updatePicture',
                    { picture: pictureURL },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    user.picture = response.data.picture;
                    setSpinner(false);
                    setProfilePictureSpinner(false);
                   
                  })
                  .catch((error) => {
                    console.error(error);
                    setSpinner(false);
                  });
              }
            })
            .catch((error) => {
              console.error('Error uploading image:', error);
              setSpinner(false);
              setProfilePictureSpinner(false);
            });
        } catch (error) {
          console.error('Error compressing image:', error);
          setSpinner(false);
          setProfilePictureSpinner(false);
        }
      }

      const step6Step7 = async (e) => {
        e.preventDefault();
        
        
        
          if (contactUser.contactemail && !contactUser.contactemail.includes('@')) {
            if(toast) {
            notify3(words[language].uncorrectContactEmail);
            }
            return;
          }
          else {
          
          setStepConfiguration1(false);
          setStepConfiguration2(false);
          setStepConfiguration3(false);
          setStepConfiguration4(false);
          setStepConfiguration5(false);
          setStepConfiguration6(false);
          setStepConfiguration7(true);
          
        
    
        
        try {
          
          const response = await axios.patch(
            `https://api.tagu.pl/updateContactUser`,
            {
              contactname: contactUser.contactname,
              contactsurname: contactUser.contactsurname,
              contactphone: contactUser.contactphone,
              contactemail: contactUser.contactemail,
              company: contactUser.company,
              url: contactUser.url,
              jobtitle : contactUser.jobtitle
              
              
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              },
            }
          );
            

            

            
            
              
             
          } catch (error) {
            console.error('An error occurred while updating contact user.', error);
          }
        }
        

      }
      const step7Step8 = async () => {
        const maxSizeInBytes = 6 * 1024 * 1024; // 6 MB
        const selectedPhoto = confPhoto1;
        setPhoto1Spinner(true);
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(false);
        setStepConfiguration5(false);
        setStepConfiguration6(false);
        setStepConfiguration7(false);
        setStepConfiguration8(true);
        const generatedHash = generateRandomHash();
        const fileExtension = selectedPhoto.name.split('.').pop();
        const newFileName = `${generatedHash}.${fileExtension}`;
      
        const options = {
          maxSizeMB: 3, // Ustal maksymalny rozmiar po kompresji w megabajtach
          
          alwaysKeepResolution : true, 
        };
        if (!confPhoto1) {
          setPhoto1Spinner(false);
          return;
        }
        else {
        try {
          const compressedFile = await imageCompression(selectedPhoto, options);
      
          // Utwórz nowy obiekt pliku skompresowanego
          const compressedPhoto = new File([compressedFile], newFileName, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
      
          if (compressedPhoto.size > maxSizeInBytes) {
            setSelectedImage(null);
            if (toast) {
              notify3(words[language].photo1Size);
            }
            setPhoto1Spinner(false);
            return;
          }
      
          const formData = new FormData();
          formData.append("photo", compressedPhoto);
      
          axios
            .post("https://api.tagu.pl/uploadPhoto", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              const photoURL = response.data.photoURL;
              setUser((prevState) => ({
                ...prevState,
                photo1: photoURL,
              }));
              setCountPhoto((prevCount) => prevCount + 1);
              // Aktualizacja wartości user.photo1 w bazie danych po opóźnieniu
              if (user._id) {
                const updatedData = {
                  photo1: photoURL,
                };
      
                axios
                  .patch("https://api.tagu.pl/updatePhotos", updatedData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(() => {
                    setPhoto1Spinner(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setPhoto1Spinner(false);
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              setPhoto1Spinner(false);
            });
        } catch (error) {
          console.error(error);
          setPhoto1Spinner(false);
        }
      }
      }
      
      const step8Step9 = () => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(false);
        setStepConfiguration5(false);
        setStepConfiguration6(false);
        setStepConfiguration7(false);
        setStepConfiguration8(false);
        setShowOffcanvas(true);
        
      }
      const cancelSteps = () => {
        setStepConfiguration1(false);
        setStepConfiguration2(false);
        setStepConfiguration3(false);
        setStepConfiguration4(false);
        setStepConfiguration5(false);
        setStepConfiguration6(false);
        setStepConfiguration7(false);
        setStepConfiguration8(false);
        setCancelConfiguration(false);
      }

      const backToStep = () => {
        switch (rememberedStep) {
          case "conf1":
            setStepConfiguration1(true);
            setCancelConfiguration(false);
            break;
          case "conf2":
            setStepConfiguration2(true);
            setCancelConfiguration(false);
            break;
          case "conf3":
            setStepConfiguration3(true);
            setCancelConfiguration(false);
            break;
          case "conf4":
            setStepConfiguration4(true);
            setCancelConfiguration(false);
            break;
          case "conf5":
            setStepConfiguration5(true);
            setCancelConfiguration(false);
            break;
          case "conf6":
            setStepConfiguration6(true);
            setCancelConfiguration(false);
            break;
          case "conf7":
            setStepConfiguration7(true);
            setCancelConfiguration(false);
            break;
          case "conf8":
            setStepConfiguration8(true);
            setCancelConfiguration(false);
            break;
          default:
            // Jeśli rememberedStep nie pasuje do żadnego przypadku, możesz obsłużyć to tutaj.
            break;
        }
      
      }

      
    
      const toggleContactButton = () => {
        setOpenContactButton(!openContactButton);
      };
      const [openContactPinButton, setOpenContactPinButton] = useState(false);


      useEffect(() => {
        const handleUpdateContactPinSwitch = async () => {

          try {
            setContactSpinner(true)
            if(user._id) {
            const response = await axios.patch(`https://api.tagu.pl/updateContactPinSwitch`, {
          contactpinswitch: openContactPinButton
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        });

           


            setOpenContactButton(response.data.contactswitch);
            
      }
          } catch (error) {
            console.error('An error occurred while updating user contact switch.', error);
          }
        };
    
        const timeout = setTimeout(() => {
          handleUpdateContactPinSwitch();
          setContactSpinner(false)
        }, 1000); // 
    
        return () => {
          clearTimeout(timeout); // Czyszczenie timera przy odmontowywaniu komponentu
        };
      }, [openContactPinButton, user._id]);

      const toggleContactPinButton = () => {
        setOpenContactPinButton(!openContactPinButton);
      };

      






      

      
    const [openGalleryButton, setOpenGalleryButton] = useState(false);
    
    const toggleGalleryButton = async () => {
        setOpenGalleryButton(!openGalleryButton)
    }
    

    

      const [gallerySpinner, setGallerySpinner] = useState(false);
      useEffect(() => {
        const handleUpdateGallerySwitch = async () => {

          try {
            setGallerySpinner(true)
            const response = await axios.patch(`https://api.tagu.pl/updateUserGallerySwitch`, {
              openGalleryButton: openGalleryButton
            }, {
              headers: {
                Authorization: `Bearer ${token}`
              },

            });

            setOpenGalleryButton(response.data.galleryswitch);
            
          } catch (error) {
            console.error('An error occurred while updating user contact switch.', error);
          }
        };
    
        const timeout = setTimeout(() => {
          handleUpdateGallerySwitch();
          setGallerySpinner(false)
        }, 1000); // 
    
        return () => {
          clearTimeout(timeout); // Czyszczenie timera przy odmontowywaniu komponentu
        };
      }, [openGalleryButton, user._id]);


      const [openPostButton, setOpenPostButton] = useState(false);
      const [postSpinner, setPostSpinner] = useState(false);
      const togglePostButton = async () => {
        setOpenPostButton(!openPostButton)
    }

    const toggleVideoButton = async () => {
      setOpenVideoButton(!openVideoButton)
  }
    const toggleReviewButton = async () => {
      setOpenReviewButton(!openReviewButton)
    }

    

    /* state ktory obsluguje inputy po wcisnieciu przycisku firmowych */
    const [openFirmPanel,setOpenFirmPanel] = useState(false);
    const [inputFirmName,setInputFirmName] = useState(false);
    const [inputPersonalName,setInputPersonalName] = useState(false);
    const [inputPersonalLastName,setInputPersonalLastName] = useState(false);
    const [inputAdress,setInputAdress]  = useState(false);
    const [inputJob,setInputJob] = useState(false);
    const [inputNip,setInputNip] = useState(false);
    const [inputEuNip,setInputEuNip] = useState(false);
    const [inputRegon,setInputRegon] = useState(false);
    const [inputFirmBio,setInputFirmBio] = useState(false);
    const [inputBilling1, setInputBilling1] = useState(false);
    const [inputBilling2,setInputBilling2] = useState(false);
    const [inputBilling3, setInputBilling3] = useState(false);
    /* dane firmowe */
    
    const [enabledNameInput,setEnabledNameInput] = useState(false);
    
    const [enabledAdressInput,setEnabledAdressInput] = useState(false);
    const [enabledJobInput,setEnabledJobInput] = useState(false);
    const [enabledPostInput,setEnabledPostInput] = useState(false);
    const [enabledCityInput,setEnabledCityInput ] = useState(false);
    const [enabledPersonalNameInput,setEnabledPersonalNameInput] = useState(false);
    const [enabledPersonalLastNameInput,setEnabledPersonalLastNameInput] = useState(false)
    const [enabledNipInput,setEnabledNipInput] = useState(false);
    const [enabledEuNipInput,setEnabledEuNipInput] = useState(false);
    const [enabledRegonInput,setEnabledRegonInput] = useState(false);
    const [enabledBioInput,setEnabledBioInput] = useState(false);
    const [enabledBillingInput1,setEnabledBillingInput1] = useState(false);
    const [enabledBillingInput2,setEnabledBillingInput2] = useState(false);
    const [enabledBillingInput3,setEnabledBillingInput3] = useState(false);
    const [bankBilling1,setBankBilling1] = useState('');
    const [bankLogo1,setBankLogo1] = useState('');
    const [bankLogo2,setBankLogo2] = useState('')
    const [bankLogo3,setBankLogo3] = useState('')

    /*KOMPRESJA ZDJEC FUNKCJA*/
    

    /* INPUTY */
    
    
    
    const firmNameInputHandler = () => {
      setInputFirmName(true);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputJob(false)
      setInputNip(false);
      setInputRegon(false);
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      setSelectedButton('firmName');
      
    }
    const firmPersonalNameInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(true);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputJob(false)
      setInputRegon(false);
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('firmPersonalName');
    }
    const firmPersonalLastNameInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(true);
      setInputAdress(false);
      setInputJob(false)
      setInputNip(false);
      setInputRegon(false);
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton("firmPersonalLastName")
    }
    const firmAdressInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(true);
      setInputNip(false);
      setInputJob(false)
      setInputRegon(false);
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('firmAdress')
    }
    const firmNipInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(true);
      setInputRegon(false);
      setInputJob(false)
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('firmNip')
    }
    const firmRegonInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputRegon(true);
      setInputJob(false)
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('firmRegon')
    }
    const firmBioInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputRegon(false);
      setInputFirmBio(true);
      setInputJob(false)
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('firmBio');
    }
    const jobInputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputRegon(false);
      setInputFirmBio(false);
      setInputJob(true)
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(false);
      
      setSelectedButton('jobDisplay');
    }
  
  

  const billing1InputHandler = () => {
    
    setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputRegon(false);
      setInputJob(false)
      setInputFirmBio(false);
      setInputBilling1(true);
      setInputBilling2(false);
      setInputBilling3(false);
    

    setSelectedButton('billing1');
    
    
  };
    
    const billing2InputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputRegon(false);
      setInputFirmBio(false);
      setInputJob(false)
      setInputBilling1(false);
      setInputBilling2(true);
      setInputBilling3(false);
      
      setSelectedButton('billing2')
      
    }
    const billing3InputHandler = () => {
      
      setInputFirmName(false);
      setInputPersonalName(false);
      setInputPersonalLastName(false);
      setInputAdress(false);
      setInputNip(false);
      setInputJob(false)
      setInputRegon(false);
      setInputFirmBio(false);
      setInputBilling1(false);
      setInputBilling2(false);
      setInputBilling3(true);
      
      setSelectedButton('billing3');
      
    }

    const savePersonalName = async (event) => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateUsers', {
          firstName: personalFirstName
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledPersonalNameInput(false);
        setInputPersonalName(true);
        
        setSelectedButton(null);
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    };
    
  
    const savePersonalLastName =  async (event) => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateUsers', {
          lastName: personalLastName
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledPersonalLastNameInput(false);
        setInputPersonalLastName(true);
        
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    

    const deletePersonalViewName = async () => {
      
        try {
          const token = localStorage.getItem("token"); // Twój token uwierzytelniający
      
          const response = await axios.patch('https://api.tagu.pl/updateUsers', {
            firstName: ''
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
      
          // Przetwarzanie odpowiedzi serwera
          
          setUser(response.data);
          setTempName('');


        }
        catch (error) {
          console.error(error);
        }

    
    }

    const deletePersonalViewLastName = async () => {
      
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateUsers', {
          lastName: ''
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setTempLastName('');
      }
      catch (error) {
        console.error(error);
      }

  
    }

    const deletePersonalName= async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateUsers', {
          firstName: ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledPersonalNameInput(false);
        setUser(response.data);
        setInputPersonalName(false);
        setSelectedButton(false);
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }


    }
    const deletePersonalLastName = async ()=>{
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateUsers', {
          lastName: ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledPersonalLastNameInput(false);
        setUser(response.data);
        setInputPersonalLastName(false);
        setSelectedButton(null);
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    function czyPierwszyZnakLitera(tekst) {
      // Sprawdzenie, czy pierwszy znak to litera
      return /^[a-zA-Z]/.test(tekst);
    }
   
    

    const billing1Handler = (event) => {
      const bankNumbers = {
        '1020': 'PKO',
        '1050': 'ING',
        '1090': 'Santander',
        '1140': 'mbank',
        '1240': 'pekao',
        '1940': 'agricole'
      };
    
      let accountNumber = event.target.value;
      
      if(czyPierwszyZnakLitera(accountNumber[0])) {
      accountNumber = accountNumber.replace(/\s/g, ''); // Remove existing spaces
    
      let formattedAccountNumber = '';
      let bankImageUrl1 = '';
    
      
          
       
        formattedAccountNumber = accountNumber.replace(/(.{4})(?=.)/g, '$1 ');
    
        const bankCode = formattedAccountNumber.substring(5, 9);
        const bankName = bankNumbers[bankCode];
    
        switch (bankCode) {
          case '1010' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
              break;
            case '1160' : 
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
              break;
            case '1020':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
              break;
            case '1050':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
              break;
            case '1090':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
              break;
            case '1140':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
              break;
            case '1240':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
              break;
            case '1320' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
              break;
            case '1870' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
              break;
            case '1930' :
              bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
              break;
            case '1940':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
              break;
            case '2030' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
              break;
            case '2490' :
              bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
              break;
            case '3250' :
              if(accountNumber.startsWith('LT')) {
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
              }
            default:
              break;
          }
        setBilling1(formattedAccountNumber);
        setBankLogo1(bankImageUrl1);
      }
      else {
        let bankImageUrl1='';
        
        const inputValue = event.target.value.replace(/\s/g, ''); // Usuwa spacje z wartości wejściowej
        let formattedValue = '';

        if (inputValue.length > 2) {
          formattedValue += inputValue.substring(0, 2) + ' ';
          formattedValue += inputValue.substring(2);
        } else {
          formattedValue = inputValue;
        }

        let output = '';
        let chunkSize = 4; // Liczba cyfr w grupie
        let startIndex = formattedValue.indexOf(' ') + 1 || 2; // Zaczyna od indeksu po spacji lub 2, jeśli spacji nie ma
        for (let i = startIndex; i < formattedValue.length; i += chunkSize) {
          output += formattedValue.substr(i, chunkSize);
          if (i + chunkSize < formattedValue.length) {
            output += ' ';
          }
        }

        const finalValue = formattedValue.substring(0, 2) + (output.length > 0 ? ' ' + output : ''); // Dodaje spację tylko jeśli output nie jest pusty

        setBilling1(finalValue);

        const bankCode = finalValue.split(' ')[1]; // Przypisuje wartość drugiego elementu po podziale na spacje
        

      switch (bankCode) {
        case '1010':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36';
          break;
        case '1160':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636';
          break;
        case '1020':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
          break;
        case '1050':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
          break;
        case '1090':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
          break;
        case '1140':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
          break;
        case '1240':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
          break;
        case '1320':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6';
          break;
        case '1870':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607';
          break;
        case '1930':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d';
          break;
        case '1940':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
          break;
        case '2030':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7';
          break;
        case '2490':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1';
          break;
        case '3250':
          if (accountNumber.startsWith('LT')) {
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2';
          }
          break;
        default:
          break;
      }


      setBankLogo1(bankImageUrl1)
      }
    }
    
      
    
    
    
    
    

    
    
    
    const billing2Handler = (event) => {
      const bankNumbers = {
        '1020': 'PKO',
        '1050': 'ING',
        '1090': 'Santander',
        '1140': 'mbank',
        '1240': 'pekao',
        '1940': 'agricole'
      };
    
      let accountNumber = event.target.value;
      
      if(czyPierwszyZnakLitera(accountNumber[0])) {
      accountNumber = accountNumber.replace(/\s/g, ''); // Remove existing spaces
    
      let formattedAccountNumber = '';
      let bankImageUrl1 = '';
    
      
          
       
        formattedAccountNumber = accountNumber.replace(/(.{4})(?=.)/g, '$1 ');
    
        const bankCode = formattedAccountNumber.substring(5, 9);
        const bankName = bankNumbers[bankCode];
    
        switch (bankCode) {
          case '1010' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
              break;
            case '1160' : 
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
              break;
            case '1020':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
              break;
            case '1050':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
              break;
            case '1090':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
              break;
            case '1140':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
              break;
            case '1240':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
              break;
            case '1320' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
              break;
            case '1870' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
              break;
            case '1930' :
              bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
              break;
            case '1940':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
              break;
            case '2030' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
              break;
            case '2490' :
              bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
              break;
            case '3250' :
              if(accountNumber.startsWith('LT')) {
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
              }
            default:
              break;
          }
        setBilling2(formattedAccountNumber);
        setBankLogo2(bankImageUrl1);
      }
      else {
        let bankImageUrl1='';
        
        const inputValue = event.target.value.replace(/\s/g, ''); // Usuwa spacje z wartości wejściowej
        let formattedValue = '';

        if (inputValue.length > 2) {
          formattedValue += inputValue.substring(0, 2) + ' ';
          formattedValue += inputValue.substring(2);
        } else {
          formattedValue = inputValue;
        }

        let output = '';
        let chunkSize = 4; // Liczba cyfr w grupie
        let startIndex = formattedValue.indexOf(' ') + 1 || 2; // Zaczyna od indeksu po spacji lub 2, jeśli spacji nie ma
        for (let i = startIndex; i < formattedValue.length; i += chunkSize) {
          output += formattedValue.substr(i, chunkSize);
          if (i + chunkSize < formattedValue.length) {
            output += ' ';
          }
        }

        const finalValue = formattedValue.substring(0, 2) + (output.length > 0 ? ' ' + output : ''); // Dodaje spację tylko jeśli output nie jest pusty

        setBilling2(finalValue);

        const bankCode = finalValue.split(' ')[1]; // Przypisuje wartość drugiego elementu po podziale na spacje
        

      switch (bankCode) {
        case '1010':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36';
          break;
        case '1160':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636';
          break;
        case '1020':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
          break;
        case '1050':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
          break;
        case '1090':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
          break;
        case '1140':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
          break;
        case '1240':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
          break;
        case '1320':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6';
          break;
        case '1870':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607';
          break;
        case '1930':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d';
          break;
        case '1940':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
          break;
        case '2030':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7';
          break;
        case '2490':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1';
          break;
        case '3250':
          if (accountNumber.startsWith('LT')) {
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2';
          }
          break;
        default:
          break;
      }


      setBankLogo2(bankImageUrl1)
      }
    };
    

    
    const billing3Handler = (event) => {
      const bankNumbers = {
        '1020': 'PKO',
        '1050': 'ING',
        '1090': 'Santander',
        '1140': 'mbank',
        '1240': 'pekao',
        '1940': 'agricole'
      };
    
      let accountNumber = event.target.value;
      
      if(czyPierwszyZnakLitera(accountNumber[0])) {
      accountNumber = accountNumber.replace(/\s/g, ''); // Remove existing spaces
    
      let formattedAccountNumber = '';
      let bankImageUrl1 = '';
    
      
          
       
        formattedAccountNumber = accountNumber.replace(/(.{4})(?=.)/g, '$1 ');
    
        const bankCode = formattedAccountNumber.substring(5, 9);
        const bankName = bankNumbers[bankCode];
    
        switch (bankCode) {
          case '1010' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
              break;
            case '1160' : 
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
              break;
            case '1020':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
              break;
            case '1050':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
              break;
            case '1090':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
              break;
            case '1140':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
              break;
            case '1240':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
              break;
            case '1320' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
              break;
            case '1870' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
              break;
            case '1930' :
              bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
              break;
            case '1940':
              bankImageUrl1 =
                'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
              break;
            case '2030' :
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
              break;
            case '2490' :
              bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
              break;
            case '3250' :
              if(accountNumber.startsWith('LT')) {
              bankImageUrl1 = 
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
              }
            default:
              break;
          }
        setBilling3(formattedAccountNumber);
        setBankLogo3(bankImageUrl1);
      }
      else {
        let bankImageUrl1='';
        
        const inputValue = event.target.value.replace(/\s/g, ''); // Usuwa spacje z wartości wejściowej
        let formattedValue = '';

        if (inputValue.length > 2) {
          formattedValue += inputValue.substring(0, 2) + ' ';
          formattedValue += inputValue.substring(2);
        } else {
          formattedValue = inputValue;
        }

        let output = '';
        let chunkSize = 4; // Liczba cyfr w grupie
        let startIndex = formattedValue.indexOf(' ') + 1 || 2; // Zaczyna od indeksu po spacji lub 2, jeśli spacji nie ma
        for (let i = startIndex; i < formattedValue.length; i += chunkSize) {
          output += formattedValue.substr(i, chunkSize);
          if (i + chunkSize < formattedValue.length) {
            output += ' ';
          }
        }

        const finalValue = formattedValue.substring(0, 2) + (output.length > 0 ? ' ' + output : ''); // Dodaje spację tylko jeśli output nie jest pusty

        setBilling3(finalValue);

        const bankCode = finalValue.split(' ')[1]; // Przypisuje wartość drugiego elementu po podziale na spacje
        

      switch (bankCode) {
        case '1010':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36';
          break;
        case '1160':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636';
          break;
        case '1020':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
          break;
        case '1050':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
          break;
        case '1090':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
          break;
        case '1140':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
          break;
        case '1240':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
          break;
        case '1320':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6';
          break;
        case '1870':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607';
          break;
        case '1930':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d';
          break;
        case '1940':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
          break;
        case '2030':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7';
          break;
        case '2490':
          bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1';
          break;
        case '3250':
          if (accountNumber.startsWith('LT')) {
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2';
          }
          break;
        default:
          break;
      }


      setBankLogo3(bankImageUrl1)
      }
      }
    
      
    
    


    
  
    
    
  
    
    

    const saveFirmName = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmName', {
          firmName: firmName
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledNameInput(false);
        setInputFirmName(true);
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    };
    
    
    const saveAdress = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmAdress', {
          firmAdress: firmAdress
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledAdressInput(false);
        setInputAdress(true);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    const saveFirmPost = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmPost', {
          firmPost: firmPost
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledPostInput(false);
        setInputAdress(true);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    const saveFirmCity = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmCity', {
          firmCity: firmCity
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        
        setUser(response.data);
        setEnabledCityInput(false);
        setInputAdress(true);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    const saveNip = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateNip', {
          firmNip: firmNip
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledNipInput(false);
        setUser(response.data);
        setInputNip(true);
        setSelectedButton(null);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
    }
    const saveRegon = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateRegon', {
          firmRegon: firmRegon
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledRegonInput(false);
        setUser(response.data);
        setInputRegon(true);
        setSelectedButton(null);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const saveFirmBio = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmBio', {
          firmBio: firmBio
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBioInput(false);
        setUser(response.data);
        setInputFirmBio(true);
        
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }

    const saveJobDisplay = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateJobDisplay', {
          jobDisplay: jobDisplay
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledJobInput(false);
        setUser(response.data);
        setInputJob(true);
        
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const saveBilling1 = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling1', {
          billing1: billing1
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput1(false);
        setUser(response.data);
        
        if(czyPierwszyZnakLitera(billing1[0])) {

        
        const bankCode1 = billing1.substring(5,9);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing1.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo1(bankImageUrl1);
      }
      else {
        const bankCode1 = billing1.substring(3,7);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing1.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo1(bankImageUrl1);

      }
        setInputBilling1(false);
        setSelectedButton(null);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const saveBilling2 = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling2', {
          billing2: billing2
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(czyPierwszyZnakLitera(billing2[0])) {
        const bankCode1 = billing2.substring(5,9);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing2.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo2(bankImageUrl1);
      }
      else {
        const bankCode1 = billing2.substring(3,7);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing2.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo2(bankImageUrl1);

      }
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput2(false);
        setUser(response.data);
        setInputBilling1(false);
        setSelectedButton(null);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }

    const saveBilling3 = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling3', {
          billing3: billing3
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(czyPierwszyZnakLitera(billing3[0])) {
        const bankCode1 = billing3.substring(5,9);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing3.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo3(bankImageUrl1);
      } else{
        const bankCode1 = billing3.substring(3,7);
        
        let bankImageUrl1 = '';
        switch (bankCode1) {
          case '1010' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnarodowybank.png?alt=media&token=6cca9d23-7eb0-4f38-b30c-5e27197abe36'
            break;
          case '1160' : 
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmillenium.jpg?alt=media&token=4b013bdf-1c67-4017-a0d5-ad2c6cec9636'
            break;
          case '1020':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpko.png?alt=media&token=516d3732-2a51-41d3-b8a1-fbdcffea140a';
            break;
          case '1050':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fing.png?alt=media&token=034b9083-2688-4858-afb6-f26535a49b1b&_gl=1*1m5s0ae*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5MTIuMC4wLjA';
            break;
          case '1090':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fsantander.png?alt=media&token=27ae7d1a-72cd-44f6-bb7f-b74a2931c5b4&_gl=1*l4i0vi*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjE0MzYuMC4wLjA.';
            break;
          case '1140':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fmbank.png?alt=media&token=0b9e1295-1058-4a4b-8786-8c81fc005378&_gl=1*b6hx3*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYxNTcxMS4zMC4xLjE2ODY2MTU5NDMuMC4wLjA';
            break;
          case '1240':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FPEKAO.png?alt=media&token=381dba03-0c63-448a-8544-0c0a8860dd80';
            break;
          case '1320' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpocztowy.jpg?alt=media&token=52603c8a-ef02-418b-acda-42cd4e43edd6'
            break;
          case '1870' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fnest.png?alt=media&token=ebc851f0-87da-4747-97e7-7689d789e607'
            break;
          case '1930' :
            bankImageUrl1 =
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fspoldzielczy.jpg?alt=media&token=f82b48ad-c51a-4c42-8d5b-3c5aa310045d'
            break;
          case '1940':
            bankImageUrl1 =
              'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fagricole.png?alt=media&token=f0e55a15-c167-4c29-8473-9ff6616934d9&_gl=1*74wfh5*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjYyMDkzMC4zMS4xLjE2ODY2MjEzMzUuMC4wLjA.';
            break;
          case '2030' :
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fbnpparibas.png?alt=media&token=f8f94a7c-e962-4387-99c6-a5a3adf96cd7'
            break;
          case '2490' :
            bankImageUrl1 = 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Falior.png?alt=media&token=033d2108-0387-43e5-ba5b-5dd91f0450c1'
            break;
          case '3250' :
            if(billing3.startsWith('LT')) {
            bankImageUrl1 = 
            'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Frevolut.png?alt=media&token=e6098c41-fa91-4ba2-8071-241fda3965d2'
            }
          default:
            break;
        }
        setDisplayBankLogo3(bankImageUrl1);

      }
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput3(false);
        setUser(response.data);
        setInputBilling1(false);
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteFirmNameHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmName', {
          firmName : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledNameInput(false);
        setUser(response.data);
        setInputFirmName(false);
        setSelectedButton(null);
        setFirmName('');
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteFirmBioHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmBio', {
          firmBio : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBioInput(false);
        setUser(response.data);
        setInputFirmBio(false);
        setSelectedButton(null);
        setFirmBio('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }

    const deleteJobDisplay = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateJobDisplay', {
          jobDisplay : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledJobInput(false);
        setUser(response.data);
        setInputJob(false);
        setSelectedButton(null);
        setJobDisplay('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteFirmAdressHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmAdress', {
          firmAdress : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledAdressInput(false);
        setUser(response.data);
        setInputAdress(false);
        setSelectedButton(null);
        setAdress('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }

    const deleteFirmPostHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmPost', {
          firmPost : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledPostInput(false);
        setUser(response.data);
        setInputAdress(false);
        setSelectedButton(null);
        setFirmPost('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteFirmCityHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateFirmCity', {
          firmCity : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledCityInput(false);
        setUser(response.data);
        setInputAdress(false);
        setSelectedButton(null);
        setFirmCity('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteFirmNipHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateNip', {
          firmNip : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledNipInput(false);
        setUser(response.data);
        setInputNip(false);
        setSelectedButton(null);
        setNip('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteRegonHandler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateRegon', {
          firmRegon : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledRegonInput(false);
        setUser(response.data);
        setInputRegon(false);
        setSelectedButton(null);
        setRegon('');
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteBilling1Handler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling1', {
          billing1 : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setBankLogo1('');
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput1(false);
        setUser(response.data);
        setBilling1('');
        setInputBilling1(false);
        setSelectedButton(null);
        
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteBilling2Handler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling2', {
          billing2 : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput2(false);
        setUser(response.data);
        setInputBilling2(false);
        setBilling2('')
        setBankLogo2('');
        setSelectedButton(null);
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }
    const deleteBilling3Handler = async () => {
      try {
        const token = localStorage.getItem("token"); // Twój token uwierzytelniający
    
        const response = await axios.patch('https://api.tagu.pl/updateBilling3', {
          billing3 : ' '
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
    
        // Przetwarzanie odpowiedzi serwera
        setEnabledBillingInput3(false);
        setUser(response.data);
        setBilling3('');
        setInputBilling3(false);
        setBankLogo3('');
        setSelectedButton(null);
        
        
        // Aktualizuj stan komponentu, wyświetl komunikat itp.
      } catch (error) {
        // Obsługa błędów
        console.error(error);
      }
      
    }

    

    
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (user._id) {
            const token = localStorage.getItem('token');
            const response = await axios.get(`https://api.tagu.pl/getUserAuthSwitches`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

            const { contactswitch, galleryswitch, postswitch, contactpinswitch, videoSwitch, reviewswitch, visitorType } = response.data;
            setOpenContactButton(contactswitch);
            setOpenGalleryButton(galleryswitch);
            setOpenPostButton(postswitch);
            setOpenVideoButton(videoSwitch);
            setOpenReviewButton(reviewswitch)
            setOpenContactPinButton(contactpinswitch);
            setUser((prevState) => ({
              ...prevState,
              visitorType: visitorType,
              
              
            }));

            

          }
        } catch (error) {
          console.error('An error occurred while fetching user switches from API.', error);
        }
      };
    
      fetchData();
    }, [user._id]);

    const nameInputHandler = () => {
     
    }
    

    useEffect(() => {
      const handleUpdatePostSwitch = async () => {
        try {
          setPostSpinner(true);
          const response = await axios.patch(
            `https://api.tagu.pl/updatePostSwitch`,
            { openPostButton: openPostButton },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          setOpenPostButton(response.data.postswitch);
          
        } catch (error) {
          console.error('An error occurred while updating user post switch.', error);
        }
      };
    
      const timeout = setTimeout(() => {
        handleUpdatePostSwitch();
        setPostSpinner(false);
      }, 1000);
    
      return () => {
        clearTimeout(timeout);
      };
    }, [openPostButton, user._id]);


    useEffect(() => {
      const handleUpdateVideoSwitch = async () => {
        try {
          
          const response = await axios.patch(
            `https://api.tagu.pl/updateVideoSwitch`,
            { openVideoButton: openVideoButton },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          setOpenVideoButton(response.data.videoswitch);
    
        } catch (error) {
          console.error('An error occurred while updating user video switch.', error);
        }
      };

      
    
      const timeout = setTimeout(() => {
        handleUpdateVideoSwitch();
        
      }, 1000);
    
      return () => {
        clearTimeout(timeout);
      };
    }, [openVideoButton, user._id]);

    useEffect(() => {
      const handleUpdateReviewSwitch = async () => {
        try {
          
          const response = await axios.patch(
            `https://api.tagu.pl/updateReviewSwitch`,
            { openReviewButton: openReviewButton },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          setOpenReviewButton(response.data.reviewswitch);
    
        } catch (error) {
          console.error('An error occurred while updating user video switch.', error);
        }
      };

      
    
      const timeout = setTimeout(() => {
        handleUpdateReviewSwitch();
        
      }, 1000);
    
      return () => {
        clearTimeout(timeout);
      };
    }, [openReviewButton, user._id]);

    const [posturl, setPosturl] = useState(user.posturl);
    const [reviewUrl, setReviewUrl] = useState(user.reviewUrl)
    const handlePostURL = (event) => {
      setPosturl(event.target.value);
    };
    const handleVideoURL = (event) => {
      setVideoUrl(event.target.value)
    }
    const handleReviewURL = (event) => {
      setReviewUrl(event.target.value)
    }

    const savePostURL = async (event) => {
      event.preventDefault();
      try {
        const isValidUrl = validateUrl(posturl);

if (!isValidUrl) {
  if(toast) {
  notify3(words[language].uncorrectPostlink);
  }
  return;
}

let modifiedPostUrl = posturl;
if (!posturl.endsWith("/embed")) {
  modifiedPostUrl = posturl.split('?')[0] + "embed";
}

const response = await axios.patch(
  `https://api.tagu.pl/updatePostURL`,
  { posturl: modifiedPostUrl },
  {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
);
    
        setUser((prevState) => ({
          ...prevState,
          posturl: response.data.posturl,
          
          
        }));
        if(toast) {
          notify2(words[language].savePostlink);
        }
      } catch (error) {
        console.error('An error occurred while updating user post URL.', error);
      }
    };

    const saveReviewURL = async (event) => {
      event.preventDefault();
      try {
        
        const response = await axios.patch(
          `https://api.tagu.pl/updateReviewURL`,
          { reviewUrl: reviewUrl },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
            
                setUser((prevState) => ({
                  ...prevState,
                  reviewUrl: response.data.reviewUrl,
                  
                  
                }));
                if(toast) {
                  notify2(words[language].saveReviewlink);
                }
              } catch (error) {
                console.error('An error occurred while updating user post URL.', error);
              }
    };

    function extractVideoId(url) {
      let videoId = '';
    
      // Regular expressions to match different YouTube URL formats
      
      const patterns = [
        /youtu\.be\/([^\?\/]+)/,
        /youtube\.com\/(?:embed|v|watch|shorts)\/?([^\/\&\?]+)|\&v=([^\/\&\?]+)/,
        /youtube\.com\/(?:embed|v|watch)\?.*?(?:\?|&)v=([^&]+)/,
        /youtube\.com\/shorts\/([^\/\&\?]+)/, // Dodane do obsługi "shorts"
      ];
    
      for (let i = 0; i < patterns.length; i++) {
        const match = url.match(patterns[i]);
        if (match && (match[1] || match[2])) {
          videoId = match[1] || match[2];
          break;
        }
      }
    
      if (videoId === '' && (url.includes('youtube.com/watch') || url.includes('youtube.com/shorts'))) {
        const searchParams = new URLSearchParams(new URL(url).search);
        videoId = searchParams.get('v');
      }
    
      return videoId;
    }
    function validateYouTubeUrl(url) {
      const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^/]+\?.*v=|shorts\/)|youtu\.be\/)([^"&?\/\s]{11})$/;
      return youtubeRegex.test(url);
    }
    

    function convertEmbedToNormalLink(embedLink) {
  const videoId = embedLink.match(/\/embed\/(.+)$/)[1];
  return `https://www.youtube.com/watch?v=${videoId}`;
}

    

    const saveVideoURL = async (event) => {
      event.preventDefault();
      try {
        if (!validateYouTubeUrl(videoUrl)) {
          notify3(words[language].incorrectYT)
          return;
        }
    
        let modifiedVideoUrl = videoUrl;
        
        if (!videoUrl.includes("embed")) {
          const videoId = extractVideoId(videoUrl);
          modifiedVideoUrl = `https://www.youtube.com/embed/${videoId}`;
        }
    
        const response = await axios.patch(
          `https://api.tagu.pl/updateVideoURL`,
          { videoURL: modifiedVideoUrl }, // Poprawiony klucz na "videoUrl"
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
    
        setUser((prevState) => ({
          ...prevState,
          videoURL: response.data.videoURL,
        }));
    
        if (toast) {
          notify2(words[language].saveVideoLink);
        }
      } catch (error) {
        console.error('An error occurred while updating user video URL.', error);
      }
    };
    
    

    




    /*Select do typu linku*/
            const [nullEditMenu,
                setNullEditMenu] = useState(false);

            const [openGeneratedLink,
                setOpenGeneratedLink] = useState(false);

            const [openCustomLink,
                setOpenCustomLink] = useState(false);
             
            const closeEveryOption = () => {
                setNullEditMenu(true);
                setOpenGeneratedLink(false);
                setOpenCustomLink(false);
            };
            const openGenerated = () => {
                setNullEditMenu(false);
                setOpenGeneratedLink(true);
                setOpenCustomLink(false);
            };
            const openCustom = () => {
                setNullEditMenu(false);
                setOpenGeneratedLink(false);
                setOpenCustomLink(true);
            };

    /*Otworzenie dodawania linku */
    
    const [openEditIcons, setOpenEditIcons] = useState(false);
    const [openEditIconsAdded, setOpenEditIconsAdded] = useState(false);

    const [links, setLinks] = useState({
      facebook: '',
      instagram: '',
      snapchat : '',
      youtube : '',
      tiktok : '',
      linkedin : '',
      whatsapp : '',
      twitter : '',
      bereal : '',
      twitch : '',
      discord : '',
      github : '',
      pinterest : '',
      spotify : '',
      soundcloud : '',
      tumblr : '',
      amazon : '',
      paypal : '',
      reddit : '',
      steam : '',
      booksy : '',
      kickstarter : '',
      telegram : '',
      own : '',
      own1 : ''
      

    });


  
  user.customlink = user ? user.customlink : 'loading';
  const [finalLink, setFinalLink] = useState('');

 

  const [selectedIcon, setSelectedIcon] = useState('');

  /* funkcja update'ujaca state'a linkow potrzebnych do edycji w bazie danych */
  const handleLinkChange = (event) => {
    const { name, value } = event.target;
    setLinks((prevLinks) => ({ ...prevLinks, [name]: value }));
    
  };



  
  
  const handleIconClick = (iconName) => {
    setOpenEditIconsAdded(true);
    setSelectedIcon(iconName);
    setOpenEditIcons(false);
    
    


  };

  const firmPostHandler = (e) => {
    let inputValue = e.target.value;
    
    setFirmPost(inputValue);
  };
  
  
  
  const [selectedButton,setSelectedButton] = useState('')
  
  const renderInput = () => {
    switch(selectedButton) {
      case 'firmName' : 
      return (
      <FormGroup className="mb-3">
       <Form.Label style={{color: textCardColor}}>{words[language].firmName}:</Form.Label>
            <InputGroup>
            <FormControl maxLength="32" value={firmName}  disabled={user.firmName != ' ' && !enabledNameInput}   placeholder={user.firmName ? user.firmName : words[language].firmName} name="firmName"  onChange={(e) => {setFirmName(e.target.value)} } />
            <Button   onClick={saveFirmName} variant="outline-success" >{words[language].firmButtonSave}</Button>
            <Button onClick={() => {setInputFirmName(false); setSelectedButton(null); setFirmName('')
            }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                  </InputGroup>
                           </FormGroup>    
      )
      case 'firmPersonalName' :
        return (
          <FormGroup className="mb-3">
          <Form.Label style={{color: textCardColor}}>{words[language].firstName}:</Form.Label>
          <InputGroup>
            <FormControl value={personalFirstName} maxLength="32"   disabled={user.firstName !=' ' && !enabledPersonalNameInput}   placeholder={user.firstName ? user.firstName : words[language].firstName} name="firstName"  onChange={(e) => {setPersonalFirstName(e.target.value)} } />
            
              <Button   onClick={savePersonalName} variant="outline-success">{words[language].firmButtonSave}</Button>
              <Button onClick={() => {setInputPersonalName(false);setSelectedButton(null);setPersonalFirstName('')}} variant="outline-danger">{words[language].firmButtonCancel}</Button>
            
          </InputGroup>
        </FormGroup>
        )
        case 'firmPersonalLastName' :
          return (
            <FormGroup className="mb-3">
            <Form.Label style={{color: textCardColor}}>{words[language].lastName}:</Form.Label>
            <InputGroup>
              <FormControl value={personalLastName} maxLength="32"  disabled={user.lastName != ' ' && !enabledPersonalLastNameInput}   placeholder={user.lastName ? user.lastName : words[language].lastName} name="lastName"  onChange={(e) => {setPersonalLastName(e.target.value)} } />
              
                <Button   onClick={savePersonalLastName} variant="outline-success">{words[language].firmButtonSave}</Button>
                <Button onClick={() => {setInputPersonalLastName(false);setSelectedButton(null);setPersonalLastName('')}} variant="outline-danger">{words[language].firmButtonCancel}</Button>
              
            </InputGroup>
          </FormGroup>          

          )
          case 'firmBio' : 
          return (
            <FormGroup className="mb-3">
  <Form.Label style={{color: textCardColor}}>{words[language].firmBio}:</Form.Label>
  <InputGroup>
    <Form.Control 
      as="textarea" 
      rows={4} 
      maxLength="655" 
      value={firmBio} 
      disabled={user.firmBio != ' ' && !enabledBioInput} 
      placeholder={user.firmBio ? user.firmBio : words[language].firmBio} 
      name="firmBio"  
      onChange={(e) => {setFirmBio(e.target.value)}}
    />
    <>
      <Button onClick={saveFirmBio} variant="outline-success">
        {words[language].firmButtonSave}
      </Button>
      <Button 
        onClick={() => {
          setInputFirmBio(false);
          setSelectedButton(null);
          setFirmBio('');
        }} 
        variant="outline-danger"
      >
        {words[language].firmButtonCancel}
      </Button>
    </>
  </InputGroup>
</FormGroup>
          )
          case 'jobDisplay' : 
      return (
      <FormGroup className="mb-3">
       <Form.Label style={{color: textCardColor}}>{words[language].jobtitle}:</Form.Label>
            <InputGroup>
            <FormControl maxLength="32" value={jobDisplay}  disabled={user.jobDisplay != ' ' && !enabledJobInput}   placeholder={user.jobDisplay ? user.jobDisplay : words[language].jobtitle} name="jobDisplay"  onChange={(e) => {setJobDisplay(e.target.value)} } />
            <Button   onClick={saveJobDisplay} variant="outline-success" >{words[language].firmButtonSave}</Button>
            <Button onClick={() => {setInputJob(false); setSelectedButton(null); setJobDisplay('')
            }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                  </InputGroup>
                           </FormGroup>    
      )
          case 'firmAdress' : 
          return (
            <>
            {user.firmAdress === ' ' ? (
          <FormGroup className="mb-3">
            <Form.Label style={{color: textCardColor}}>{words[language].firmStreet}:</Form.Label>
            <InputGroup>
              <FormControl
                value={firmAdress}
                maxLength="64"
                disabled={user.firmAdress !== ' ' && !enabledAdressInput}
                placeholder={user.firmAdress ? user.firmAdress : words[language].firmStreet}
                name="adres"
                onChange={(e) => {setAdress(e.target.value)}}
              />
              <>
                <Button onClick={saveAdress} variant="outline-success">{words[language].firmButtonSave}</Button>
                <Button onClick={() => {setInputAdress(false);setSelectedButton(null);setAdress('')}} variant="outline-danger">{words[language].firmButtonCancel}</Button>
              </>
            </InputGroup>
          </FormGroup>
        ) : null}
            {user.firmPost === ' ' ? (
          <FormGroup className="mb-3">
            <Form.Label style={{color: textCardColor}}>{words[language].firmPost}:</Form.Label>
            <InputGroup>
              <FormControl
                value={firmPost}
                maxLength="24"
                disabled={user.firmPost !== ' ' && !enabledPostInput}
                placeholder={user.firmPost ? user.firmPost : words[language].firmPost}
                name="pocztowy"
                onChange={firmPostHandler}
              />
              <>
                <Button onClick={saveFirmPost} variant="outline-success">{words[language].firmButtonSave}</Button>
                <Button onClick={() => {setInputAdress(false);setSelectedButton(null);setFirmPost('')}} variant="outline-danger">{words[language].firmButtonCancel}</Button>
              </>
            </InputGroup>
          </FormGroup>
        ) : null}
            {user.firmCity === ' ' ? (
      <FormGroup className="mb-3">
        <Form.Label style={{color: textCardColor}}>{words[language].firmCity}:</Form.Label>
        <InputGroup>
          <FormControl
            value={firmCity}
            maxLength="64"
            disabled={user.firmCity !== ' ' && !enabledCityInput}
            placeholder={user.firmCity ? user.firmCity : words[language].firmCity}
            name="miasto"
            onChange={(e) => {setFirmCity(e.target.value)}}
          />
          <>
            <Button onClick={saveFirmCity} variant="outline-success">{words[language].firmButtonSave}</Button>
            <Button onClick={() => {setInputAdress(false);setSelectedButton(null);setFirmCity('')}} variant="outline-danger">{words[language].firmButtonCancel}</Button>
          </>
        </InputGroup>
      </FormGroup>
    ) : null}
        </>
        
          )
          case 'firmNip' :
            return (
              <FormGroup className="mb-3">
              <Form.Label style={{color: textCardColor}}>NIP:</Form.Label>
              <InputGroup>
                <FormControl value={firmNip} maxLength="18"  disabled={user.firmNip != ' ' && !enabledNipInput}   placeholder={user.firmNip ? user.firmNip : "NIP"} name="NIP"  onChange={(e) => {setNip(e.target.value)} } />
                
                  <Button   onClick={saveNip} variant="outline-success" >{words[language].firmButtonSave}</Button>
                  <Button onClick={() => {setInputNip(false);setSelectedButton(null);setNip('')}}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                
              </InputGroup>
            </FormGroup>           
            )
              case 'firmRegon' :
                return (
                  <FormGroup className="mb-3">
                  <Form.Label style={{color: textCardColor}}>Regon:</Form.Label>
                    <InputGroup>
                      <FormControl value={firmRegon}maxLength="14"  disabled={user.firmRegon != ' ' && !enabledRegonInput}   placeholder={user.firmRegon ? user.firmRegon : "REGON"} name="REGON"  onChange={(e) => {setRegon(e.target.value)} } />
                      
                      <>
                        <Button onClick={saveRegon} variant="outline-success" >{words[language].firmButtonSave}</Button>
                        <Button onClick={() => {setInputRegon(false);setSelectedButton(null);setRegon('')}}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                      </>
                    </InputGroup>
              </FormGroup>             
                )
                case 'billing1' :
                  return (
                    
                       
                      <div><Form.Label style={{color: textCardColor}}>{words[language].billing1}:</Form.Label><InputGroup className="mb-3">
                                                   
                                                    <InputGroup.Text>
                                                    {bankLogo1 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={bankLogo1}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                   <FormControl value={billing1}  maxLength="36"  disabled={user.billing1 != ' ' && !enabledBillingInput1}   placeholder={user.billing1 ? user.billing1 : words[language].billing1} name="billing1"  onChange={billing1Handler } />
                                                                                     
                                                   
                                                   
                                                                                       
                                                 <Button   onClick={saveBilling1} variant="outline-success" >{words[language].firmButtonSave}</Button>
                                                 <Button onClick={() => {setInputBilling1(false);setSelectedButton(null);setBilling1('');setBankLogo1('')}}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                                                       
                                                  
                                                 </InputGroup></div>)
                                                case 'billing2' : 
                                                return (

                                                 <div>
                                                  <Form.Label style={{color: textCardColor}}>{words[language].billing2}:</Form.Label>
                                                  <InputGroup className="mb-3">
                                                    
                                                    <InputGroup.Text>
                                                    {bankLogo2 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={bankLogo2}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                   <FormControl value={billing2}  maxLength="36"  disabled={user.billing2 != ' ' && !enabledBillingInput2}   placeholder={user.billing2 ? user.billing2 : words[language].billing2} name="billing2"  onChange={billing2Handler } />
                                                                                     
                                                   
                                                   
                                                                                       
                                                 <Button   onClick={saveBilling2} variant="outline-success" >{words[language].firmButtonSave}</Button>
                                                 <Button onClick={() => {setInputBilling2(false);setSelectedButton(null);setBilling2('');setBankLogo2('')}}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                                             
                                                  
                                                 </InputGroup></div>)

                                                 case 'billing3' : 
                                                 return (
                                                 <div><Form.Label style={{color: textCardColor}}>Rachunek bankowy nr 3:</Form.Label><InputGroup className="mb-3">
                                                    
                                                    <InputGroup.Text>
                                                    {bankLogo3 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={bankLogo3}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                   <FormControl value={billing3}  maxLength="36"  disabled={user.billing3 != ' ' && !enabledBillingInput3}   placeholder={user.billing3 ? user.billing3 : "Rachunek bankowy nr3"} name="billing3"  onChange={billing3Handler } />
                                                                                     
                                                   
                                                   
                                                                                       
                                                 <Button   onClick={saveBilling3} variant="outline-success" >{words[language].firmButtonSave}</Button>
                                                 <Button onClick={() => {setInputBilling3(false);setSelectedButton(null);setBilling3('');setBankLogo3('')}}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                                                       
                                                  
                                                 </InputGroup></div>)
                                                 
                                                  
                  
                  
                
                  
              
                    
      default :
      return null;                                      

    }
    
  }

   const renderForm = () => {
    switch (selectedIcon) {
      case 'facebook':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaFacebook />
            </InputGroup.Text>
            <FormControl maxLength="64"  placeholder="Facebook link" name="facebook" value={links.facebook} onChange={handleLinkChange} />
            <Button  onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)}  variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'instagram':
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaInstagram />
            </InputGroup.Text>
            <FormControl maxLength="64"  placeholder="Instagram link" name="instagram" value={links.instagram} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'github':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaGithub />
            </InputGroup.Text>
            <FormControl maxLength="64"  placeholder="GitHub link" name="github" value={links.github} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'twitter':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaTwitter />
            </InputGroup.Text>
            <FormControl maxLength="64" placeholder="Twitter link" name="twitter" value={links.twitter} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'reddit':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaReddit />
            </InputGroup.Text>
            <FormControl maxLength="64" placeholder="Reddit link" name="reddit" value={links.reddit} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'telegram':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaTelegram />
            </InputGroup.Text>
            <FormControl maxLength="64" placeholder="Telegram link" name="telegram" value={links.telegram} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)}  variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
      case 'spotify':
        
        return (
          <InputGroup className="mb-3">
            <InputGroup.Text>
              <FaSpotify />
            </InputGroup.Text>
            <FormControl  maxLength="64" placeholder="Spotify link" name="spotify" value={links.spotify} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
          </InputGroup>
        );
        case 'discord':
            
            return (
            <InputGroup className="mb-3">
              <InputGroup.Text>
                <FaDiscord />
              </InputGroup.Text>
              <FormControl  maxLength="64" placeholder="Discord link" name="discord" value={links.discord} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'snapchat':
            
            return (
            <InputGroup className="mb-3">
                <InputGroup.Text>
                <FaSnapchat />
                </InputGroup.Text>
                <FormControl  maxLength="64"  placeholder="Snapchat link" name="snapchat" value={links.snapchat} onChange={handleLinkChange} />
            <Button  onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'whatsapp':
            
            return (
            <InputGroup className="mb-3">
                <InputGroup.Text>
                <FaWhatsapp />
                </InputGroup.Text>
                <FormControl  maxLength="64"  placeholder="WhatsApp link" name="whatsapp" value={links.whatsapp} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'linkedin':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaLinkedin />
                </InputGroup.Text>
                <FormControl  maxLength="64"  placeholder="LinkedIn link" name="linkedin" value={links.linkedin} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'youtube':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaYoutube />
                </InputGroup.Text>
                <FormControl  maxLength="64"  placeholder="Youtube link" name="youtube" value={links.youtube} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'tiktok':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaTiktok />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Tiktok link" name="tiktok" value={links.tiktok} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'kickstarter':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaKickstarter />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Kickstarter link" name="kickstarter" value={links.kickstarter} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'paypal':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaPaypal />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Paypal link" name="paypal" value={links.paypal} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'soundcloud':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaSoundcloud />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Soundcloud link" name="soundcloud" value={links.soundcloud} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );

        case 'twitch':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaTwitch />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Twitch link" name="twitch" value={links.twitch} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'tumblr':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaTumblr />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Tumblr link" name="tumblr" value={links.tumblr} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'steam':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaSteam />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Steam link" name="steam" value={links.steam} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>
            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'pinterest':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaPinterest />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Pinterest link" name="pinterest" value={links.pinterest} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'booksy':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <img style={{width:"18px"}} src={icon2}></img>
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Booksy link" name="booksy" value={links.booksy} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'amazon':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <FaAmazon />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="Amazon link" name="amazon" value={links.amazon} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'own':

            return (
            <InputGroup className="mb-3">
              
                <InputGroup.Text>
                <FaLink />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder={words[language].ownLinkPlaceholder} name="own" value={links.own} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'own1':

            return (
            <InputGroup className="mb-3">
              
                <InputGroup.Text>
                <FaLink />
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder={words[language].ownLink1Placeholder} name="own1" value={links.own1} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );
        case 'bereal':

            return (
            <InputGroup className="mb-3">

                <InputGroup.Text>
                <img style={{width:"18px"}} src={icon}></img>
                </InputGroup.Text>
                <FormControl  maxLength="64" placeholder="BeReal link" name="bereal" value={links.bereal} onChange={handleLinkChange} />
            <Button onClick={addToPage} variant="outline-success" ><FontAwesomeIcon icon={faCheck}/></Button>

            <Button  onClick={() => setOpenEditIconsAdded(false)} variant="outline-danger" ><FontAwesomeIcon icon={faXmark}/></Button>
            </InputGroup>
        );

              


        
        


        
        default:
        return null;
        }
        };

        const isValidUrl = user && user.posturl && validateUrl(user.posturl);



        function validateUrl(url) {
          const instagramPostRegex = /^https?:\/\/(www\.)?instagram\.com\/p\/[a-zA-Z0-9_-]+(\/embed)?\/?.*$/;
          return instagramPostRegex.test(url);
        }
        

    
    
         // Sprawdzamy, czy URL jest prawidłowy
    
    
    
    

    
    /*useeffect function that fetch data by axios from endpoint nodejs localhost:30004/getphotos/user._id
    and set state galleryImages with data from database*/
    useEffect(() => {
      const fetchData = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await axios.get('https://api.tagu.pl/getphotos', {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
            
              user.photo1=response.data.photo1;
              user.photo2=response.data.photo2;
              user.photo3=response.data.photo3;
              
          } catch (error) {
              console.error('An error occurred while fetching user gallery images from API.', error);
          }
      };
  
      if (user._id) {
          fetchData();
      }
  }, [user._id]);


  const [maxHeight, setMaxHeight] = useState(0);
  const carouselRef = useRef(null);

  const calculateMaxHeight = (carouselChildren) => {
    let max = 0;
  
    carouselChildren.forEach((child) => {
      const image = child.querySelector('img');
      if (image && image.offsetHeight > max) {
        max = image.offsetHeight;
      }
    });
  
    return max;
  };

  

  useEffect(() => {
    const handleResize = () => {
      const carouselChildren = Array.from(carouselRef.current?.children || []);
      setMaxHeight(calculateMaxHeight(carouselChildren));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageLoad = () => {
    const carouselChildren = Array.from(carouselRef.current?.children || []);
    setMaxHeight(calculateMaxHeight(carouselChildren));
  };


    //useffect function that fetch data by axios from endpoint nodejs localhost:3004/getPostURL
    //and set state posturl with data from database
    useEffect(() => {
      const fetchData = async () => {
        try {
          if(user._id) {
          
          const token = localStorage.getItem('token');
          const response = await axios.get('https://api.tagu.pl/getPostURL', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          user.posturl = response.data.posturl;
          setPosturl(response.data.posturl);
          
          }
        } catch (error) {
          console.error('An error occurred while fetching user post url from API.', error);
        }
      };

      if (user._id) {
        fetchData();
      }
    }, [user._id]);

    
    

    /* wylogowywanie */
    const handleLogout = () => {
        const token = localStorage.getItem("token");

        if (token) {
            axios
                .get("https://api.tagu.pl/logout", {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => {
                    localStorage.removeItem("token");
                    
                    setAuthorized(false);
                    setUser({});
                    /* odswiezenie komponentu po wylogowaniu*/
                    setTimeout(() => {
                      window.location.replace(`/users/${user.keylink}`);
                    }, 1000);
                    

                })
                .catch();
        }
    };

    const [firmHeader,setFirmHeader] = useState('')

    /*obsluga inputow i pobieranie z nich danych */
    const saveNameHandler = (event) => {
        setTempName(event.target.value);
        
    }
    const saveLastHandler = (event) => {
        setTempLastName(event.target.value);
    }
    const saveBioHandler = (event) => {
        setTempBio(event.target.value);
        setInputBio(event.target.value);
        

    }
    const saveFirmHeaderHandler = (event) => {
      setFirmHeader(event.target.value);
    }
    const saveHeaderData = async () => {
      
      try {
        if(firmHeader.length > 6) {
          notify3(words[language].firmHeaderValidation)
        }
        const response = await axios.patch('https://api.tagu.pl/updateFirmHeader', { firmHeader }, {
          headers: {
            Authorization: `Bearer ${token}`, // Dodaj token autoryzacyjny jako nagłówek
          },
        });
        
        notify2(words[language].saveFirmHeader)
        setUser((prevState) => ({
          ...prevState,
          firmHeader: response.data.firmHeader,
        }));
      } catch (error) {
        console.error(error);
      }
      
    }

    /* ustawianie state dla bio zeby przechowywac jego wartosc w inpucie */
    const [inputBio, setInputBio] = useState('');

    useEffect(() => {
    if (user) {
    setInputBio(user.bio);
    }
    }, [user]);

    //motywy

    
    const [cardStyles, setCardStyles] = useState({
      coverPhoto: '',
      cardBackground: '',
      textCardColor: '',
      buttonContactColor: '',
      textButtonContactColor: '',
      navbarBackground : '',
      navbarTextColor : '',
      editLinksButtonColor : '',
      profilePictureBorder : '',
      iconsColor:'',
      
    });
    
    useEffect(() => {
      
      const fetchData = async () => {
        try {
          if(user._id) {
          const token = localStorage.getItem('token')
          const response = await axios.get(`https://api.tagu.pl/getAuthTheme`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

          const { theme } = response.data;
  
          // Ustawienie tematu jako wybrany temat
          
          
  
          // Ustawienie odpowiednich stylów karty na podstawie tematu
          if (theme === 'white') {
            setCardStyles({
              coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlack%20Minimalist%20Linktree%20Background2.png?alt=media&token=37f0f254-9cc3-4d0b-ba54-56060d4675ca',
              cardBackground: '#EBEBEB',
              textCardColor: '#001014',
              buttonContactColor: '#001014',
              textButtonContactColor: '#EBEBEB',
              navbarBackground : '#001014',
              navbarTextColor : '#EBEBEB',
              editLinksButtonColor : '#001014',
              profilePictureBorder : '5px solid #001014',
              iconsColor : '#001014'
            });
          } else if (theme === 'lightgrey') {
            setCardStyles({
              coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlue%20Watercolor%20Linktree%20Background%20(3).png?alt=media&token=e5c3cf34-bd5a-449e-a987-0e3b765f5baf',
              cardBackground: '#E1E3D5',
              textCardColor: '#323358',
              buttonContactColor: '#323358',
              textButtonContactColor: '#e1b94b',
              navbarBackground : '#323358',
              navbarTextColor : '#e1b94b',
              editLinksButtonColor : '#323358',
              profilePictureBorder : '5px solid #323358',
              iconsColor : '#323358'
              
            });
          } else if (theme === 'purple') {
            setCardStyles({
              coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FProjekt%20bez%20nazwy%20(11).png?alt=media&token=4762d3cb-4f26-4aa7-818c-8eb3140e1bbb',
              cardBackground: '#FCECC9',
              textCardColor: '#814692',
              buttonContactColor: '#814692',
              textButtonContactColor: '#FCECC9',
              navbarBackground : '#814692',
              navbarTextColor : '#FCECC9',
              editLinksButtonColor : '#814692',
              profilePictureBorder : '5px solid #814692',
              iconsColor : '#814692'
            });
          }
          else if (theme === 'green') {
            setCardStyles({
              coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FUntitled%20design%20(5).png?alt=media&token=d141591d-2fca-45d2-bf26-bd094c2c9153',
              cardBackground: '#F6EDD9',
              textCardColor: '#567956',
              buttonContactColor: '#567956',
              textButtonContactColor: '#E1E3D5',
              navbarBackground: '#567956',
              navbarTextColor: '#F6EDD9',
              editLinksButtonColor: '#567956',
              profilePictureBorder: '5px solid #567956',
              iconsColor: '#567956'
            });
          }
        }
        }  catch (error) {
          console.error('An error occurred while fetching user theme.', error);
          // Obsługa błędu
        }
      };
  
      fetchData();
    }, []);
   
    

    const handleThemeChange = async (pickedTheme,event) => {
      event.preventDefault();
      const selectedTheme = pickedTheme;
      try {
        if(user._id) {
          const response = await axios.patch(
            `https://api.tagu.pl/updateTheme`,
            { theme: pickedTheme },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
        if (response.status === 200) {
          const user = response.data;
          
          
        } else {
          console.error('Error updating user theme:', response.status);
          // Handle error case
        }
      }
        
      
      } catch (error) {
        console.error('An error occurred while updating user theme.', error);
        // Handle error case
      }

      
      
      if (selectedTheme === 'white') {
        setCardStyles({
          coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlack%20Minimalist%20Linktree%20Background2.png?alt=media&token=37f0f254-9cc3-4d0b-ba54-56060d4675ca',
          cardBackground: '#EBEBEB',
          textCardColor: '#001014',
          buttonContactColor: '#001014',
          textButtonContactColor: '#EBEBEB',
          navbarBackground : '#001014',
          navbarTextColor : '#EBEBEB',
          editLinksButtonColor : '#001014',
          profilePictureBorder : '5px solid #001014',
          iconsColor : '#001014'
          
        });
      } else if (selectedTheme === 'lightgrey') {
        setCardStyles({
          coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FBlue%20Watercolor%20Linktree%20Background%20(3).png?alt=media&token=e5c3cf34-bd5a-449e-a987-0e3b765f5baf',
          cardBackground: '#E1E3D5',
          textCardColor: '#323358',
          buttonContactColor: '#323358',
          textButtonContactColor: '#e1b94b',
          navbarBackground : '#323358',
          navbarTextColor : '#e1b94b',
          editLinksButtonColor : '#323358',
          profilePictureBorder : '5px solid #323358',
          iconsColor : '#323358'

        });
      } else if (selectedTheme === 'purple') {
        setCardStyles({
          coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FProjekt%20bez%20nazwy%20(11).png?alt=media&token=4762d3cb-4f26-4aa7-818c-8eb3140e1bbb',
          cardBackground: '#FCECC9',
          textCardColor: '#814692',
          buttonContactColor: '#814692',
          textButtonContactColor: '#FCECC9',
          navbarBackground : '#814692',
          navbarTextColor : '#FCECC9',
          editLinksButtonColor : '#814692',
          profilePictureBorder : '5px solid #814692',
          iconsColor : '#814692'
        });
      }
      else if (selectedTheme === 'green') {
        setCardStyles({
          coverPhoto: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FUntitled%20design%20(5).png?alt=media&token=d141591d-2fca-45d2-bf26-bd094c2c9153',
          cardBackground: '#F6EDD9',
          textCardColor: '#567956',
          buttonContactColor: '#567956',
          textButtonContactColor: '#E1E3D5',
          navbarBackground: '#567956',
          navbarTextColor: '#F6EDD9',
          editLinksButtonColor: '#567956',
          profilePictureBorder: '5px solid #567956',
          iconsColor: '#567956'
        });
      }
    };
  
    const { cardBackground, textCardColor, buttonContactColor, textButtonContactColor, navbarBackground, navbarTextColor,editLinksButtonColor, profilePictureBorder,iconsColor } = cardStyles;

     

    
      
    


    
    

    /* tablica z wartosciami ikonek - fontawesome (potrzebne) */ 
    const iconMap = {
        facebook: faFacebook,
        instagram: faInstagram,
        github: faGithub,
        snapchat: faSnapchat,
        twitter : faTwitter,
        whatsapp : faWhatsapp,
        reddit : faReddit,
        telegram : faTelegram,
        spotify : faSpotify,
        discord : faDiscord,
        snapchat : faSnapchat,
        linkedin : faLinkedin,
        tiktok : faTiktok,
        youtube : faYoutube,
        kickstarter : faKickstarter,
        paypal : faPaypal,
        soundcloud : faSoundcloud,
        twitch : faTwitch,
        tumblr : faTumblr,
        steam : faSteam,
        pinterest : faPinterest,
        booksy : faEtsy,
        amazon : faAmazon,
        bereal : <img src={icon} style={{width:"18px"}}></img>,
        own : faLink,
        own1 : faLink,


    
        // itd.
      };

    /* tablica z wartosciami ikonek - react-icon (tez potrzebne) */
    const iconsMap = {
        facebook : FaFacebook,
        instagram : FaInstagram,
        github : FaGithub,
        snapchat : FaSnapchat,
        twitter : FaTwitter,
        whatsapp : FaWhatsapp,
        reddit : FaReddit,
        telegram : FaTelegram,
        spotify : FaSpotify,
        discord : FaDiscord,
        snapchat : FaSnapchat,
        linkedin : FaLinkedin,
        tiktok : FaTiktok,
        youtube : FaYoutube,
        kickstarter : FaKickstarter,
        paypal : FaPaypal,
        soundcloud : FaSoundcloud,
        twitch : FaTwitch,
        tumblr : FaTumblr,
        steam : FaSteam,
        pinterest : FaPinterest,
        booksy : FaEtsy,
        amazon : FaAmazon,
        own : FaLink,
        own1 : FaLink,
        bereal : <img src={icon} style={{width:"18px"}}></img>,

    }
    const [selectedImage, setSelectedImage] = useState(null);
   
      

      
      const [photo1, setPhoto1] = useState();
      const [photo2, setPhoto2] = useState();
      const [photo3, setPhoto3] = useState();
      const [photo1Spinner,setPhoto1Spinner] = useState(false);
      const [photo2Spinner,setPhoto2Spinner] = useState(false);
      const [photo3Spinner,setPhoto3Spinner] = useState(false);
    
      
      
      

      const photoHandler = async (event) => {
        const maxSizeInBytes = 6 * 1024 * 1024; // 6 MB
        const selectedPhoto = event.target.files[0];
        setPhoto1Spinner(true);
      
        const generatedHash = generateRandomHash();
        const fileExtension = selectedPhoto.name.split('.').pop();
        const newFileName = `${generatedHash}.${fileExtension}`;
      
        const options = {
          maxSizeMB: 3, // Ustal maksymalny rozmiar po kompresji w megabajtach
          
          alwaysKeepResolution : true, 
        };
      
        try {
          const compressedFile = await imageCompression(selectedPhoto, options);
      
          // Utwórz nowy obiekt pliku skompresowanego
          const compressedPhoto = new File([compressedFile], newFileName, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
      
          if (compressedPhoto.size > maxSizeInBytes) {
            setSelectedImage(null);
            if (toast) {
              notify3(words[language].photo1Size);
            }
            setPhoto1Spinner(false);
            return;
          }
      
          const formData = new FormData();
          formData.append("photo", compressedPhoto);
      
          axios
            .post("https://api.tagu.pl/uploadPhoto", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              const photoURL = response.data.photoURL;
              setUser((prevState) => ({
                ...prevState,
                photo1: photoURL,
              }));
              setCountPhoto((prevCount) => prevCount + 1);
              // Aktualizacja wartości user.photo1 w bazie danych po opóźnieniu
              if (user._id) {
                const updatedData = {
                  photo1: photoURL,
                };
      
                axios
                  .patch("https://api.tagu.pl/updatePhotos", updatedData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(() => {
                    setPhoto1Spinner(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setPhoto1Spinner(false);
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              setPhoto1Spinner(false);
            });
        } catch (error) {
          console.error(error);
          setPhoto1Spinner(false);
        }
      };
      
      
      
      // Podobne zmiany można wprowadzić w photo2Handler i photo3Handler
      
      const photo2Handler = async (event) => {
        const maxSizeInBytes = 6 * 1024 * 1024; // 6 MB
        const selectedPhoto = event.target.files[0];
        setPhoto2Spinner(true);
      
        const generatedHash = generateRandomHash();
        const fileExtension = selectedPhoto.name.split('.').pop();
        const newFileName = `${generatedHash}.${fileExtension}`;
      
        const options = {
          maxSizeMB: 3, // Ustal maksymalny rozmiar po kompresji w megabajtach
          
          alwaysKeepResolution : true, 
        };
      
        try {
          const compressedFile = await imageCompression(selectedPhoto, options);
      
          // Utwórz nowy obiekt pliku skompresowanego
          const compressedPhoto = new File([compressedFile], newFileName, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
      
          if (compressedPhoto.size > maxSizeInBytes) {
            setSelectedImage(null);
            if (toast) {
              notify3(words[language].photo2Size);
            }
            setPhoto2Spinner(false);
            return;
          }
      
          const formData = new FormData();
          formData.append("photo", compressedPhoto);
      
          axios
            .post("https://api.tagu.pl/uploadPhoto2", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              const photoURL = response.data.photoURL;
              setUser((prevState) => ({
                ...prevState,
                photo2: photoURL,
              }));
              setCountPhoto((prevCount) => prevCount + 1);
              // Aktualizacja wartości user.photo2 w bazie danych po opóźnieniu
              if (user._id) {
                const updatedData = {
                  photo2: photoURL,
                };
      
                axios
                  .patch("https://api.tagu.pl/updatePhotos", updatedData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(() => {
                    setPhoto2Spinner(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setPhoto2Spinner(false);
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              setPhoto2Spinner(false);
            });
        } catch (error) {
          console.error(error);
          setPhoto2Spinner(false);
        }
      };
      
      
      
      const photo3Handler = async (event) => {
        const maxSizeInBytes = 6 * 1024 * 1024; // 6 MB
        const selectedPhoto = event.target.files[0];
        setPhoto3Spinner(true);
      
        const generatedHash = generateRandomHash();
        const fileExtension = selectedPhoto.name.split('.').pop();
        const newFileName = `${generatedHash}.${fileExtension}`;
      
        const options = {
          maxSizeMB: 1, // Ustal maksymalny rozmiar po kompresji w megabajtach
          
          alwaysKeepResolution : true, 
        };
      
        try {
          const compressedFile = await imageCompression(selectedPhoto, options);
      
          // Utwórz nowy obiekt pliku skompresowanego
          const compressedPhoto = new File([compressedFile], newFileName, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
      
          if (compressedPhoto.size > maxSizeInBytes) {
            setSelectedImage(null);
            if (toast) {
              notify3(words[language].photo3Size);
            }
            setPhoto3Spinner(false);
            return;
          }
      
          const formData = new FormData();
          formData.append("photo", compressedPhoto);
      
          axios
            .post("https://api.tagu.pl/uploadPhoto3", formData, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              const photoURL = response.data.photoURL;
              setUser((prevState) => ({
                ...prevState,
                photo3: photoURL,
              }));
              setCountPhoto((prevCount) => prevCount + 1);
              // Aktualizacja wartości user.photo3 w bazie danych po opóźnieniu
              if (user._id) {
                const updatedData = {
                  photo3: photoURL,
                };
      
                axios
                  .patch("https://api.tagu.pl/updatePhotos", updatedData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then(() => {
                    setPhoto3Spinner(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setPhoto3Spinner(false);
                  });
              }
            })
            .catch((error) => {
              console.error(error);
              setPhoto3Spinner(false);
            });
        } catch (error) {
          console.error(error);
          setPhoto3Spinner(false);
        }
      };
      




      
        
      
 
      
        
          
          
    const [spinner2, setSpinner2] = useState(false);
    const [spinner3, setSpinner3] = useState(false);
    const [spinner4, setSpinner4] = useState(false);

    /* funkcja saveGallery ktora update'uje zdjecia w bazie danych na podstawie state'a galleyImages i odswieza strone - korzysta z endpointa localhost:3004/updatePhotos/:userId */ 
    

      const handleDeleteClick = (event) => {
        //axios request that delete photo1 or photo2 or photo3
        event.preventDefault();
        
        const photoToDelete = event.target.name;
        if(photoToDelete==='photo1') {
          setUser((prevState) => ({
            ...prevState,
            photo1: '',
            
            
            
          }));
          setCountPhoto(prevCount => prevCount - 1);
          const tempPath = getPhotoPathFromURL(user.photo1);
          const photoPath = tempPath;
          

          axios.delete('https://api.tagu.pl/delete-photo', {
          params: {
            photoPath: photoPath,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            // Obsługa odpowiedzi z serwera po usunięciu zdjęcia
            // Możesz dostosować to zgodnie z odpowiedzią serwera
            // Aktualizacja danych użytkownika itp.
          })
          .catch((error) => {
            console.error(error);
          });
          setPhoto1('');
          setPhoto1Spinner(false);
        }
        else if(photoToDelete==="photo2") {
          setUser((prevState) => ({
            ...prevState,
            
            photo2 : '',
            
            
          }));
          setCountPhoto(prevCount => prevCount - 1);
          const tempPath = getPhotoPathFromURL(user.photo2);
          const photoPath = tempPath; // Ścieżka do usuwanego zdjęcia

          axios.delete('https://api.tagu.pl/delete-photo', {
          params: {
            photoPath: photoPath,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            // Obsługa odpowiedzi z serwera po usunięciu zdjęcia
             // Możesz dostosować to zgodnie z odpowiedzią serwera
            // Aktualizacja danych użytkownika itp.
          })
          .catch((error) => {
            console.error(error);
          });
          setPhoto2('');
          setPhoto2Spinner(false);
        }
        else if(photoToDelete==="photo3") {
          setUser((prevState) => ({
            ...prevState,
            
            
            photo3 : '',
            
            
          }));
          setCountPhoto(prevCount => prevCount - 1);
          const tempPath = getPhotoPathFromURL(user.photo3);
          const photoPath = tempPath; // Ścieżka do usuwanego zdjęcia

          axios.delete('https://api.tagu.pl/delete-photo', {
          params: {
            photoPath: photoPath,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            // Obsługa odpowiedzi z serwera po usunięciu zdjęcia
             // Możesz dostosować to zgodnie z odpowiedzią serwera
            // Aktualizacja danych użytkownika itp.
          })
          .catch((error) => {
            console.error(error);
          });
          setPhoto3('');
          setPhoto3Spinner(false);
          

        }
        
      
        const updatedData = {};
        updatedData[photoToDelete] = '';
        axios.patch(`https://api.tagu.pl/updatePhotos`, updatedData, {
          headers: {
            Authorization: `Bearer ${token}`




      }
    })
      .then((response) => {
        
        
        
        
        
        
        
        
        
      })
      .catch((error) => {
        console.error(error);
      });
      



  }
  const getPhotoPathFromURL = (url) => {
    const startIndex = url.lastIndexOf('/') + 1; // Znajdź indeks ostatniego wystąpienia '/' i dodaj 1, aby przejść do początku nazwy pliku
    const endIndex = url.indexOf('?'); // Znajdź indeks znaku '?' oznaczającego koniec nazwy pliku (jeśli występuje)
  
    let prefix = '';
    const prefixIndex = url.lastIndexOf('/', startIndex - 2) + 1; // Znajdź indeks ostatniego wystąpienia '/' przed indeksem startIndex i dodaj 1, aby przejść do początku prefiksu
  
    if (prefixIndex !== -1 && prefixIndex < startIndex) {
      prefix = url.substring(prefixIndex, startIndex - 1); // Wytnij fragment z prefiksem (bez ostatniego '/')
    }
  
    let fileName;
    if (endIndex !== -1) {
      fileName = url.substring(startIndex, endIndex); // Wytnij fragment z nazwą pliku
    } else {
      fileName = url.substring(startIndex); // Wytnij fragment z nazwą pliku (bez końcowego znaku '?')
    }
  
    return `${prefix}/${decodeURIComponent(fileName)}`;
  };
  
  
  
  
  
  const handleDeleteProfileClick = (event) => {
    event.preventDefault();
    const tempPath = getPhotoPathFromURL(user.picture);
    const photoPath = tempPath; // Ścieżka do usuwanego zdjęcia

  axios.delete('https://api.tagu.pl/delete-photo', {
    params: {
      photoPath: photoPath,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      // Obsługa odpowiedzi z serwera po usunięciu zdjęcia
       // Możesz dostosować to zgodnie z odpowiedzią serwera
      // Aktualizacja danych użytkownika itp.
    })
    .catch((error) => {
      console.error(error);
    });

    axios.patch('https://api.tagu.pl/updatePicture', { picture: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fxd.png?alt=media&token=c6984522-1947-4aad-a701-a8a6ec696376&_gl=1*sjjg7u*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjAwNzU0Mi4yNS4xLjE2ODYwMDc2NTcuMC4wLjA.' }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    
      .then((response) => {
        // Aktualizacja danych użytkownika na podstawie odpowiedzi
        setUser((prevState) => ({
          ...prevState,
         
          picture : response.data.picture,
          
          
        }));
        setProfilePictureSpinner(false);
        if(toast){
          notify2(words[language].pictureDelete);
        }
        
        setSelectedImage(null)
      })
      .catch((error) => {
        console.error(error);
      });
  }; 
  /* zablokowanie wizytówki */

  const blockVisitor = () => {
    async function blockUser() {
      try {
        const token = localStorage.getItem("token"); 
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    
        const response = await axios.patch('https://api.tagu.pl/blockUser', null, config);
        
        setUser(prevState => ({ ...prevState, blocked: true }));
      } catch (error) {
        console.error(error);
      }
    }
    
    // Wywołanie funkcji do wykonania żądania
    blockUser();
    setBlockInput(false)
    if(toast) {
    notify2(words[language].blockVisitor);
    }
  }
  const unBlockVisitor = () => {
    async function unblockUser() {
      try {
        const token = localStorage.getItem("token"); 
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    
        const response = await axios.patch('https://api.tagu.pl/unblockUser', null, config);
        
        setUser(prevState => ({ ...prevState, blocked: false }));
      } catch (error) {
        console.error(error);
      }
    }
    
    // Wywołanie funkcji do wykonania żądania
    unblockUser();
    setUnBlockInput(false)
    if(toast) {
    notify2(words[language].unBlockVisitor);
    }
  }

  

        
    






        
        
    
    



    /* wyslanie żadania ze zmiana na dane wprowazdone w formularzu po wcisnieciu * - JT */
    const saveDataHandler = async (e) => {
      e.preventDefault()
      
      localStorage.setItem("showOffcanvas", showOffcanvas.toString());
      localStorage.setItem("open", 'personal');
      setTimeout(() => {
        if (!saveCalled) {
          localStorage.removeItem("showOffcanvas");
          localStorage.removeItem("open");
        }
      }, 2000);


      
    
      try {
        
        
        
    
        const response = await fetch(`https://api.tagu.pl/updateUsers`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ firstName: tempName, lastName: tempLastName, bio: tempBio }),
        });
        
        
        const updatedUser = await response.json();
        setUser(updatedUser);
        
        if(toast) {
        notify2(words[language].savePersonalData)
        }
        
      } catch (err) {
        console.error(err);
      }
    };
    /* funkcja generujaca randomowy hash 16 znakowy */
    function generateRandomHash() {
        let hash = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      
        for (let i = 0; i < 16; i++) {
          hash += characters.charAt(Math.floor(Math.random() * characters.length));
        }
      
        return hash;
      }
    /* state ktory przechowuje tymczasowy link ktory zostanie zupdate'owany po wcisnieciu przycisku */
    
    const [tempLink,setTempLink] = useState("TWOJ-NOWY-LINK");
    const [ownLink,setOwnLink] = useState("")

   
    /*funkcja obslugujaca klikniecie przycisku - generuj */
    const saveLinkHandler = () => {
        setTempLink(generateRandomHash());
    }
    useEffect(() => {
      const notifyMessage = localStorage.getItem('notifyMessage');
      if (notifyMessage) {
        if(toast) {
        notify2(notifyMessage);
        }
        localStorage.removeItem('notifyMessage');
      }
    }, []);

    

    /* funkcja wysylajaca update do bazy po wcisnieciu przycisku zmien - przy generowaniu */
    const updateGenerateHandler = () => {
      localStorage.setItem('showOffcanvas', showOffcanvas.toString());
      localStorage.setItem('open', 'links');
      setTimeout(() => {
        if (!saveCalled) {
          localStorage.removeItem('showOffcanvas');
          localStorage.removeItem('open');
        }
      }, 2000);
      if(tempLink === "TWOJ-NOWY-LINK") {
        notify3(words[language].generateLinkError);
        return;
      }
      axios.put(`https://api.tagu.pl/customlink`, { customlink: tempLink }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          setUser((prevState) => ({
            ...prevState,
            customlink: response.data.customlink,
          }));
          if(toast) {
            notify2(words[language].saveCustomLink)
          }
          
        })
        .catch(error => {
          console.error(error);
        });
    };
    
    /* funkcja obslugujaca formularz do wlasnych linkow */
    const saveOwnHandler = (event) => {
        setOwnLink(event.target.value);
        

    }
    /* walidacja linku do wizytowki */
    const isValidLink = (link) => {
        const regex = /^[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/;
        return regex.test(link);
      }
      
    /* funkcja wysylajaca update do bazy po wcisnieciu przycisku zmien - przy wlasnej zmianie */
    const updateOwnHandler = () => {
        
        if (ownLink.trim() === '') {
          if(toast) {
          notify3(words[language].nullLink)
          }
        } else if (!isValidLink(ownLink)) {
          if(toast) {
          notify3(words[language].validCustomLink)
          }
        } else if (ownLink.length > 16) {
          if(toast) {
          notify3(words[language].maxLengthLink)
          }
        }
        else if(ownLink.length <4) {
          if(toast) {
          notify3(words[language].minLengthLink)
          }
        }
         else {
            localStorage.setItem('showOffcanvas', showOffcanvas.toString());
            localStorage.setItem('open', 'links');
            setTimeout(() => {
                if (!saveCalled) {
                localStorage.removeItem('showOffcanvas');
                localStorage.removeItem('open');
                }
            }, 2000);
            axios.put(`https://api.tagu.pl/customlink`, { customlink: ownLink.toLowerCase() }, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
              .then(response => {
                setFinalLink(ownLink);
                setUser((prevState) => ({
                  ...prevState,
                  customlink: response.data.customlink,
                }));
                if(toast) {
                  notify2(words[language].saveCustomLink)
                }
                
                
              })
              .catch(error => {
                if(error.response.status === 409) {
                  if(toast) {
                  notify3(words[language].existLink)
                  }
                }
              });
        }
      }
      
    const [preview, setPreview] = useState(null);
    const onClose=() => {
      setPreview(null)
    }
    const onCrop = (view)=> {
      setPreview(view);
    }
    const [avatar,setAvatar] = useState(false);
    const [urlSelected, setUrlSelected] = useState('');
    const editorRef = useRef(null);
    const [profilePictureSpinner,setProfilePictureSpinner] = useState(false);
    /* funkcja zwiazana ze zdjeciami */
    const fetchImageData = (e) => {
      const file = e.target.files[0];
      if(file.name) {
      
      const fileExtension = file.name.split('.').pop();
      const generatedHash = generateRandomHash();
      const newFileName = `${generatedHash}.${fileExtension}`;
      
      setAvatar(true)
      const modifiedFile = new File([file], newFileName, { type: file.type });
      setUrlSelected(URL.createObjectURL(modifiedFile));
      setSelectedImage(modifiedFile);
      
      }
      
    };
    const [confPhoto1,setConfPhoto] = useState();

    const fetchPhoto1 = (e) => {
      const file = e.target.files[0];
      if(file.name) {
      
      const fileExtension = file.name.split('.').pop();
      const generatedHash = generateRandomHash();
      const newFileName = `${generatedHash}.${fileExtension}`;
      
      
      const modifiedFile = new File([file], newFileName, { type: file.type });
      setUrlSelected(URL.createObjectURL(modifiedFile));
      setConfPhoto(modifiedFile);
      console.log(confPhoto1)
      
      }
    }
    const [spinner, setSpinner] = useState(false);
    
    const handleCrop = () => {
      
    };
    const saveImageHandler = async (e) => {
      e.preventDefault();
    
      setProfilePictureSpinner(true);
      if (!selectedImage) return;
    
      const file = selectedImage;
      const maxSizeInBytes = 6 * 1024 * 1024; // 8 MB
    
      setSpinner(true);
    
      const canvas = editorRef.current.getImage();
    
      const options = {
        maxSizeMB: 3, // Ustal maksymalny rozmiar po kompresji w megabajtach
        
        alwaysKeepResolution : true, 
      };
    
      try {
        // Utwórz obiekt Blob z danych obrazu w formacie PNG
        const blob = await new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/png");
        });
    
        const compressedFile = await imageCompression(blob, options);
    
        if (compressedFile.size > maxSizeInBytes) {
          setSelectedImage(null);
          if (toast) {
            notify3(words[language].pictureSize);
          }
          setSpinner(false);
          setProfilePictureSpinner(false);
          return;
        }
    
        const generatedHash = generateRandomHash();
        const fileExtension = selectedImage.name.split('.').pop();
        const newFileName = `${generatedHash}.${fileExtension}`;
    
        const formData = new FormData();
        formData.append('image', compressedFile, newFileName);
    
        axios
          .post('https://api.tagu.pl/upload', formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const pictureURL = response.data.pictureURL;
    
            setSelectedImage(null);
            if (user._id) {
              axios
                .patch(
                  'https://api.tagu.pl/updatePicture',
                  { picture: pictureURL },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((response) => {
                  user.picture = response.data.picture;
                  setSpinner(false);
                  setProfilePictureSpinner(false);
                  if (toast) {
                    notify2(words[language].pictureUpdate);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setSpinner(false);
                });
            }
          })
          .catch((error) => {
            console.error('Error uploading image:', error);
            setSpinner(false);
            setProfilePictureSpinner(false);
          });
      } catch (error) {
        console.error('Error compressing image:', error);
        setSpinner(false);
        setProfilePictureSpinner(false);
      }
    };
    
    
    

    
    

    
    const [editStates, setEditStates] = useState({
      facebook: false,
      instagram: false,
      github: false,
      snapchat: false,
      telegram: false,
      whatsapp: false,
      twitter : false,
      discord: false,
      reddit : false,
      spotify : false,
      linkedin : false,
      tiktok : false,
      youtube : false,
      kickstarter : false,
      paypal : false,
      soundcloud : false,
      twitch : false,
      tumblr : false,
      steam : false,
      pinterest : false,
      booksy : false,
      amazon : false,
      bereal : false,
      own : false,
      own1 : false,
      
    });
   

  

    const [isEnabled, setIsEnabled] = useState({
      facebook: true,
      instagram: true,
      github: true,
      snapchat: true,
      telegram: true,
      whatsapp: true,
      twitter: true,
      discord: true,
      reddit: true,
      spotify: true,
      linkedin: true,
      tiktok : true,
      youtube : true,
      kickstarter : true,
      paypal : true,
      soundcloud : true,
      twitch : true,
      tumblr : true,
      steam : true,
      pinterest : true,
      booksy : true,
      amazon : true,
      bereal : true,
      own : true,
      own1 : true,

    });
    
    useEffect(() => {
      setIsEnabled(prevState => {
        const updatedIsEnabled = {};
        for (const key in  user.icons) {
          updatedIsEnabled[key] =  user.icons[key]  === " " ? prevState[key] : false;
        }
        return {...prevState, ...updatedIsEnabled};
      });
    }, [ user.icons]);

    
    /* funkcja zmieniajaca stan wygladu inputa */
    
    

      
      
      

    
    const changeInput = (key) => {
      setEditStates(prevState => ({
          ...prevState,
          [key]: true
        }));
        
        
  }

  const cancelChanges = (key) => {
      setEditStates(prevState => ({
          ...prevState,
          [key]: false
        }));
        
        
  }

    
    
    /*funkcja update'ujaca linki ktore sa w bazie danych */ 
    

    const addToPage = () => {
      let link = links[selectedIcon];
      
      if(link === '' || link.startsWith('http://') || link.startsWith('https://')) {



    }
    else {
      link = 'https://' + link;
      console.log(link);
    }
        
      
        axios.patch(`https://api.tagu.pl/updateIcon`, {
          selectedItem: selectedIcon,
          link: link
        }, {
          headers: {
            Authorization : `Bearer ${token}`  // Replace 'your-token' with the actual JWT token or custom header value required for authentication
          }
        })
          .then(response => {
            setUser((prevState) => ({
              ...prevState,
              icons: response.data,
            }));
            
            
          })
          .catch(error => {
            console.error(error);
          });
      setOpenEditIconsAdded(false);
      
  }
    /*Kopiowanie linku*/ 
    const inputRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);
  
    const copyToClipboard = () => {
      inputRef.current.select();
      document.execCommand('copy');
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1750); // Ukryj komunikat po 1.75s
    };
    
    
    /* funkcja usuwajaca ikonke ze strony i bazy danych */

    const deleteIcon = (key) => {
      const link = ' ';
      
     
      axios.patch(`https://api.tagu.pl/updateIcon`, {
      selectedItem: key,
      link: link,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setUser((prevState) => ({
          ...prevState,
          icons: response.data,
          
          
        }));
        isEnabled[key]= true
        
        links[key] = ''
        
      })
      .catch(error => {
        console.error(error);
      });
    }

    /* pin input */
    const [pins, setPins] = useState(['', '', '', '']);
    const inputsRef = useRef([]);
  
    const handlePinChange = (e, index) => {
      let { value } = e.target;
      value = value.replace(/\D/g, '');
    
      const newPins = [...pins];
      newPins[index] = value;
      setPins(newPins);
    
      if (value.length === 1 && index < 3) {
        // Dodanie znaku spowodowało, że pole ma 1 znak
        if (index === 3 && value.length === 1) {
          // Jeśli jesteśmy w ostatnim polu, opuść kursor z pola
          inputsRef.current[index].blur();
        } else {
          // Przenieś kursor do następnego pustego pola lub do tego samego pola
          const nextEmptyIndex = newPins.findIndex((pin, i) => i > index && !pin);
          inputsRef.current[nextEmptyIndex !== -1 ? nextEmptyIndex : index + 1].focus();
        }
      }
    };
    
    
    
    
    
    
    
    
    
    
    
    




    const editPage = (event,key) => {
      let link = links[key];
      console.log(links[key])
      
      
      
      event.preventDefault();
      axios.patch(`https://api.tagu.pl/updateIcon`, {
      selectedItem: key,
      link: link,
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setUser((prevState) => ({
          ...prevState,
          icons: response.data,
        }));
        editStates[key] = false;

       
      })
     
      .catch(error => {
        console.error(error);
      });
  }
   if (!authorized) {
        return null;
    }
    const handleRadioChange = (event) => {
      if(isPremium) {
        if(event.target.value==='userView') {
          setSelectedOption('userView');
          const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
        
          // Wysłanie żądania do API z nagłówkiem uwierzytelniającym
          axios.patch('https://api.tagu.pl/updateVisitorType', { visitorType: event.target.value }, config)
          .then((response) => {
            setUser((prevState) => ({
              ...prevState,
              visitorType: response.data.visitorType,
              
              
            }));
            
            
          })
          .catch((error) => {
            // Obsłuż błąd tutaj
            console.error('Błąd podczas wykonywania żądania:', error);
          });
          
        }
        else {
          setSelectedOption('firmView');
          const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
          const config = {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          };
        
          // Wysłanie żądania do API z nagłówkiem uwierzytelniającym
          axios.patch('https://api.tagu.pl/updateVisitorType', { visitorType: event.target.value }, config)
          .then((response) => {
            setUser((prevState) => ({
              ...prevState,
              visitorType: response.data.visitorType,
              
              
            }));
            
            
          })
          .catch((error) => {
            // Obsłuż błąd tutaj
            console.error('Błąd podczas wykonywania żądania:', error);
          });
        }
      }
      else {
      
        
      const value = event.target.value;
      if(event.target.value==='userView') {
         setModalChange(true)
         setSelectedOption('userView');
         return;
         
      }
      
       else {
         setModal2Change(true);
         setSelectedOption('firmView');
         return;
      
   };
      }
 }
   const changeVisitorType = ()=> {
     const value = selectedOption
     if(selectedOption==='userView') {
     setModalChange(false);
   
     // Pobranie tokenu z localStorage lub innego źródła
     const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
     const config = {
       headers: {
         'Authorization': `Bearer ${token}`
       }
     };
   
     // Wysłanie żądania do API z nagłówkiem uwierzytelniającym
     axios.patch('https://api.tagu.pl/updateVisitorType', { visitorType: value }, config)
       .then(response => {
         setUser((prevState) => ({
           ...prevState,
           visitorType: response.data.visitorType,
           firmAdress : ' ',
           firmBio : ' ',
           firmPersonalName : ' ',
           firmPersonalLastName : ' ',
           firmName : ' ',
           firmNip : ' ',
           firmCity : ' ',
           firmPost : ' ',
           firmHeader : 'TagU',
           firmRegon : ' ',
           billing1 : ' ',
           billing2 : ' ',
           billing3 : ' ',
           firstName : "Imie",
               lastName : "Nazwisko",

               icons: {
                 facebook: " ",
                 instagram: " ",
                 snapchat: " ",
                 youtube: " ",
                 tiktok: " ",
                 linkedin: " ",
                 whatsapp: " ",
                 twitter: " ",
                 bereal: " ",
                 twitch: " ",
                 discord: " ",
                 github: " ",
                 pinterest: " ",
                 spotify: " ",
                 soundcloud: " ",
                 tumblr: " ",
                 amazon: " ",
                 paypal: " ",
                 reddit: " ",
                 steam: " ",
                 booksy: " ",
                 kickstarter: " ",
                 telegram: " ",
                 own: " ",
                 own1 : " "
               },
               contactswitch: false,
               url: "",
               company: "",
               picture: "https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fxd.png?alt=media&token=c6984522-1947-4aad-a701-a8a6ec696376&_gl=1*sjjg7u*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjAwNzU0Mi4yNS4xLjE2ODYwMDc2NTcuMC4wLjA.",
               contactname: "",
               contactsurname: "",
               contactphone: "",
               contactemail: "",
               galleryswitch: false,
               photo1: "",
               photo2: "",
               photo3: "",
               postswitch: false,
               posturl: "",
               contactpin: "",
               bio : "Opis",
               videoURL : '',
               
               

               
               contactpinswitch: false,

           
         }));
         setContactUser({
          contactname: '',
          contactsurname: '',
          contactphone: '',
          contactemail: '',
          company: '',
          url: '',
          jobtitle : '',
          pin: ''
        });
         const updatedIsEnabled = { ...isEnabled }; // Tworzenie kopii obiektu isEnabled

          Object.keys(updatedIsEnabled).forEach((key) => {
            updatedIsEnabled[key] = true; // Przypisanie wartości true dla każdego klucza
          });

          setIsEnabled(updatedIsEnabled); // Ustawienie zaktualizowanego obiektu isEnabled
          const updatedLinks = { ...links }; // Tworzenie kopii obiektu links

        Object.keys(updatedLinks).forEach((key) => {
          updatedLinks[key] = ''; // Przypisanie wartości spacji dla każdego klucza
        });
        setOpenGalleryButton(false);
         setOpenContactButton(false);
         setOpenContactPinButton(false);
         setOpenPostButton(false);
         setOpenVideoButton(false);
         setOpenReviewButton(false);
        setLinks(updatedLinks);
         
         // Tutaj możesz dodać dodatkową logikę po otrzymaniu odpowiedzi z serwera
       })
       .catch(error => {
         console.error('Błąd podczas wysyłania żądania:', error);
         // Tutaj możesz obsłużyć błąd w odpowiedni sposób
       });
     }
     else {
       setModal2Change(false);
   
     // Pobranie tokenu z localStorage lub innego źródła
     const token = localStorage.getItem('token'); // Przykładowe źródło tokenu
   
     // Ustawienie nagłówka 'Authorization' z tokenem uwierzytelniającym
     const config = {
       headers: {
         'Authorization': `Bearer ${token}`
       }
     };
     axios.patch('https://api.tagu.pl/updateVisitorType', { visitorType: value }, config)
       .then(response => {
         setUser((prevState) => ({
           ...prevState,
           visitorType: response.data.visitorType,
           firstName: "Imie",
               lastName: "Nazwisko",
               bio: "",
               icons: {
                 facebook: " ",
                 instagram: " ",
                 snapchat: " ",
                 youtube: " ",
                 tiktok: " ",
                 linkedin: " ",
                 whatsapp: " ",
                 twitter: " ",
                 bereal: " ",
                 twitch: " ",
                 discord: " ",
                 github: " ",
                 pinterest: " ",
                 spotify: " ",
                 soundcloud: " ",
                 tumblr: " ",
                 amazon: " ",
                 paypal: " ",
                 reddit: " ",
                 steam: " ",
                 booksy: " ",
                 kickstarter: " ",
                 telegram: " ",
                 own: " ",
                 own1 : " "
               },
               photo1 : "",
               photo2 : "",
               photo3 : "",
               url: "",
               company: "",
               firmHeader : "TagU",
               picture : "https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fxd.png?alt=media&token=c6984522-1947-4aad-a701-a8a6ec696376&_gl=1*sjjg7u*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjAwNzU0Mi4yNS4xLjE2ODYwMDc2NTcuMC4wLjA.",
               contactname: "",
               contactsurname: "",
               contactphone: "",
               contactemail: "",
               bio : "Opis",
               videoURL : "",
               posturl: "",
               contactpin: "",
               

               
               



           
         }));
         setContactUser({
          contactname: '',
          contactsurname: '',
          contactphone: '',
          contactemail: '',
          company: '',
          url: '',
          pin: ''
        });
         setOpenGalleryButton(false);
         setOpenContactButton(false);
         setOpenContactPinButton(false);
         setOpenPostButton(false);
         setOpenVideoButton(false);
         setOpenReviewButton(false);
         
         const updatedIsEnabled = { ...isEnabled }; // Tworzenie kopii obiektu isEnabled

         Object.keys(updatedIsEnabled).forEach((key) => {
           updatedIsEnabled[key] = true; // Przypisanie wartości true dla każdego klucza
         });

         
         setIsEnabled(updatedIsEnabled); // Ustawienie zaktualizowanego obiektu isEnabled
         const updatedLinks = { ...links }; // Tworzenie kopii obiektu links

        Object.keys(updatedLinks).forEach((key) => {
          updatedLinks[key] = ''; // Przypisanie wartości spacji dla każdego klucza
        });

        setLinks(updatedLinks);
         
         // Tutaj możesz dodać dodatkową logikę po otrzymaniu odpowiedzi z serwera
       })
       .catch(error => {
         
         // Tutaj możesz obsłużyć błąd w odpowiedni sposób
       });

       

     }
   }
     

    return (

        <div translate="no" style={{border: 'none'}}>
            <Card style={{backgroundColor : cardBackground, color: textCardColor, border: 'none'}} className="visitor-card">
                <Navbar
                    style={{backgroundColor : navbarBackground, color: textCardColor, borderRadius: '0'}}
                    // bg={navbarBackground}
                    variant="dark"
                    className="justify-content-between card-header">
                    <Navbar.Brand style={{color : navbarTextColor}}> <Button onClick={openEditPanel} size='sm' style={{backgroundColor : cardBackground, border:navbarTextColor, color: textCardColor, marginBottom: "3px", marginLeft:"-15px"}}>{words[language].navbarEdit}</Button> </Navbar.Brand>
                    <Nav>

                        <a style={{color : navbarTextColor}} onClick={handleLogout} className="logowanie">{words[language].logout}</a>

                    </Nav>
                </Navbar>

                <div className="profile-image-container">
                    <Image
                        className="cover-image"
                        src={cardStyles.coverPhoto}
                        fluid/>

                    {/* <button onClick={() => setShowOffcanvas(true)} style={{backgroundColor : navbarButtonColor}} className="profile-button">Personalizuj</button> */}

                    <Image style={{border : profilePictureBorder}}  className="profile-image-dashboard" src={user.picture } onClick={openPersonalInfo}/>
                    
                    <div className="underlay">
                        <div className="underlay-text">{words[language].edit}</div>
                    </div>

                </div>

                <Card.Body>
                    <div className="theme-buttons ">
                          
                            <button className="btn btn-outline-secondary square-button1" onClick={(event) => handleThemeChange("white",event)}></button>
                        
                            <button className="btn btn-outline-secondary square-button2" onClick={(event) => handleThemeChange("lightgrey",event)}></button>
                          
                            <button className="btn btn-outline-secondary square-button3" onClick={(event) => handleThemeChange("purple",event)}></button>

                            <button className="btn btn-outline-secondary square-button4" onClick={(event) => handleThemeChange("green",event)}></button>
                          
                    </div>
                    <Card.Title className="name-info mt-1 mb-1" onClick={openPersonalInfo}>
                    
                    
                      {user.visitorType==='firmView'  ? (<>{user.firstName} {user.lastName}</>) : (<p>{user.firstName} {' '}  {user.lastName} 
                    </p>)}
                  
                  
                        
                    </Card.Title>

                                      {user.visitorType === 'firmView' && (
                    <Card.Text className="firm-personal-name">
                      <h5>
                        {user.firmName !== ' '? `${user.firmName}` : ''}
                      </h5>
                    </Card.Text>
                  )}
                  
                  {user.visitorType === 'firmView' && user.jobDisplay !== ' ' && (
                    <Card.Text className="firm-job">
                      <b>{words[language].jobContent}</b> {user.jobDisplay}
                    </Card.Text>
                  )}
                    

                    {user.visitorType === 'firmView' && 
                      (user.firmAdress !== ' ' || user.firmPost !== ' ' || user.firmCity !== ' ') && (
                        <Card.Text className="firm-adress">
                          <b>{words[language].firmAddress}: {''}</b>
                          {/* Zbudowanie pełnego adresu z dostępnych części */}
                          <a 
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${user.firmAdress} ${user.firmPost} ${user.firmCity}`.trim())}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                          >
                            {`${user.firmAdress !== ' ' ? user.firmAdress : ''}${user.firmPost !== ' ' ? `, ${user.firmPost}` : ''}${user.firmCity !== ' ' ? ` ${user.firmCity}` : ''}`}
                          </a>
                        </Card.Text>
                      )}


                   {user.visitorType === 'firmView' && user.firmNip !== ' ' && (
                    <Card.Text className="firm-nip">
                      <b>NIP:</b> {user.firmNip}
                    </Card.Text>
                  )}
                   {user.visitorType === 'firmView' && user.firmRegon !== ' ' && (
                    <Card.Text className="firm-regon">
                      <b>Regon:</b> {user.firmRegon}
                    </Card.Text>
                  )}
                  {user.visitorType === 'firmView' && user.billing1 !== ' ' && (
                    <Card.Text className="billing1">
                     {displayBankLogo1 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo1} alt="Bank Logo" />
                             {" "}            {user.billing1}</>) :  (<><BsBank>   </BsBank> {' '} {user.billing1} </>)}
                    </Card.Text>
                  )}
                  {user.visitorType === 'firmView' && user.billing2 !== ' ' && (
                    <Card.Text className="billing2">
                      {displayBankLogo2 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo2}></img> {" "} {user.billing2}</>) :  (<><BsBank>   </BsBank> {' '} {user.billing2} </>)}
                    </Card.Text>
                  )}
                  {user.visitorType === 'firmView' && user.billing3 !== ' ' && (
                    <Card.Text className="billing3">
                     {displayBankLogo3 ? (<><img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={displayBankLogo3}></img> {" "} {user.billing3}</>) :  (<><BsBank>   </BsBank> {' '} {user.billing3} </>)}
                    </Card.Text>
                  )}

                  {user.visitorType === 'userView' && (
                          <Card.Text className="bio-content" onClick={openPersonalInfo}>
                            <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap", wordBreak: "break-word" }} className="bio-content">
                              {user.bio}{' '}
                            </pre>
                            {/* <FontAwesomeIcon
                              icon={faPencil}
                              size="xs"
                              onClick={() => setShowOffcanvas(true)}
                            /> */}
                          </Card.Text>
                        )}
                        {user.visitorType === 'firmView' && user.firmBio && (
                          <Card.Text className="bio-content" onClick={openPersonalInfo}>
                            <pre style={{ fontFamily: "inherit", whiteSpace: "pre-wrap", wordBreak: "break-word" }} className="bio-content">
                              {user.firmBio}{' '}
                            </pre>
                            {/* <FontAwesomeIcon
                              icon={faPencil}
                              size="xs"
                              onClick={() => setShowOffcanvas(true)}
                            /> */}
                          </Card.Text>
                        )}
                        
                        <div>
                     <Modal show={stepConfiguration1} onHide={() => setStepConfiguration1(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration1(false);setCancelConfiguration(true);setRememberedStep("conf1")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><BsFillGearFill></BsFillGearFill></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step1Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step1Text}</p>
                             

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step1Step2}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                     <div>
                     <Modal show={stepConfiguration2} onHide={() => setStepConfiguration2(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration2(false);setCancelConfiguration(true);setRememberedStep("conf2")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><BiUser></BiUser></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step2Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step2Text}</p>
                           <Form>
                            
                              <div className="mb-3">
                                <Form.Check
                                  inline
                                  label={words[language].userView}
                                  name="group1"
                                  type="radio"
                                  id="radio-1"
                                  value="userView"
                                  onChange={configuratorRadioChange}
                                  checked={user.visitorType === 'userView'}
                                />
                                <Form.Check
                                  inline
                                  label={words[language].firmView}
                                  name="group1"
                                  type="radio"
                                  id="radio-2"
                                  value="firmView"
                                  onChange={configuratorRadioChange}
                                  checked={user.visitorType === 'firmView'}
                                />
                                
                              </div>
                           
                          </Form>

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step2Step3}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   <div>
                   <Modal show={stepConfiguration3} onHide={() => setStepConfiguration3(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration3(false);setCancelConfiguration(true);;setRememberedStep("conf3")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><IoMdColorPalette></IoMdColorPalette></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step3Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step3Text}</p>
                           <div style={{marginBottom:"20px"}} className="theme-buttons ">
                          
                            <button className="btn btn-outline-secondary square-button1" onClick={(event) => handleThemeChange("white", event)}></button>
                        
                            <button className="btn btn-outline-secondary square-button2" onClick={(event) => handleThemeChange("lightgrey",event)}></button>
                          
                            <button className="btn btn-outline-secondary square-button3" onClick={(event) => handleThemeChange("purple",event)}></button>

                            <button className="btn btn-outline-secondary square-button4" onClick={(event) => handleThemeChange("green",event)}></button>
                          
                    </div>

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step3Step4}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   
                   <div>
                     <Modal show={stepConfiguration4} onHide={() => setStepConfiguration4(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration4(false);setCancelConfiguration(true);setRememberedStep("conf4")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><BiUser></BiUser> </span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step4Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step4Text}</p>
                           <Form.Group>
                           <Form.Control

                            type="text"
                            onChange={saveNameHandler}
                            placeholder={user.firstName}
                            maxLength="32"
                            value={tempName}
                            
                          />
                          <br></br>
                           <Form.Control
                            type="text"
                            onChange={saveLastHandler}
                            placeholder={user.lastName}
                            maxLength="32"
                            value={tempLastName}
                            style={{marginBottom:"15px"}}
                             // Pole zostanie zablokowane, jeśli user.firstName jest puste
                            />
                            </Form.Group>

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step4Step5}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   <div>
                     <Modal show={stepConfiguration5} onHide={() => setStepConfiguration5(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration5(false);setCancelConfiguration(true);setRememberedStep("conf5")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><MdPhotoCamera></MdPhotoCamera></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step5Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step5Text}</p>
                           <InputGroup className="mb-3">
                            <input id="files" style={{display:"none"}}  onChange={fetchImageData}  type="file" accept="image/*" />                         
                            <label htmlFor="file-input" className="file-input-label" for="files">{profilePictureSpinner ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : <div>{words[language].addPhoto}</div>}</label>
                            <Form.Control
                            placeholder={selectedImage ? words[language].choosePhoto : words[language].noChoosePhoto}
                            disabled
                            />
                            </InputGroup>
                            {selectedImage && (
                                                  <>  
                                                    <AvatarEditor
                                                      ref={editorRef}
                                                      image={selectedImage}
                                                      width={150}
                                                      height={150}
                                                      border={50}
                                                      borderRadius={100}
                                                      color={[255, 255, 255, 0.6]}
                                                      borderColor={[0, 0, 0, 1]} // czarne obramowanie
                                                      scale={1}
                                                      rotate={0}
                                                      style={{marginBottom:"20px"}} />
                                                       </>)}

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step5Step6}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   <div>
                     <Modal show={stepConfiguration6} onHide={() => setStepConfiguration6(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration6(false);setCancelConfiguration(true);setRememberedStep("conf6")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><GrContact></GrContact></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step6Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step6Text}</p>
                           

                           <div class="d-flex">
                           
                             <div class="mx-auto">
                             <Form.Check
                          type="switch"
                          
                          label={<span style={{ color: textCardColor }}>{words[language].contact}</span>}
                          checked={openContactButton}
                          onClick={toggleContactButton}
                          /> <br></br>
                              <Collapse in={openContactButton}>
                                <div>
                                <Form.Group >
                                                                   
                                                                  
                                                                   <Form.Control
                                                                       type="text"
                                                                       name="contactname"
                                                                       placeholder={words[language].firstName}
                                                                       maxLength="32"
                                                                       value={contactUser.contactname}
                                                                       onChange={handleContactUserChange}/>
                                                               </Form.Group> <br></br>
                                                               <Form.Group >
                                                                   
                                                                   <Form.Control
                                                                       type="text"
                                                                       placeholder={words[language].lastName}
                                                                       name="contactsurname"
                                                                       maxLength="32"
                                                                       value={contactUser.contactsurname}
                                                                       onChange={handleContactUserChange}/>
                                                               </Form.Group> <br></br>
                                                               <Form.Group >
                                                                   
                                                                   <Form.Control
                                                                       type="text"
                                                                       placeholder="Email"
                                                                       name="contactemail"
                                                                       maxLength="64"
                                                                       value={contactUser.contactemail}
                                                                       onChange={handleContactUserChange}/>
                                                               </Form.Group> <br></br>
                                                               <Form.Group>
                                                             
                                                             <Form.Control
                                                                 type="tel"
                                                                 placeholder={words[language].phone}
                                                                 name="contactphone"
                                                                 pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // dodajemy wzorzec dla numeru telefonu
                                                                 maxLength="16"
                                                                 value={contactUser.contactphone}
                                                                 onChange={handleContactUserChange}
                                                             />
                                                             
                                                         </Form.Group>
                                                         </div>
                                                         </Collapse> <br></br>
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step6Step7}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   <div>
                     <Modal show={stepConfiguration7} onHide={() => setStepConfiguration7(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration7(false);setCancelConfiguration(true);setRememberedStep("conf7")}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><GrGallery></GrGallery></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step7Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step7Text}</p>
                             

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].sectionPhoto}</span>}
                                                        checked={openGalleryButton}
                                                        onClick={toggleGalleryButton} /> <br></br>
                                                      
                              <Collapse in={openGalleryButton}>
                                <div>
                                 
                                                        <InputGroup className="mb-3">
                            <input id="files" style={{display:"none"}}  onChange={fetchPhoto1}  type="file" accept="image/*" />                         
                            <label htmlFor="file-input" className="file-input-label" for="files">{<div>{words[language].addPhoto}</div>}</label>
                            <Form.Control
                            placeholder={confPhoto1 ? words[language].choosePhoto : words[language].noChoosePhoto}
                            disabled
                            />
                            </InputGroup>

                                  </div>
                              </Collapse> <br></br>
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step7Step8}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>

                   <div>
                     <Modal show={stepConfiguration8} onHide={() => setStepConfiguration8(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => {setStepConfiguration8(false);setRememberedStep("conf8");setCancelConfiguration(true)}} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><BsCheckCircleFill></BsCheckCircleFill></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].step8Configuration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].step8Text}</p>
                             

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={step8Step9}  variant="primary">
                              {words[language].changeStep}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   <div>
                     <Modal show={cancelConfiguration} onHide={() => setCancelConfiguration(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => setCancelConfiguration(false)} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><MdCancel></MdCancel></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].cancelConfiguration}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].cancelText}</p>
                             

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor, margin : "5px"}} onClick={backToStep}  variant="primary">
                              {words[language].cancelBack}
                         </Button>
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor, margin : "5px"}} onClick={cancelSteps}  variant="primary">
                              {words[language].cancelStep}
                         </Button>
                         
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                   

                    <div className="text-center mb-2">
                      <Collapse in={openContactButton}>
                        <div >
                          <Button onClick={openContact} className="btn1" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} href="#target">
                            {words[language].contactEditButton}
                          </Button>
                        </div>
                      </Collapse>
                    </div>
                    <div className="text-center mb-2">
                      <Collapse in={openReviewButton}>
                        <div >
                          
                          <Button target="_blank" href={reviewUrl} className="btn1" style={{color : "black", backgroundColor:"#ebb734", border: "black"}} >
                          <FontAwesomeIcon icon={faGoogle}></FontAwesomeIcon> {words[language].reviewus} 
                          </Button>
                          
                        </div>
                      </Collapse>
                    </div>
                    
                    <div class="social-wrapper">
                    
                    
                  
                  
                    <div className="social-icons" style={{ border: Object.values(user.icons).filter(value => value !== ' ').length >= 4 ? '1px solid #00000040' : 'none' }}>
                     {Object.entries(user.icons).map(([key, value]) => {
                        if (value !== ' ') {
                          return (
                            <a href={value} target="_blank" rel="noopener noreferrer">
                              {key === 'booksy' ? (
                                
                                <svg style={{borderRadius:"15%", width: "60px"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 400.000000 400.000000"
                                          preserveAspectRatio="xMidYMid meet">

                                          <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
                                          fill={iconsColor} stroke="none">
                                          <path d="M0 2000 l0 -2000 2000 0 2000 0 0 2000 0 2000 -2000 0 -2000 0 0
                                          -2000z m1956 885 c11 -15 14 -69 14 -265 0 -136 3 -249 6 -253 3 -3 41 12 84
                                          34 111 57 207 75 321 60 155 -20 271 -79 368 -188 177 -198 214 -520 88 -758
                                          -47 -88 -156 -194 -247 -238 -116 -57 -185 -72 -335 -72 -106 0 -145 5 -210
                                          23 -158 46 -239 92 -548 315 -81 58 -186 125 -232 148 -77 38 -93 42 -158 42
                                          -61 0 -82 -4 -124 -27 -47 -26 -123 -109 -123 -134 0 -24 -43 -33 -152 -30
                                          l-113 3 -3 32 c-6 63 85 200 183 274 123 93 311 136 459 106 113 -23 182 -60
                                          351 -187 325 -244 457 -313 642 -335 169 -19 354 78 420 221 34 75 42 194 19
                                          284 -73 285 -390 385 -588 185 -74 -74 -100 -139 -107 -267 -7 -132 -14 -142
                                          -78 -108 -25 13 -70 41 -99 62 l-54 39 -2 499 c-2 274 -1 509 2 521 8 31 34
                                          39 123 36 65 -2 81 -6 93 -22z"/>
                                          </g>
                                          </svg>
                              ) : key === 'bereal' ? (
                               <svg style={{width:"60px"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 249.000000 243.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <g transform="translate(0.000000,243.000000) scale(0.100000,-0.100000)"
                              fill={iconsColor} stroke="none">
                              <path d="M610 2354 c-81 -9 -157 -27 -202 -49 -145 -70 -244 -193 -279 -350
                              -20 -88 -20 -1415 0 -1495 48 -191 188 -331 381 -381 62 -17 1276 -25 1412
                              -10 124 14 191 37 273 96 120 85 187 210 206 388 15 131 6 1360 -10 1412 -60
                              198 -190 327 -381 376 -32 9 -237 13 -705 14 -363 1 -676 1 -695 -1z m-15
                              -959 c52 -25 72 -96 40 -142 -14 -20 -13 -24 10 -47 20 -20 25 -35 25 -74 0
                              -41 -5 -53 -31 -78 -29 -28 -34 -29 -145 -32 l-114 -4 0 196 0 196 91 0 c61 0
                              102 -5 124 -15z m652 -4 c43 -20 73 -62 73 -101 0 -28 -25 -76 -49 -92 -19
                              -13 -18 -16 21 -83 56 -98 56 -95 12 -95 -38 0 -40 2 -79 73 -38 68 -42 72
                              -75 72 l-35 0 -3 -72 -3 -73 -34 0 -35 0 0 195 0 195 83 0 c59 0 94 -6 124
                              -19z m778 -176 l0 -190 -37 -3 -38 -3 0 196 0 196 38 -3 37 -3 0 -190z m-1096
                              69 c28 -20 51 -72 51 -114 l0 -30 -90 0 c-96 0 -110 -9 -70 -45 16 -14 30 -17
                              74 -12 52 5 55 4 61 -20 7 -31 -4 -40 -63 -49 -59 -9 -127 18 -153 60 -23 38
                              -25 125 -4 166 8 16 28 36 43 44 37 21 122 20 151 0z m643 5 c24 -13 57 -83
                              58 -121 l0 -28 -90 0 c-96 0 -110 -9 -70 -45 15 -14 31 -17 70 -13 42 4 53 2
                              60 -13 15 -26 13 -29 -25 -45 -46 -19 -81 -17 -133 6 -96 44 -105 205 -14 254
                              32 18 115 21 144 5z m287 -4 c39 -20 51 -61 51 -171 l0 -94 -40 0 c-27 0 -40
                              4 -40 14 0 10 -9 9 -40 -6 -54 -27 -105 -11 -126 39 -27 62 18 112 101 113 49
                              0 68 11 63 36 -4 25 -75 33 -84 10 -8 -20 -74 -22 -74 -3 0 20 30 58 54 67 34
                              14 104 11 135 -5z m281 -225 c0 -39 -1 -40 -35 -40 -26 0 -35 4 -37 18 0 9 -2
                              24 -3 32 -2 25 5 30 41 30 33 0 34 -1 34 -40z"/>
                              <path d="M450 1300 l0 -50 41 0 c56 0 79 14 79 51 0 34 -27 49 -86 49 l-34 0
                              0 -50z"/>
                              <path d="M450 1135 l0 -55 55 0 c42 0 60 5 75 20 45 45 8 90 -75 90 l-55 0 0
                              -55z"/>
                              <path d="M1117 1343 c-4 -3 -7 -30 -7 -60 l0 -53 45 0 c85 0 117 72 48 109
                              -24 12 -76 15 -86 4z"/>
                              <path d="M816 1224 c-27 -26 -18 -34 39 -34 58 0 66 7 37 33 -23 21 -56 22
                              -76 1z"/>
                              <path d="M1468 1229 c-10 -5 -18 -17 -18 -24 0 -11 14 -15 56 -15 56 0 56 0
                              42 23 -17 25 -54 32 -80 16z"/>
                              <path d="M1748 1129 c-22 -13 -23 -45 -2 -53 42 -16 84 6 84 45 0 15 -7 19
                              -32 19 -18 0 -41 -5 -50 -11z"/>
                              </g>
                              </svg>
                              ) : (
                                <FontAwesomeIcon
                                  style={{ color: iconsColor }}
                                  href={value}
                                  icon={iconMap[key]}
                                  className="icon"
                                />
                              )}
                            </a>
                          );
                        } else {
                          
                          return null;
                        }
                       
                      })}
                       <a onClick={ () => {openLinks(); setOpenEditIcons(!openEditIcons)}}> <CiSquarePlus className="icon" style={{color : iconsColor, opacity : "0.75"}}></CiSquarePlus>  </a>
                      
                        
                      </div>
                       


                      <Collapse in={openGalleryButton} className='mt-2'>
                        <div>
                        
                        {user.photo1 || user.photo2 || user.photo3 ? (
  <Carousel id={countPhoto===1 ? "onePhoto" : 'morePhoto'} ref={carouselRef}>
    {user.photo1 && user.photo1 !== "" && (
      <Carousel.Item>
        <div className="carousel-image-container rounded">
          <img
            className="centered-image d-block rounded"
            src={user.photo1}
            alt="First slide"
            onLoad={handleImageLoad}
          />
        </div>
      </Carousel.Item>
    )}

    {user.photo2 && user.photo2 !== "" && (
      <Carousel.Item>
        <div className="carousel-image-container rounded">
          <img
            className="centered-image d-block rounded"
            src={user.photo2}
            alt="Second slide"
            onLoad={handleImageLoad}
          />
        </div>
      </Carousel.Item>
    )}

    {user.photo3 && user.photo3 !== "" && (
      <Carousel.Item>
        <div className="carousel-image-container rounded">
          <img
            className="centered-image d-block rounded"
            src={user.photo3}
            alt="Third slide"
            onLoad={handleImageLoad}
          />
        </div>
      </Carousel.Item>
    )}
  </Carousel>
) : null}

                      
                      </div>
                    </Collapse>  

                    
                      <Collapse in={openPostButton}> 
                          <div className='igpost text-center mt-2'>
                          {user && user.posturl && validateUrl(user.posturl) && (
                          <iframe
                            title="Instagram Embed"
                            src={user.posturl}
                            width="100%"
                            height="600"
                            frameborder="0"
                            scrolling="no"
                            allowtransparency="true"
                          ></iframe>
                        )}
                      </div>
                      
                    </Collapse>
                    <Collapse in={openVideoButton}> 
                          <div className='igpost text-center mt-2'>
                          {user && user.videoURL && (
                           <iframe width="100%" height="600" src={user.videoURL} frameborder="0" allowfullscreen></iframe>
                        )}
                      </div>
                      
                    </Collapse>    

                      <Offcanvas
                          id= {cardBackground==="#040404"  ? "custom" : "custom2"}
                          show={showOffcanvas}
                          onHide={() => {setShowOffcanvas(false);toast.dismiss();}}
                          
                          placement="bottom"
                          style={{maxWidth:'450px' ,width:'100%', height: '100%'}}
                          >
                            {showOffcanvas && (
                            <ToastContainer
                              position="top-center"
                              autoClose={2000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              limit="4"
                              pauseOnFocusLoss="false"
                              draggable="false"
                              pauseOnHover="false"
                              theme="dark"
                              
              
                            />
                            
                            )}
                          <Offcanvas.Header closeButton style={{backgroundColor : cardBackground, color: textCardColor}}>
                              <Offcanvas.Title>{words[language].editPanel}</Offcanvas.Title>
                              
                          </Offcanvas.Header>
                          <Offcanvas.Body style={{backgroundColor : cardBackground, color: textCardColor}}>
                          

                              <div className='collapsed-edit-blocks'>
                                  <div className='d-grid gap-2'>
                                      <Button onClick={toggleCollapse} variant="primary" size="lg" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                          {user.visitorType === 'userView' ? (<div>{words[language].personalData}</div>) : (<div>{words[language].firmData}</div>)}
                                      </Button>
                                  </div>
                                  <Collapse in={open}>
                                      <div>
                                          <br/>
                                          <Form>
                                              <Form.Group >
                                                  <Form.Label style={{color: textCardColor}}>{words[language].profilePicture}:</Form.Label>
                                                  
                                                  <input id="files" style={{display:"none"}}  onChange={fetchImageData}  type="file" accept="image/*" />
                                                  {user.picture && user.picture.length > 0 && user.picture !== 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fxd.png?alt=media&token=c6984522-1947-4aad-a701-a8a6ec696376&_gl=1*sjjg7u*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NjAwNzU0Mi4yNS4xLjE2ODYwMDc2NTcuMC4wLjA.' ? (
                                                    <InputGroup className="mb-3">
                                                      <label htmlFor="file-input" className="file-input-label" for="files">{profilePictureSpinner ? (<><FontAwesomeIcon icon={faSpinner} spinPulse /></>) : (<>{words[language].changePhoto}</>)}</label>
                                                      <Form.Control
                                                        placeholder={words[language].choosePhoto}
                                                        disabled
                                                      />
                                                      <button  name="picture" className="delete-button btn btn-outline-danger" onClick={handleDeleteProfileClick}>
                                                        {words[language].deletePhoto}
                                                      </button>
                                                    </InputGroup>
                                                  ) : (
                                                    <InputGroup className="mb-3">
                                                      
                                                      <label htmlFor="file-input" className="file-input-label" for="files">{profilePictureSpinner ? <FontAwesomeIcon icon={faSpinner} spinPulse /> : <div>{words[language].addPhoto}</div>}</label>
                                                      <Form.Control
                                                        placeholder={selectedImage ? words[language].choosePhoto : words[language].noChoosePhoto}
                                                        disabled
                                                      />
                                                    </InputGroup>
                                                  )}


                                                  
                                                  {selectedImage && (
                                                  <>  
                                                    <AvatarEditor
                                                      ref={editorRef}
                                                      image={selectedImage}
                                                      width={150}
                                                      height={150}
                                                      border={50}
                                                      borderRadius={100}
                                                      color={[255, 255, 255, 0.6]}
                                                      borderColor={[0, 0, 0, 1]} // czarne obramowanie
                                                      scale={1}
                                                      rotate={0}
                                                    /><br/>
                                                    <Button
                                                        className='save-buttons'
                                                        onClick={(e)=>saveImageHandler(e)}
                                                        variant="primary"
                                                        type="submit"
                                                        style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                                                        >
                                                        {words[language].save}
                                                    </Button>
                                                  </>
                                                )}
    
                                                    
                                                    
                                                    
                                                </Form.Group>
                                                


                                                    {user.visitorType === 'userView' ? (
                                                <div>
                                                  <Form.Group>
                                                    <Form.Label style={{ color: textCardColor }}>{words[language].firstName}:</Form.Label>
                                                    <InputGroup>
                                                    <Form.Control
                                                      type="text"
                                                      onChange={saveNameHandler}
                                                      placeholder={user.firstName}
                                                      maxLength="32"
                                                      value={tempName}
                                                      disabled={user.firstName !== ''} // Pole zostanie zablokowane, jeśli user.firstName jest puste
                                                    />
                                                    {user.firstName? ( // Sprawdzamy, czy user.firstName nie jest puste
                                                      <>
                                                        
                                                        <Button onClick={deletePersonalViewName} variant="outline-danger">
                                                          {words[language].delete}
                                                        </Button>
                                                      </>
                                                    ) : null}
                                                  </InputGroup>
                                                  </Form.Group>
                                                  <Form.Group>
                                                    <Form.Label style={{ color: textCardColor }}>{words[language].lastName}:</Form.Label>
                                                    <InputGroup>
                                                    <Form.Control
                                                      type="text"
                                                      onChange={saveLastHandler}
                                                      placeholder={user.lastName}
                                                      maxLength="32"
                                                      value={tempLastName}
                                                      disabled={user.lastName !== ''} // Pole zostanie zablokowane, jeśli user.firstName jest puste
                                                    />
                                                    {user.lastName ? ( // Sprawdzamy, czy user.firstName nie jest puste
                                                      <>
                                                        
                                                        <Button onClick={deletePersonalViewLastName} variant="outline-danger">
                                                          {words[language].delete}
                                                        </Button>
                                                      </>
                                                    ) : null}
                                                  </InputGroup>
                                                  </Form.Group>
                                                  <Form.Group>
                                                    <Form.Label style={{ color: textCardColor }}>{words[language].bio}:</Form.Label>
                                                    <Form.Control
                                                      onChange={saveBioHandler}
                                                      type="text"
                                                      id="opis"
                                                      as="textarea"
                                                      maxLength="255"
                                                      rows={4}
                                                      value={inputBio}
                                                      placeholder={words[language].bio}
                                                    />
                                                  </Form.Group>
                                                  <div className="save-buttons-container">
                                                    <Button
                                                      className="save-buttons"
                                                      onClick={saveDataHandler}
                                                      variant="primary"
                                                      
                                                      type="submit"
                                                      style={{
                                                        color: textButtonContactColor,
                                                        backgroundColor: buttonContactColor,
                                                        border: buttonContactColor
                                                      }}
                                                    >
                                                      {words[language].save}
                                                    </Button>
                                                  </div>
                                                </div>
                                              ) : (
                                                
                                                <div>
                                                  
                                                  {user.firmName != ' '    ? <>
                                                    <Form.Label  style={{color: textCardColor}}>{words[language].firmName}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl  maxLength="48" value={firmName}  disabled={user.firmName != ' ' && !enabledNameInput}   placeholder={user.firmName ? user.firmName : words[language].firmName} name="firmName"  onChange={(e) => {setFirmName(e.target.value)} }
                                                      />
                                                      {user.firmName != ' ' && !enabledNameInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledNameInput(true)}   variant="outline-warning" >{words[language].edit}</Button>
                                                      <Button onClick={deleteFirmNameHandler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveFirmName} variant="outline-success" >{words[language].firmButtonSave}</Button>
                                                      <Button onClick={() => {setInputFirmName(false); setSelectedButton(null);setEnabledNameInput(false);setFirmName(user.firmName)
                                                      }}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firstName != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].firstName}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="32" value={personalFirstName}  disabled={user.firstName != ' ' && !enabledPersonalNameInput}   placeholder={user.firstName ? user.firstName :words[language].firstName} name="firstName"  onChange={(e) => {setPersonalFirstName(e.target.value)} }
                                                      />
                                                      {user.firstName != ' ' && !enabledPersonalNameInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledPersonalNameInput(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deletePersonalName}  variant="outline-danger">{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={savePersonalName} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputPersonalName(false); setSelectedButton(null);setEnabledPersonalNameInput(false);setPersonalFirstName(user.firstName)
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.lastName != ' '    ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].lastName}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="32" value={personalLastName}  disabled={user.lastName != ' ' && !enabledPersonalLastNameInput}   placeholder={user.lastName ? user.lastName : words[language].lastNam} name="lastName"  onChange={(e) => {setPersonalLastName(e.target.value)} }
                                                      />
                                                      {user.lastName != ' ' && !enabledPersonalLastNameInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledPersonalLastNameInput(true)}   variant="outline-warning" >{words[language].edit}</Button>
                                                      <Button onClick={deletePersonalLastName}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={savePersonalLastName} variant="outline-success" >{words[language].save}</Button>
                                                      <Button onClick={() => {setInputPersonalLastName(false); setSelectedButton(null);setEnabledPersonalLastNameInput(false);setPersonalLastName(user.lastName)
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firmBio != ' '    ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].firmBio}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                      <Form.Control 
                                                        as="textarea" 
                                                        rows={4} 
                                                        maxLength="655" 
                                                        value={firmBio} 
                                                        disabled={user.firmBio != ' ' && !enabledBioInput} 
                                                        placeholder={user.firmBio ? user.firmBio : words[language].firmBio} 
                                                        name="firmBio"  
                                                        onChange={(e) => {setFirmBio(e.target.value)}}
                                                      />
                                                      {user.firmBio != ' ' && !enabledBioInput ? (
                                                        <>
                                                          <Button onClick={() => setEnabledBioInput(true)} variant="outline-warning">
                                                            {words[language].edit}
                                                          </Button>
                                                          <Button onClick={deleteFirmBioHandler} variant="outline-danger">
                                                            {words[language].delete}
                                                          </Button>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <Button onClick={saveFirmBio} variant="outline-success">
                                                            {words[language].save}
                                                          </Button>
                                                          <Button 
                                                            onClick={() => {
                                                              setInputFirmBio(false); 
                                                              setSelectedButton(null);
                                                              setEnabledBioInput(false);
                                                              setFirmBio(user.firmBio);
                                                            }}  
                                                            variant="outline-danger"
                                                          >
                                                            {words[language].firmButtonCancel}
                                                          </Button>
                                                        </>
                                                      )}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.jobDisplay != ' '    ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].jobtitle}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="255" value={jobDisplay}  disabled={user.jobDisplay != ' ' && !enabledJobInput}   placeholder={user.jobDisplay ? user.jobDisplay : words[language].jobDisplay} name="jobDisplay"  onChange={(e) => {setJobDisplay(e.target.value)} }
                                                      />
                                                      {user.jobDisplay != ' ' && !enabledJobInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledJobInput(true)} variant="outline-warning" >{words[language].edit}</Button>
                                                      <Button onClick={deleteJobDisplay}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveJobDisplay} variant="outline-success" >{words[language].save}</Button>
                                                      <Button onClick={() => {setInputJob(false); setSelectedButton(null);setEnabledJobInput(false);setJobDisplay(user.jobDisplay)
                                                      }}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firmAdress != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].firmStreet}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="48" value={firmAdress}  disabled={user.firmAdress != ' ' && !enabledAdressInput}   placeholder={user.firmAdress ? user.firmAdress : words[language].firmStreet} name="firmAdress"  onChange={(e) => {setAdress(e.target.value)} }
                                                      />
                                                      {user.firmAdress != ' ' && !enabledAdressInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledAdressInput(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteFirmAdressHandler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveAdress} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputAdress(false); setSelectedButton(null);setEnabledAdressInput(false);setAdress(user.firmAdress)
                                                      }}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firmPost != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].firmPost}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="6" value={firmPost}  disabled={user.firmPost != ' ' && !enabledPostInput}   placeholder={user.firmPost ? user.firmPost : words[language].firmPost} name="firmPost"  onChange={firmPostHandler }
                                                      />
                                                      {user.firmPost != ' ' && !enabledPostInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledPostInput(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteFirmPostHandler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveFirmPost} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputAdress(false); setSelectedButton(null);setEnabledPostInput(false);setFirmPost(user.firmPost)
                                                      }}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firmCity != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].firmCity}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="48" value={firmCity}  disabled={user.firmCity != ' ' && !enabledCityInput}   placeholder={user.firmCity ? user.firmCity : words[language].firmCity} name="firmCity"  onChange={(e) => {setFirmCity(e.target.value)} }
                                                      />
                                                      {user.firmCity != ' ' && !enabledCityInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledCityInput(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteFirmCityHandler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveFirmCity} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputAdress(false); setSelectedButton(null);setEnabledCityInput(false);setFirmCity(user.firmAdress)
                                                      }}  variant="outline-danger" >{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  
                                                  {user.firmNip   != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>NIP:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="18" value={firmNip}  disabled={user.firmNip != ' ' && !enabledNipInput}   placeholder={user.firmNip ? user.firmNip : "NIP"} name="firmNip"  onChange={(e) => {setNip(e.target.value)} }
                                                      />
                                                      {user.firmNip != ' ' && !enabledNipInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledNipInput(true)} variant="outline-warning" >{words[language].edit}</Button>
                                                      <Button onClick={deleteFirmNipHandler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveNip} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputNip(false); setSelectedButton(null);setEnabledNipInput(false);setNip(user.firmNip)
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.firmRegon  != ' '   ? <>
                                                    <Form.Label style={{color: textCardColor}}>REGON:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    
                                                     <FormControl maxLength="14" value={firmRegon}  disabled={user.firmRegon != ' ' && !enabledRegonInput}   placeholder={user.firmRegon ? user.firmRegon : "REGON"} name="firmRegon"  onChange={(e) => {setRegon(e.target.value)} }
                                                      />
                                                      {user.firmRegon != ' ' && !enabledRegonInput ? (
                                                        <>
                                                      <Button onClick={() => setEnabledRegonInput(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteRegonHandler}  variant="outline-danger">{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveRegon} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputRegon(false); setSelectedButton(null);setEnabledRegonInput(false);setRegon(user.firmRegon);
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.billing1  != ' '  ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].billing1}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    <InputGroup.Text>
                                                    {bankLogo1 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={bankLogo1}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                    
                                                     <FormControl maxLength="36" value={billing1}  disabled={user.billing1 != ' ' && !enabledBillingInput1}   placeholder={user.billing1 ? user.billing1 : words[language].billing1} name="billing1"  onChange={billing1Handler }
                                                      />
                                                      {user.billing1 != ' ' && !enabledBillingInput1 ? (
                                                        <>
                                                      <Button onClick={() => setEnabledBillingInput1(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteBilling1Handler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveBilling1} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputBilling1(false); setSelectedButton(null);setEnabledBillingInput1(false);setBilling1(user.billing1);setBankLogo1(displayBankLogo1)
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.billing2  != ' '  ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].billing2}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    <InputGroup.Text>
                                                    {bankLogo2 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}} src={bankLogo2}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                    
                                                     <FormControl maxLength="36" value={billing2}  disabled={user.billing2 != ' ' && !enabledBillingInput2}   placeholder={user.billing2 ? user.billing2 : words[language].billing2} name="billing2"  onChange={billing2Handler }
                                                      />
                                                      {user.billing2 != ' ' && !enabledBillingInput2 ? (
                                                        <>
                                                      <Button onClick={() => setEnabledBillingInput2(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteBilling2Handler} variant="outline-danger">{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button onClick={saveBilling2} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputBilling2(false); setSelectedButton(null);setEnabledBillingInput2(false);setBilling2(user.billing2);setBankLogo2(displayBankLogo2);
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {user.billing3  != ' '  ? <>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].billing3}:</Form.Label>
                                                    <InputGroup className='mb-1'>
                                                    <InputGroup.Text>
                                                    {bankLogo3 ? (<img style={{height:"30px", width:"30px", borderRadius: "50%"}}  src={bankLogo3}></img>) : (<BsBank></BsBank>)}
                                                   </InputGroup.Text>
                                                    
                                                     <FormControl maxLength="36" value={billing3}  disabled={user.billing3 != ' ' && !enabledBillingInput3}   placeholder={user.billing3 ? user.billing3 : words[language].billing3} name="billing3"  onChange={billing3Handler }
                                                      />
                                                      {user.billing3 != ' ' && !enabledBillingInput3 ? (
                                                        <>
                                                      <Button onClick={() => setEnabledBillingInput3(true)} variant="outline-warning">{words[language].edit}</Button>
                                                      <Button onClick={deleteBilling3Handler}  variant="outline-danger" >{words[language].delete}</Button>
                                                      </>) : (<>
                                                        <Button   onClick={saveBilling3} variant="outline-success">{words[language].save}</Button>
                                                      <Button onClick={() => {setInputBilling3(false); setSelectedButton(null);setEnabledBillingInput3(false);setBilling3(user.billing3);setBankLogo3(displayBankLogo3)
                                                      }}  variant="outline-danger">{words[language].firmButtonCancel}</Button>
                                                    </>)}
                                                    </InputGroup>
                                                  </> : <></>}
                                                  {renderInput()}
                                                  
                                                  {!inputFirmName && user.firmName===' ' ? (
                                                    <div className="d-grid gap-2 mb-2 mt-3">
                                                    <Button
                                                      onClick={firmNameInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus} /> {words[language].addFirmName}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                   {!inputPersonalName && user.firstName=== ' ' ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmPersonalNameInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addPersonalName}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                   <></>
                                                  )}
                                                  {!inputPersonalLastName && user.lastName === ' ' ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmPersonalLastNameInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addLastName}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {!inputFirmBio && user.firmBio === ' ' ? (
                                                    
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmBioInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus} /> {words[language].addFirmBio}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                   <></>
                                                  )}
                                                  {(user.jobDisplay === ' ') ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={jobInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addJob}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                   <></>
                                                  )}
                                                  {!inputAdress && (user.firmAdress === ' ' || user.firmCity === ' ' || user.firmPost === ' ') ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmAdressInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addAddress}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                   <></>
                                                  )}
                                                  {!inputNip && user.firmNip === ' ' ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmNipInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addNip}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {!inputRegon && user.firmRegon === ' ' ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      onClick={firmRegonInputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addRegon}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                    {!inputBilling1 && user.billing1 === ' '   ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      
                                                      onClick={billing1InputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addBilling1}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    
                                                    <></>
                                                    
                                                    
                                                  
                                                  
                                                  )}
                                                  {!inputBilling2  && user.billing2 === ' '  ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      
                                                      onClick={billing2InputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addBilling2}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    
                                                    <></>
                                                    
                                                    
                                                  
                                                  
                                                  )}
                                                  {!inputBilling3  && user.billing3 === ' '  ? (
                                                    <div className="d-grid gap-2 mb-2">
                                                    <Button
                                                      
                                                      onClick={billing3InputHandler}
                                                      variant="secondary"
                                                      size="sm"
                                                    >
                                                      <FontAwesomeIcon icon={faPlus}/> {words[language].addBilling3}
                                                    </Button>
                                                    </div>
                                                  ) : (
                                                    
                                                    <></>
                                                    
                                                    
                                                  
                                                  
                                                  )}
                                                  
                                                  

                                           
                                                  
                                                </div>
                                              )}
                                            </Form>
                                        </div>
                                    </Collapse>
                                </div>




                                <div className='collapsed-edit-blocks'>
                                    <div className='d-grid gap-2'>
                                        <Button onClick={toggleCollapse1} variant="primary" size="lg" block style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                            {words[language].links}
                                        </Button>
                                    </div>
                                    <Collapse in={open1}>
                                        <div>
                                            <br/>
                                            <Form>
                                            <Form.Group>
                                                <Form.Label style={{color: textCardColor}}>{words[language].currentLink}:</Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  value={`https://tagu.pl/users/${user.customlink}`}
                                                  readOnly
                                                  onClick={copyToClipboard}
                                                  ref={inputRef}
                                                />
                                                <Form.Text className="text-muted">
                                                  {words[language].copyClick}
                                                </Form.Text>
                                                <Overlay target={inputRef} show={showTooltip} placement="top">
                                                  {(props) => (
                                                    <Tooltip id="overlay-example" {...props}>
                                                      {words[language].copied}
                                                    </Tooltip>
                                                  )}
                                                </Overlay>
                                              </Form.Group>

                                                <Form.Group  className="mb-2">
                                                    <Form.Label className="mb-0" style={{color: textCardColor}}> {words[language].changeLink}: </Form.Label>
                                                    
                                                    <Form.Select  onChange={(event) => {
                                                        if (event.target.value === words[language].chooseLink) {
                                                            closeEveryOption();
                                                        }
                                                        else if (event.target.value === words[language].generatedLink) {
                                                            openGenerated();
                                                        }

                                                        else if (event.target.value === words[language].personalLink) {
                                                            openCustom();
                                                        }
                                                    }}>
                                                    <option>{words[language].chooseLink}</option>
                                                    <option>{words[language].generatedLink}</option>
                                                    <option>{words[language].personalLink}</option>
                                                    </Form.Select>
                                                    <Collapse in={nullEditMenu}>
                                                        <div></div>
                                                    </Collapse>
                                                    <Collapse in={openGeneratedLink}>
                                                        <div>
                                                            <InputGroup className="mb-1 mt-1" size="sm">
                                                                <Button onClick={saveLinkHandler} variant="outline-primary">
                                                                {words[language].generate}
                                                                </Button>
                                                                <Form.Control 
                                                                    placeholder={`https://tagu.pl/users/${tempLink}`}
                                                                    readOnly
                                                                    />
                                                            </InputGroup>
                                                            <div className="d-grid gap-2">
                                                                <Button onClick={updateGenerateHandler} variant="secondary" size="sm">
                                                                    {words[language].changeHandler}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                    <Collapse in={openCustomLink}>
                                                        <div>
                                                            <Form.Control
                                                            className="mb-1 mt-1"
                                                            type="text"
                                                            placeholder={words[language].placeholderCustom}
                                                            
                                                            onChange={saveOwnHandler}
                                                            size="sm"
                                                            />
                                                            <div className="d-grid gap-2">
                                                                <Button onClick={updateOwnHandler} variant="secondary" size="sm">
                                                                    {words[language].changeHandler}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </Form.Group>




                                                <Form.Label style={{color: textCardColor}}>{words[language].linkIcons}:</Form.Label>
                                                <FormGroup >
                            
                                                {Object.entries( user.icons).map(([key, value]) => {
                                                if (value !== " ") {
                                                  const Icon = iconsMap[key];
                                                  return (
                                                    <InputGroup className="mb-3" key={key}>
                                                      <InputGroup.Text>
                                                        {key === "bereal" ? (
                                                          <img src={icon} style={{ width: "18px" }} alt="Icon" />
                                                        ) : (
                                                          <>
                                                          {key === "booksy" ? (
                                                            <img src={icon2} style={{ color : iconsColor, width: "18px" }} alt="Icon" />
                                                          ) : (
                                                            
                                                            <Icon />
                                                          )}
                                                          </>
                                                          
                                                          
                                                        )}
                                                      </InputGroup.Text>
                                                      {!editStates[key] ? (
                                                        <>
                                                          <FormControl
                                                            placeholder={`${value}`}
                                                            name={key}
                                                            disabled
                                                            onChange={handleLinkChange}
                                                          />
                                                          <Button onClick={() => changeInput(key)} variant="outline-warning" size="sm">
                                                            {words[language].edit}
                                                          </Button>
                                                          <Button onClick={() => deleteIcon(key)} variant="outline-danger" size="sm">
                                                            {words[language].delete}
                                                          </Button>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <FormControl
                                                            placeholder={`${value}`}
                                                            name={key}
                                                            onChange={handleLinkChange}
                                                          />
                                                          <Button onClick={(event) => editPage(event,key)} variant="outline-success">
                                                            {words[language].save}
                                                          </Button>
                                                          <Button onClick={() => cancelChanges(key)} variant="outline-danger">
                                                            {words[language].firmButtonCancel}
                                                          </Button>
                                                        </>
                                                      )}
                                                    </InputGroup>
                                                  );
                                                }
                                              })}

                                                <Collapse in={openEditIconsAdded}>
                                                    <div>
                                                        {renderForm()}
                                                    </div>
                                                </Collapse>
    
                                                <div className="d-grid gap-2"> <Button variant="secondary" size="sm" onClick={() => setOpenEditIcons(!openEditIcons)} > <FontAwesomeIcon icon={faPlus} />{words[language].addIcon}</Button></div>
                                                    <Collapse in={openEditIcons}>
                                                        <Container>
                                                            <ButtonGroup size="sm" className="button-group-wrap">
                                                                <Button disabled={!isEnabled.facebook} id="facebook" onClick={() => handleIconClick('facebook')} 
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaFacebook size={18} className="mr-2" /><br/>Facebook
                                                                </Button>

                                                                <Button disabled={!isEnabled.instagram} id="instagram" onClick={() => handleIconClick('instagram')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaInstagram size={18} className="mr-2" /><br/>Instagram
                                                                </Button>

                                                                <Button disabled={!isEnabled.snapchat} id="snapchat" onClick={() => handleIconClick('snapchat')} 
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaSnapchat size={18} className="mr-2" /><br/>Snapchat
                                                                </Button>

                                                                <Button disabled={!isEnabled.youtube} id="youtube" onClick={() => handleIconClick('youtube')} 
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaYoutube size={18} className="mr-2" /><br/>Youtube
                                                                </Button>
                                                                
                                                            </ButtonGroup>
                                                            <ButtonGroup size="sm" className="button-group-wrap">

                                                                <Button disabled={!isEnabled.tiktok}   id="tiktok" onClick={() => handleIconClick('tiktok')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaTiktok size={18} className="mr-2" /><br/>Tiktok
                                                                </Button>

                                                                <Button disabled={!isEnabled.linkedin} id="linkedin" onClick={() => handleIconClick('linkedin')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaLinkedin size={18} className="mr-2" /><br/>Linkedin
                                                                </Button>

                                                                <Button disabled={!isEnabled.whatsapp} id="whatsapp" onClick={() => handleIconClick('whatsapp')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaWhatsapp size={18} className="mr-2" /><br/>Whatsapp
                                                                </Button>
                                                                <Button disabled={!isEnabled.twitter} id="twitter" onClick={() => handleIconClick('twitter')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaTwitter size={18} className="mr-2" /><br/>Twitter
                                                                </Button>

                                                                
                                                            </ButtonGroup>
                                                            <ButtonGroup size="sm" className="button-group-wrap">
                                                            <Button disabled={!isEnabled.bereal} id="bereal" onClick={() => handleIconClick('bereal')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                               <svg style={{width:"18px"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 249.000000 243.000000"
                                                                preserveAspectRatio="xMidYMid meet">

                                                                <g transform="translate(0.000000,243.000000) scale(0.100000,-0.100000)"
                                                                fill={textButtonContactColor} stroke="none">
                                                                <path d="M610 2354 c-81 -9 -157 -27 -202 -49 -145 -70 -244 -193 -279 -350
                                                                -20 -88 -20 -1415 0 -1495 48 -191 188 -331 381 -381 62 -17 1276 -25 1412
                                                                -10 124 14 191 37 273 96 120 85 187 210 206 388 15 131 6 1360 -10 1412 -60
                                                                198 -190 327 -381 376 -32 9 -237 13 -705 14 -363 1 -676 1 -695 -1z m-15
                                                                -959 c52 -25 72 -96 40 -142 -14 -20 -13 -24 10 -47 20 -20 25 -35 25 -74 0
                                                                -41 -5 -53 -31 -78 -29 -28 -34 -29 -145 -32 l-114 -4 0 196 0 196 91 0 c61 0
                                                                102 -5 124 -15z m652 -4 c43 -20 73 -62 73 -101 0 -28 -25 -76 -49 -92 -19
                                                                -13 -18 -16 21 -83 56 -98 56 -95 12 -95 -38 0 -40 2 -79 73 -38 68 -42 72
                                                                -75 72 l-35 0 -3 -72 -3 -73 -34 0 -35 0 0 195 0 195 83 0 c59 0 94 -6 124
                                                                -19z m778 -176 l0 -190 -37 -3 -38 -3 0 196 0 196 38 -3 37 -3 0 -190z m-1096
                                                                69 c28 -20 51 -72 51 -114 l0 -30 -90 0 c-96 0 -110 -9 -70 -45 16 -14 30 -17
                                                                74 -12 52 5 55 4 61 -20 7 -31 -4 -40 -63 -49 -59 -9 -127 18 -153 60 -23 38
                                                                -25 125 -4 166 8 16 28 36 43 44 37 21 122 20 151 0z m643 5 c24 -13 57 -83
                                                                58 -121 l0 -28 -90 0 c-96 0 -110 -9 -70 -45 15 -14 31 -17 70 -13 42 4 53 2
                                                                60 -13 15 -26 13 -29 -25 -45 -46 -19 -81 -17 -133 6 -96 44 -105 205 -14 254
                                                                32 18 115 21 144 5z m287 -4 c39 -20 51 -61 51 -171 l0 -94 -40 0 c-27 0 -40
                                                                4 -40 14 0 10 -9 9 -40 -6 -54 -27 -105 -11 -126 39 -27 62 18 112 101 113 49
                                                                0 68 11 63 36 -4 25 -75 33 -84 10 -8 -20 -74 -22 -74 -3 0 20 30 58 54 67 34
                                                                14 104 11 135 -5z m281 -225 c0 -39 -1 -40 -35 -40 -26 0 -35 4 -37 18 0 9 -2
                                                                24 -3 32 -2 25 5 30 41 30 33 0 34 -1 34 -40z"/>
                                                                <path d="M450 1300 l0 -50 41 0 c56 0 79 14 79 51 0 34 -27 49 -86 49 l-34 0
                                                                0 -50z"/>
                                                                <path d="M450 1135 l0 -55 55 0 c42 0 60 5 75 20 45 45 8 90 -75 90 l-55 0 0
                                                                -55z"/>
                                                                <path d="M1117 1343 c-4 -3 -7 -30 -7 -60 l0 -53 45 0 c85 0 117 72 48 109
                                                                -24 12 -76 15 -86 4z"/>
                                                                <path d="M816 1224 c-27 -26 -18 -34 39 -34 58 0 66 7 37 33 -23 21 -56 22
                                                                -76 1z"/>
                                                                <path d="M1468 1229 c-10 -5 -18 -17 -18 -24 0 -11 14 -15 56 -15 56 0 56 0
                                                                42 23 -17 25 -54 32 -80 16z"/>
                                                                <path d="M1748 1129 c-22 -13 -23 -45 -2 -53 42 -16 84 6 84 45 0 15 -7 19
                                                                -32 19 -18 0 -41 -5 -50 -11z"/>
                                                                </g>
                                                                </svg> <br/> Bereal
                                                                </Button>
                                                                

                                                                <Button disabled={!isEnabled.twitch} id="twitch" onClick={() => handleIconClick('twitch')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaTwitch size={18} className="mr-2" /><br/>Twitch
                                                                </Button>

                                                                <Button disabled={!isEnabled.discord} id="discord" onClick={() => handleIconClick('discord')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaDiscord size={18} className="mr-2" /><br/>Discord
                                                                </Button>

                                                                <Button disabled={!isEnabled.github} id="github" onClick={() => handleIconClick('github')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaGithub size={18} className="mr-2" /><br/>Github
                                                                </Button>
                                                            </ButtonGroup>
                                                            <ButtonGroup size="sm" className="button-group-wrap">

                                                                <Button disabled={!isEnabled.pinterest} id="pinterest" onClick={() => handleIconClick('pinterest')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaPinterest size={18} className="mr-2" /><br/>Pinterest
                                                                </Button>

                                                                <Button disabled={!isEnabled.spotify} id="spotify" onClick={() => handleIconClick('spotify')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaSpotify size={18} className="mr-2" /><br/>Spotify
                                                                </Button>

                                                                <Button disabled={!isEnabled.soundcloud} id="soundcloud" onClick={() => handleIconClick('soundcloud')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaSoundcloud size={18} className="mr-2" /><br/>SoundCld
                                                                </Button>

                                                                <Button disabled={!isEnabled.telegram} id="telegram" onClick={() => handleIconClick('telegram')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaTelegram size={18} className="mr-2" /><br/>Telegram
                                                                </Button>
                                                            </ButtonGroup>
                                                            <ButtonGroup size="sm" className="button-group-wrap">

                                                                <Button disabled={!isEnabled.amazon} id="amazon" onClick={() => handleIconClick('amazon')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaAmazon size={18} className="mr-2" /><br/>Amazon
                                                                </Button>

                                                                <Button disabled={!isEnabled.paypal} id="paypal" onClick={() => handleIconClick('paypal')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaPaypal size={18} className="mr-2" /><br/>Paypal
                                                                </Button>

                                                                <Button disabled={!isEnabled.reddit} id="reddit" onClick={() => handleIconClick('reddit')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaReddit size={18} className="mr-2" /><br/>Reddit
                                                                </Button>

                                                                <Button disabled={!isEnabled.steam} id="steam" onClick={() => handleIconClick('steam')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaSteam size={18} className="mr-2" /><br/>Steam
                                                                </Button>
                                                            </ButtonGroup>
                                                            <ButtonGroup size="sm" className="button-group-wrap">

                                                                <Button disabled={!isEnabled.booksy} id="booksy" onClick={() => handleIconClick('booksy')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                 <svg style={{width: "18px"}} version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 400.000000 400.000000"
                                                                  preserveAspectRatio="xMidYMid meet">

                                                                  <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
                                                                  fill={textButtonContactColor} stroke="none">
                                                                  <path d="M0 2000 l0 -2000 2000 0 2000 0 0 2000 0 2000 -2000 0 -2000 0 0
                                                                  -2000z m1956 885 c11 -15 14 -69 14 -265 0 -136 3 -249 6 -253 3 -3 41 12 84
                                                                  34 111 57 207 75 321 60 155 -20 271 -79 368 -188 177 -198 214 -520 88 -758
                                                                  -47 -88 -156 -194 -247 -238 -116 -57 -185 -72 -335 -72 -106 0 -145 5 -210
                                                                  23 -158 46 -239 92 -548 315 -81 58 -186 125 -232 148 -77 38 -93 42 -158 42
                                                                  -61 0 -82 -4 -124 -27 -47 -26 -123 -109 -123 -134 0 -24 -43 -33 -152 -30
                                                                  l-113 3 -3 32 c-6 63 85 200 183 274 123 93 311 136 459 106 113 -23 182 -60
                                                                  351 -187 325 -244 457 -313 642 -335 169 -19 354 78 420 221 34 75 42 194 19
                                                                  284 -73 285 -390 385 -588 185 -74 -74 -100 -139 -107 -267 -7 -132 -14 -142
                                                                  -78 -108 -25 13 -70 41 -99 62 l-54 39 -2 499 c-2 274 -1 509 2 521 8 31 34
                                                                  39 123 36 65 -2 81 -6 93 -22z"/>
                                                                  </g>
                                                                  </svg>
                                                                  <br/> Booksy
                                                                
                                                                </Button>

                                                                <Button disabled={!isEnabled.kickstarter} id="kickstarter" onClick={() => handleIconClick('kickstarter')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaKickstarter size={18} className="mr-2" /><br/>Kickstarter
                                                                </Button>
                                                                
                                                                <Button disabled={!isEnabled.own} id="own" onClick={() => handleIconClick('own')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaLink size={18} className="mr-2" /><br/>{words[language].own}
                                                                </Button>

                                                                <Button disabled={!isEnabled.own1} id="own" onClick={() => handleIconClick('own1')}
                                                                className="social-button" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                                                <FaLink size={18} className="mr-2" /><br/>{words[language].own1}
                                                                </Button>

                                                            </ButtonGroup>

                                                        </Container>
                                                    </Collapse>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Collapse>
                                </div>

                                

                                <div className='collapsed-edit-blocks'>
                                    <div className='d-grid gap-2'>
                                        <Button onClick={toggleCollapse2} variant="primary" size="lg" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                            {words[language].contact}
                                        </Button>
                                    </div>
                                    <Collapse in={open2}>
                                        <div >
                                            <Form >
                                                <FormGroup>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].buttonContact}:</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].contact}</span>}
                                                        checked={openContactButton}
                                                        onClick={toggleContactButton}
                                                        />
                                                        {contactSpinner && <FontAwesomeIcon icon={faSpinner} spinPulse />}
                                                        

                                                            <Form.Text className="text-muted">
                                                                {words[language].toggleContact}
                                                            </Form.Text>
                                                            <Collapse in={openContactButton}>
                                                                <div>
                                                                    <Form.Group>
                                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].secureContact}: </span>}
                                                        checked={openContactPinButton}
                                                        onClick={toggleContactPinButton}
                                                        />
                                                        </Form.Group>
                                                        <Form.Group>
                                                        {openContactPinButton && (
                                                            <>
                                                                <Form.Label style={{color: textCardColor}}>PIN:</Form.Label>
                                                               
                                                                <div style={{ display: "flex" }}>
                                                                {contactPinSet  ? (
                                                                <div>
                                                                 
                                                                 <Button onClick={deletePinValue} variant="outline-danger">
                                                                    {words[language].resetPin}
                                                                   </Button>

                                                                </div>
                                                              ) : (
                                                                <div className="form-group mb-1 mt-3" style={{width:"100%"}}>
                                                                  <form style={{textAlign:"center"}}>
                                                                      <div style={{marginLeft:"3px"}}>
                                                                        {pins.map((pin, index) => (
                                                                          <input
                                                                            key={index}
                                                                            type="tel" // Używamy type="tel", aby ograniczyć wprowadzanie tylko do cyfr
                                                                            maxLength={1}
                                                                            value={pin}
                                                                            onChange={(e) =>handlePinChange(e, index)}
                                                                            className='pinInputs'
                                                                            style={{borderColor: textCardColor, color: textCardColor }}
                                                                            ref={(input) => (inputsRef.current[index] = input)}
                                                                            
                                                                          />
                                                                        ))}
                                                                      </div>
                                                                  <br/>
                                                            
                                                          </form>
                                                          </div>
                                                              )}

                                                              </div>
                                                                
                                                                
                                                              {!contactPinSet && (
                                                              <Button
                                                                className='save-buttons'
                                                                onClick={savePinData}
                                                                variant="primary"
                                                                type="submit"
                                                                style={{
                                                                  color: textButtonContactColor,
                                                                  backgroundColor: buttonContactColor,
                                                                  border: buttonContactColor
                                                                }}
                                                              >
                                                                {words[language].savePin}
                                                              </Button>
                                                            )}


                                                            </>
                                                            
                                                        )}
                                                    </Form.Group>

                                                               
                                                                    <Form.Group >
                                                                   
                                                                        <Form.Label style={{color: textCardColor}}>{words[language].firstName}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="contactname"
                                                                            placeholder={words[language].firstName}
                                                                            maxLength="32"
                                                                            value={contactUser.contactname}
                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <Form.Group >
                                                                        <Form.Label style={{color: textCardColor}}>{words[language].lastName}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={words[language].lastName}
                                                                            name="contactsurname"
                                                                            maxLength="32"
                                                                            value={contactUser.contactsurname}
                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <Form.Group >
                                                                        <Form.Label style={{color: textCardColor}}>Email:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Email"
                                                                            name="contactemail"
                                                                            maxLength="64"
                                                                            value={contactUser.contactemail}
                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                  <Form.Label style={{color: textCardColor}}>{words[language].phone}:</Form.Label>
                                                                  <Form.Control
                                                                      type="tel"
                                                                      placeholder={words[language].phone}
                                                                      name="contactphone"
                                                                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // dodajemy wzorzec dla numeru telefonu
                                                                      maxLength="16"
                                                                      value={contactUser.contactphone}
                                                                      onChange={handleContactUserChange}
                                                                  />
                                                                  
                                                              </Form.Group>
                                                                    <Form.Group >
                                                                        <Form.Label style={{color: textCardColor}}>{words[language].company}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={words[language].company}
                                                                            name="company"
                                                                            value={contactUser.company}
                                                                            maxLength="48"
                                                                            

                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <Form.Group >
                                                                        <Form.Label style={{color: textCardColor}}>{words[language].jobtitle}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={words[language].jobtitle}
                                                                            name="jobtitle"
                                                                            value={contactUser.jobtitle}
                                                                            maxLength="48"
                                                                            

                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <Form.Group >
                                                                        <Form.Label style={{color: textCardColor}}>{words[language].url}:</Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={words[language].url}
                                                                            name="url"
                                                                            maxLength="64"
                                                                            value={contactUser.url}
                                                                            onChange={handleContactUserChange}/>
                                                                    </Form.Group>
                                                                    <div className='save-buttons-container'>
                                                                        <Button
                                                                            className='save-buttons'
                                                                            onClick={saveContactData}
                                                                            variant="primary"
                                                                            type="submit"
                                                                            style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                                                                            >
                                                                            {words[language].save}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    </Collapse>
                                </div>

                                <div className='collapsed-edit-blocks'>
                                    <div className='d-grid gap-2'>
                                        <Button onClick={toggleCollapse3} variant="primary" size="lg" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                            {words[language].photoAndOthers}
                                        </Button>
                                    </div>
                                    <Collapse in={open3}>
                                        <div >
                                          {user.visitorType==='firmView' ? (<>
                                            <Form.Group>
                                                    <Form.Label style={{ color: textCardColor }}>{words[language].firmHeader}:</Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      onChange={saveFirmHeaderHandler}
                                                      value={firmHeader}
                                                      maxLength="6"
                                                    />
                                                  </Form.Group>
                                                  <Button
                                                      className="save-buttons"
                                                      onClick={saveHeaderData}
                                                      variant="primary"
                                                      type="submit"
                                                      style={{
                                                        color: textButtonContactColor,
                                                        backgroundColor: buttonContactColor,
                                                        border: buttonContactColor
                                                      }}
                                                    >
                                                      {words[language].save}
                                                    </Button>
                                          </>) : <></>}
                                            <Form >
                                                <FormGroup>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].photos}:</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].sectionPhoto}</span>}
                                                        checked={openGalleryButton}
                                                        onClick={toggleGalleryButton}
                                                        />
                                                        {gallerySpinner && <FontAwesomeIcon icon={faSpinner} spinPulse />}

                                                            <Form.Text className="text-muted">
                                                                {words[language].toggleGallery}
                                                            </Form.Text>

                                                            <Collapse in={openGalleryButton}>
                                                                <div>
                                                                    <Form>
                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].photo} 1:</Form.Label>
                                                                      <input id="files1" style={{display:"none"}}  onChange={photoHandler}  type="file" accept="image/*" />
                                                                      
                                                                      
                                                                      {user.photo1 && user.photo1.length > 0 ? (
                                                                       <InputGroup className="mb-3">
                                                                        <label htmlFor="file-input" className="file-input-label" for="files1">{photo1Spinner ? (<><FontAwesomeIcon icon={faSpinner} spinPulse /></>) : (<>{words[language].changePhoto}</>)}</label>
                                                                        <Form.Control
                                                                          placeholder={words[language].choosePhoto}
                                                                          disabled
                                                                        />
                                                                        <button name="photo1" className="delete-button btn btn-outline-danger" onClick={handleDeleteClick}>
                                                                         {words[language].delete}
                                                                        </button>
                                                                     </InputGroup>
                                                                      ) : (
                                                                        <InputGroup className="mb-3">
                                                                        <label htmlFor="file-input" className="file-input-label" for="files1"> {photo1Spinner ?   <FontAwesomeIcon icon={faSpinner} spinPulse /> :  <div>{words[language].addPhoto}</div>}</label>
                                                                        <Form.Control
                                                                          placeholder={photo1 ? words[language].choosePhoto : words[language].noChoosePhoto}
                                                                          disabled
                                                                        />
                                                                      </InputGroup>
                                                                      
                                                                      )}
                                                                    </Form.Group>

                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].photo} 2:</Form.Label>
                                                                      <input id="files2" style={{display:"none"}}  onChange={photo2Handler}  type="file" accept="image/*" />
                                                                      {spinner3 && <FontAwesomeIcon icon={faSpinner} spinPulse />}
                                                                      
                                                                      {user.photo2 && user.photo2.length > 0 ? (
                                                                       <InputGroup className="mb-3">
                                                                        <label htmlFor="file-input" className="file-input-label" for="files2">{photo2Spinner ? (<><FontAwesomeIcon icon={faSpinner} spinPulse /></>) : (<>{words[language].changePhoto}</>)}</label>
                                                                        <Form.Control
                                                                          placeholder={words[language].choosePhoto}
                                                                          disabled
                                                                        />
                                                                        <button name="photo2" className="delete-button btn btn-outline-danger" onClick={handleDeleteClick}>
                                                                          {words[language].delete}
                                                                        </button>
                                                                     </InputGroup>
                                                                      ) : (
                                                                        <InputGroup className="mb-3">
                                                                        <label htmlFor="file-input" className="file-input-label" for="files2">{photo2Spinner ?   <FontAwesomeIcon icon={faSpinner} spinPulse /> :  <div>{words[language].addPhoto}</div>}</label>
                                                                        <Form.Control
                                                                          placeholder={photo2 ? words[language].choosePhoto : words[language].noChoosePhoto}
                                                                          disabled
                                                                        />
                                                                      </InputGroup>
                                                                      
                                                                      )}
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].photo} 3:</Form.Label>
                                                                      <input id="files3" style={{display:"none"}}  onChange={photo3Handler}  type="file" accept="image/*" />
                                                                      {spinner4 && <FontAwesomeIcon icon={faSpinner} spinPulse />}
                                                                      
                                                                      {user.photo3 && user.photo3.length > 0 ? (
                                                                        <InputGroup className="mb-3">
                                                                          <label htmlFor="file-input" className="file-input-label" for="files3">{photo3Spinner ? (<><FontAwesomeIcon icon={faSpinner} spinPulse /></>) : (<>{words[language].changePhoto}</>)}</label>
                                                                          <Form.Control
                                                                            placeholder={words[language].choosePhoto}
                                                                            disabled
                                                                          />
                                                                          <button name="photo3" className="delete-button btn btn-outline-danger" onClick={handleDeleteClick}>
                                                                           {words[language].delete}
                                                                          </button>
                                                                        </InputGroup>
                                                                      ) : (
                                                                        <InputGroup className="mb-3">
                                                                        <label htmlFor="file-input" className="file-input-label" for="files3">{photo3Spinner ?   <FontAwesomeIcon icon={faSpinner} spinPulse /> :  <div>{words[language].addPhoto}</div>}</label>
                                                                        <Form.Control
                                                                          placeholder={photo3 ? words[language].choosePhoto : words[language].noChoosePhoto}
                                                                          disabled
                                                                        />
                                                                      </InputGroup>
                                                                      
                                                                      )}
                                                                    </Form.Group>
                                                                    </Form>
                                                                    <div className='save-buttons-container'>
                                                                       
                                                                        
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].posts}:</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].igpost}</span>}
                                                        checked={openPostButton}
                                                        onClick={togglePostButton}
                                                        />
                                                        {postSpinner && <FontAwesomeIcon icon={faSpinner} spinPulse />}

                                                            <Form.Text className="text-muted">
                                                                {words[language].togglePost}
                                                            </Form.Text>

                                                            <Collapse in={openPostButton}>
                                                                <div>
                                                                    <Form>
                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].iglink}:</Form.Label>
                                                                      <Form.Control
                                                                        type="text"
                                                                        name="contactname"
                                                                        placeholder="URL"
                                                                        maxLength="62"
                                                                        value={posturl && posturl.includes("/embed") ? posturl.replace("/embed", "") : posturl}
                                                                        onChange={handlePostURL}
                                                                      />
                                                                    </Form.Group>
                                                                   
                                                                    
                                                                    </Form>
                                                                    <div className='save-buttons-container'>
                                                                        <Button
                                                                            className='save-buttons'
                                                                            onClick={(e) =>savePostURL(e)}
                                                                            variant="primary"
                                                                            type="submit"
                                                                            style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                                                                            

                                                                            >
                                                                            {words[language].save}
                                                                        </Button>
                                                                        
                                                                        
                                                                    </div>
                                                                   
                                                                </div>
                                                            </Collapse>
                                                        
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].videos}:</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].ytvideo}</span>}
                                                        checked={openVideoButton}
                                                        onClick={toggleVideoButton}
                                                        />
                                                        {postSpinner && <FontAwesomeIcon icon={faSpinner} spinPulse />}

                                                            <Form.Text className="text-muted">
                                                                {words[language].toggleYt}:
                                                            </Form.Text>

                                                            <Collapse in={openVideoButton}>
                                                                <div>
                                                                    <Form>
                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].ytlink}:</Form.Label>
                                                                      <Form.Control
                                                                      type="text"
                                                                      name="ytlink"
                                                                      value={videoUrl}
                                                                      maxLength="62"
                                                                      onChange={handleVideoURL}
                                                                    />

                                                                    </Form.Group>
                                                                   
                                                                    
                                                                    </Form>
                                                                    <div className='save-buttons-container'>
                                                                        <Button
                                                                            className='save-buttons'
                                                                            onClick={(e) =>saveVideoURL(e)}
                                                                            variant="primary"
                                                                            type="submit"
                                                                            
                                                                            style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                                                                            >
                                                                            {words[language].save}
                                                                        </Button>
                                                                        
                                                                        
                                                                    </div>
                                                                   
                                                                </div>
                                                            </Collapse>
                                                        
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label style={{color: textCardColor}}>{words[language].reviews}:</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        label={<span style={{ color: textCardColor }}>{words[language].googlereview}</span>}
                                                        checked={openReviewButton}
                                                        onClick={toggleReviewButton}
                                                        />
                                                        {postSpinner && <FontAwesomeIcon icon={faSpinner} spinPulse />}

                                                            <Form.Text className="text-muted">
                                                                {words[language].reviewtext}:
                                                            </Form.Text>

                                                            <Collapse in={openReviewButton}>
                                                                <div>
                                                                    <Form>
                                                                    <Form.Group>
                                                                      <Form.Label style={{color: textCardColor}}>{words[language].reviewlink}:</Form.Label>
                                                                      <Form.Control
                                                                      type="text"
                                                                      name="ytlink"
                                                                      value={reviewUrl}
                                                                      maxLength="62"
                                                                      onChange={handleReviewURL}
                                                                    />

                                                                    </Form.Group>
                                                                   
                                                                    
                                                                    </Form>
                                                                    <div className='save-buttons-container'>
                                                                        <Button
                                                                            className='save-buttons'
                                                                            onClick={(e) =>saveReviewURL(e)}
                                                                            variant="primary"
                                                                            type="submit"
                                                                            
                                                                            style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                                                                            >
                                                                            {words[language].save}
                                                                        </Button>
                                                                        
                                                                        
                                                                    </div>
                                                                   
                                                                </div>
                                                            </Collapse>
                                                        
                                                </FormGroup>
                                            </Form>
                                        </div>
                                        
                                         
                                    </Collapse>
                                </div>
                                <div className='collapsed-edit-blocks'>
                                    <div className='d-grid gap-2'>
                                        <Button onClick={toggleCollapse5} variant="primary" size="lg" style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}>
                                            {words[language].settings}
                                        </Button>
                                    </div>
                                    <br></br>
                                    <Collapse in={open5}>
                                        <div>
                                        <div>
                                            <Form.Label style={{color: textCardColor}}>{words[language].language}:</Form.Label><br/>
                                            <Form.Text className="text-muted">
                                              {words[language].languageChoose}
                                            </Form.Text>
                                            <div style={{ width:"100%", marginTop:"10px", marginBottom:"20px"}}>
                                            <select
                                            style={{
                                              color: textButtonContactColor,
                                              backgroundColor: buttonContactColor,
                                              border: `2px solid ${buttonContactColor}`,
                                              borderRadius: '8px',
                                              padding: '8px',
                                              fontSize: '16px',
                                              width: '180px',
                                              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${buttonContactColor}" width="18px" height="18px"><path d="M7 10l5 5 5-5z" /></svg>')`,
                                              backgroundRepeat: 'no-repeat',
                                              backgroundPosition: 'right center',
                                              backgroundSize: '18px',
                                              paddingRight: '30px',
                                              appearance: 'none',
                                              WebkitAppearance: 'none',
                                              MozAppearance: 'none',
                                            }}
                                            defaultValue={language}
                                            onChange={(event) => handleChangeLanguage(event.target.value)}
                                          >
                                            <option value="pl">Polski</option>
                                            <option value="en">English</option>
                                          </select>
                                        </div>
                                        </div>

                                        <Form.Label style={{color: textCardColor}}>{words[language].visitorSettings}:</Form.Label>
                                        <Form>
                                        <div key="radio" className="mb-3">
                                          <div>
                                          <Form.Check
                                            inline
                                            name="group1"
                                            type="radio"
                                            id="radio-1"
                                            value="userView"
                                            checked={user.visitorType === 'userView'}
                                            onChange={handleRadioChange}
                                          />
                                          <label style={{color: textCardColor}}>{words[language].userView}</label>
                                          </div>
                                          <div>
                                          <Form.Check
                                            inline
                                            name="group1"
                                            type="radio"
                                            id="radio-2"
                                            value="firmView"
                                            checked={user.visitorType === 'firmView'}
                                            onChange={handleRadioChange}
                                            style={{marginBottom:"10px"}}
                                          />
                                          <label style={{color: textCardColor}} >{words[language].firmView}</label>
                                          </div>
                                        </div>
                        </Form>
                        {modalChange && (
                      <div>
                        <Modal show={modalChange} onHide={() => setModalChange(false)} centered>
                          
                          <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                          <div className="main-content text-center">
                            
                            <a href="#" onClick={() => setModalChange(false)} class="close-btn" data-dismiss="modal" aria-label="Close">
                                <span className="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                              </a>

                            <div className="warp-icon mb-4">
                              <span style={{color:textCardColor}} class="icon-lock2"><BiUser></BiUser></span>
                            </div>
                            <form action="#">
                              <label style={{color : textCardColor}} for="">{words[language].togglePersonalChange}</label>
                              <p style={{color : textCardColor}} class="mb-4">{words[language].togglePersonalText}</p>

                              <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={changeVisitorType}  variant="primary">
                              {words[language].changeHandler}
                         </Button>
                             </div>
                           </div>
                            </form>
                          
                        </div>
                          
                          </Modal.Body>
                          
                         
                        </Modal>
                      </div>
                    )}
                    {modal2Change && (
                     <div>
                     <Modal show={modal2Change} onHide={() => setModal2Change(false)} centered>
                       
                       <Modal.Body style={{padding:"15%", backgroundColor: cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => setModal2Change(false)} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><MdBusinessCenter></MdBusinessCenter></span>
                         </div>
                         <form action="#">
                           <label  style={{color : textCardColor}} for="">{words[language].toggleFirmChange}</label>
                           <p style={{color : textCardColor}} class="mb-4">{words[language].toggleFirmText}</p>
                           

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={changeVisitorType}  variant="primary">
                              {words[language].changeHandler}
                         </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                    )}
                    <Form.Label style={{color: textCardColor}}>{words[language].secure}:</Form.Label><br/>
                    <Form.Text className="text-muted">
                      {words[language].secureText}
                    </Form.Text>
                        {!user.blocked ? (
                        <div>
                        <Button
                          className='save-buttons'
                          onClick={()=> setBlockInput(true)}
                          variant="primary"
                          type="submit"
                          style={{marginTop:"15px", color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                      >
                       {words[language].secure}
                      </Button>
                      </div>) : (
                        <div>
                        <Button
                          className='save-buttons'
                          onClick={()=> setUnBlockInput(true)}
                          variant="primary"
                          type="submit"
                          style={{marginTop:"15px",color: textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}}
                        >
                       {words[language].unSecure}
                      </Button>
                      </div>
                      )}
                      {blockInput && (
                       <div>
                       <Modal show={blockInput} onHide={() => setBlockInput(false)} centered>
                         
                         <Modal.Body style={{padding:"10%", backgroundColor:cardBackground}}>
                         <div class="main-content text-center">
                           
                           <a href="#" onClick={() => setBlockInput(false)} class="close-btn" data-dismiss="modal" aria-label="Close">
                               <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                             </a>

                           <div class="warp-icon mb-4">
                             <span style={{color:textCardColor}} class="icon-lock2"><FaLock></FaLock></span>
                           </div>
                           <form action="#">
                             <label style={{color:textCardColor}} for="">{words[language].toggleBlock}</label>
                             <p style={{color:textCardColor}}class="mb-4">{words[language].toggleBlockText}</p>
                             

                             <div class="d-flex">
                               <div class="mx-auto">
                               <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={blockVisitor} variant="primary">
                              {words[language].block}
                            </Button>
                               </div>
                             </div>
                           </form>
                         
                       </div>
                         
                         </Modal.Body>
                         
                        
                       </Modal>
                     </div>
                    )}
                    {unBlockInput && (
                     <div>
                     <Modal show={unBlockInput} onHide={() => setUnBlockInput(false)} centered>
                       
                       <Modal.Body style={{padding:"10%", backgroundColor:cardBackground}}>
                       <div class="main-content text-center">
                         
                         <a href="#" onClick={() => setUnBlockInput(false)} class="close-btn" data-dismiss="modal" aria-label="Close">
                             <span class="icon-close2"><FontAwesomeIcon icon={faXmark}/></span>
                           </a>

                         <div class="warp-icon mb-4">
                           <span style={{color:textCardColor}} class="icon-lock2"><FaLockOpen></FaLockOpen></span>
                         </div>
                         <form action="#">
                           <label style={{color:textCardColor}} for="">{words[language].toggleUnblock}</label>
                           <p style={{color:textCardColor}} class="mb-4">{words[language].toggleUnblockText}</p>
                           

                           <div class="d-flex">
                             <div class="mx-auto">
                             <Button style={{color : textButtonContactColor, backgroundColor:buttonContactColor, border: buttonContactColor}} onClick={unBlockVisitor} variant="primary">
                              {words[language].unblock}
                            </Button>
                             </div>
                           </div>
                         </form>
                       
                     </div>
                     
                       
                       </Modal.Body>
                       
                      
                     </Modal>
                   </div>
                    )}
                     
                    
                      
                </div>
                </Collapse>
                </div>
                                


                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default UserDashboard;