import React from 'react';
import { Link } from 'react-router-dom';

const SomethingWentWrong = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light text-center p-3">
      <h1 className="display-4 text-dark mb-3">Coś poszło nie tak</h1>
      <p className="lead text-muted mb-4">Przepraszamy za niedogodności. Spróbuj jeszcze raz</p>
      <Link to="/" className="btn btn-primary btn-lg">Wróć na stronę główną</Link>
    </div>
  );
}

export default SomethingWentWrong;
