import React from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { FaArrowDown,FaArrowUp, FaAngleDown } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TutorialPage.css';
import {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil} from '@fortawesome/free-solid-svg-icons'

const Instruction = () => {

  const [language,setLanguage] = useState('pl')

  useEffect(() => {
    const languageFromLocalStorage = localStorage.getItem("language");
    if (languageFromLocalStorage) {
      setLanguage(languageFromLocalStorage);
    }
  }, []);

  const handleChangeLanguage = (language) => {
    // Tutaj umieść kod obsługujący zmianę języka
    setLanguage(language);
    localStorage.setItem("language",language);
    
    

  };

  const words = {
    pl: {
      header: 'Korzystanie z wizytówki NFC',
      headerText: 'Proste kroki, które pozwolą Ci wykorzystać pełny potencjał twojej wizytówki',
      firstRegisterHeader : 'Pierwsza rejestracja',
      step1Register : 'Wraz z zaprogramowaną kartą otrzymujesz unikalny kod aktywacyjny, który umożliwia Ci przypisanie wizytówki do Twojego identyfikatora. Abyś mógł korzystać z opcji edycji, musisz przejść przez proces pierwszej rejestracji.',
      step2Register : 'Zbliżając kartę do telefonu lub wpisując link: https://tagu.pl/rejestracja, wyświetli Ci się formularz rejestracji, gdzie zostaniesz poproszony o wprowadzenie danych (e-mail oraz hasło), które będą Ci później służyć do zalogowania się do panelu edycji Twojej wizytówki. Oprócz powyższych danych, będziesz musiał wprowadzić również unikalny kod aktywacyjny, który znajduje się na ulotce.',
      step3Register : 'Po zakończeniu rejestracji zostaniesz przeniesiony na stronę logowania, gdzie będziesz mógł wprowadzić dane, które wcześniej ustawiłeś. Od tego momentu możesz cieszyć się z usług panelu edycji oraz dostosować wizytówkę do swoich potrzeb.',
      editPanelHeader : 'Panel edycji',
      step1Edit : 'Po zalogowaniu ukaże się wygląd twojej wizytówki. Pod zdjęciem profilowym znajdują się 3 kwadraty, które umożliwiają zmianę motywu twojej wizytówki.',
      step2Edit : 'Klikając w lewym górnym rogu przycisk "Edytuj wizytówkę", wyświetli Ci się zawartość panelu edycji. W panelu wyróżniono kilka sekcji, w których możesz edytować zawartość Twojej wizytówki. Klikając w sekcję, która Cię zainteresowała, ukaże Ci się możliwość jej edycji.',
      step3Edit : 'W dalszych krokach omówimy poszczególne sekcje.',
      personalHeader : 'Dane personalne',
      step1Personal : 'W sekcji danych personalnych można dokonać zmiany imienia, nazwiska oraz zdjęcia profilowego. Dane te będą znajdować się w profilu Twojej wizytówki.',
      step2Personal : 'Aby dokonać zmiany zdjęcia profilowego, kliknij przycisk "Dodaj" lub "Zmień zdjęcie". Po wybraniu grafiki pojawi się obszar jej kadrowania.',
      step3Personal : 'Przesuwając palcem lub kursorem po ekranie, dopasujesz obszar zdjęcia, które będzie widoczne na wizytówce. Kliknij przycisk "Zapisz" pod obszarem kadrowania, aby zaktualizować zdjęcie.',
      step4Personal : 'Aby zmienić dane personalne, wprowadź wybrane treści do pól tekstowych, a następnie kliknij przycisk "Zapisz", który znajduje się pod polami edycyjnymi. Po wykonaniu powyższych czynności dane personalne zostaną zaktualizowane.',
      linkHeader : 'Linki',
      step1Link : 'W sekcji linków wyświetla się aktualny link do Twojej wizytówki, który możesz skopiować. Istnieje również możliwość zmiany końcówki linku na losowo wygenerowaną lub własną.',
      step2Link : 'Aby dokonać zmiany końcówki linku na wygenerowaną automatycznie, wybierz opcję "generowany". Następnie, po kliknięciu przycisku "generuj", ukaże Ci się losowy ciąg znaków, który możesz ustawić jako link do swojej wizytówki (np. tagu.pl/users/losowyciagznakow). Aby zapisać modyfikację, naciśnij przycisk "Zmień".',
      step3Link : 'Aby dokonać zmiany końcówki linku na własną, wybierz opcję "personalizowany". Wówczas ukaże Ci się pole tekstowe, w które możesz wprowadzić końcówkę linku (np. tagu.pl/users/wlasnakoncowka) - musi ona zawierać przynajmniej 4 znaki. Po dokonaniu zmian, kliknij przycisk "Zmień", aby zapisać modyfikacje.',
      step4Link : 'Masz również możliwość dodania ikon z wielu portali społecznościowych. Odwiedzający, który kliknie interesującą go ikonkę zostanie przeniesiony na wskazany przez Ciebie adres. Aby dodać ikonę, kliknij przycisk "Dodaj ikonkę" i wybierz interesujący Cię portal społecznościowy w rozwinięciu sekcji.',
      step5Link : 'Wybierz ikonę, którą chcesz dodać do swojej wizytówki, klikając na przycisk odpowiadający danemu portalowi społecznościowemu. Wprowadź link do pola tekstowego i kliknij zielono obramowany przycisk, aby zapisać ikonkę na Twojej wizytówce. Jeśli klikniesz na niewłaściwy portal, możesz użyć przycisku z czerwoną obramówką, aby ukryć pole edycji.',
      step6Link : 'Możesz również edytować lub usuwać istniejące ikony. W sekcji "Linki" wyświetlają się wszystkie ikony znajdujące się na Twojej wizytówce. Jeśli chcesz dokonać edycji, kliknij przycisk "Edytuj" i wprowadź nowy link. Aby zatwierdzić zmiany, kliknij zielono obramowany przycisk, a jeśli chcesz anulować, wybierz przycisk z czerwoną obramówką. Aby usunąć dodaną ikonę, kliknij przycisk "Usuń", a zostanie ona usunięta z Twojej wizytówki.',
      contactHeader : 'Kontakt',
      step1Contact : 'Masz również możliwość skonfigurowania danych kontaktowych w swojej wizytówce, co umożliwi innym użytkownikom zapisanie Twojego kontaktu po odwiedzeniu Twojej wizytówki.',
      step2Contact : 'Aby wprowadzić dane kontaktowe, które będą wyświetlane po zapisaniu Twojego kontaktu, kliknij przycisk "Kontakt" i wprowadź odpowiednie informacje.',
      step3Contact : 'Możesz go dodatkowo zabezpieczyć, klikając przycisk "Zabezpiecz kontakt". Wprowadź czterocyfrowy PIN, a następnie wciśnij przycisk "Zapisz PIN". Po zapisaniu PIN-u, każda osoba odwiedzająca Twoją wizytówkę będzie musiała wprowadzić ten czterocyfrowy PIN, aby móc zapisać Twój kontakt.',
      step4Contact : 'W dowolnym momencie możesz zresetować swój PIN, wprowadzić nowy lub całkowicie wyłączyć tę funkcję, klikając ponownie przycisk "Zabezpiecz kontakt".',
      photoHeader : 'Zdjęcia i inne',
      step1Photo : 'W sekcji "Zdjęcia i inne" masz możliwość personalizacji galerii na swojej wizytówce poprzez dodanie trzech zdjęć. Po kliknięciu przycisku "Sekcja zdjęć", pojawią się trzy pola, które umożliwią Ci przesłanie wybranych obrazów. Aby dodać zdjęcia, skorzystaj z przycisku "Dodaj zdjęcie" obok każdego pola i wybierz odpowiedni obraz z galerii urządzenia.',
      step2Photo : 'W dowolnym momencie możesz dokonać zmiany zdjęcia na inne, klikając przycisk "Zmień zdjęcie" i wybierając nowe zdjęcie z galerii, lub całkowicie je usunąć. Dodatkowo, masz możliwość ukrycia galerii przed odwiedzającymi, wystarczy ponownie kliknąć przycisk "Sekcja zdjęć".',
      step3Photo : 'Możesz także umieścić post z Instagrama na swojej wizytówce. Aby to zrobić, kliknij przycisk "Post na Instagramie" i skopiuj link do wybranego postu na Instagramie. Następnie wklej go do pola tekstowego.',
      step4Photo : 'Po wprowadzeniu prawidłowego linku, zapisz post na wizytówce, klikając przycisk "Zapisz". Od tego momentu na Twojej wizytówce będzie wyświetlany fragment tego postu.',
      step5Photo : 'Możesz również umieścić film z YouTube na swojej wizytówce. Aby to zrobić, kliknij przycisk "Dodaj film z YouTube" i skopiuj link do wybranego filmu z YouTube. Następnie wklej go do odpowiedniego pola tekstowego.',
      step6Photo : ' Po wprowadzeniu prawidłowego linku, zapisz film na wizytówce, klikając przycisk "Zapisz". Od tego momentu na Twojej wizytówce będzie wyświetlany film z YouTube',
      step7Photo : 'Możesz również udostępnić opinie z Google na swojej wizytówce, aby zachęcić odwiedzających do napisania opinii o Twojej działalności. Aby to zrobić, kliknij w przycisk "Oceny google" i skopiuj link do opinii Google o swojej działalności (instrukcja jak skopiować link do opinii swojej działalności jest dostępna w internecie)',
      step8Photo : 'Po wprowadzeniu prawidłowego linku, zapisz opinię na wizytówce, klikając przycisk "Zapisz". Od tego momentu na Twojej wizytówce będzie wyświetlany przycisk, który po kliknięciu przez użytkownika Twojej wizytówki zostanie przekierowany do opinii Twojej działalności.',
      settingHeader : 'Ustawienia',
      step1Setting : 'W sekcji "Ustawienia" masz możliwość zablokowania swojej wizytówki przed każdym odwiedzającym. Po zablokowaniu, żaden odwiedzający nie będzie mógł uzyskać dostępu do Twojej wizytówki, nawet poprzez wpisanie linku w wyszukiwarce internetowej. Aby ponownie odblokować swoją wizytówkę, przejdź do sekcji ustawień i kliknij przycisk "Odblokuj wizytówkę".',
      step2Setting : 'Masz także możliwość dostosowania wyglądu swojej wizytówki, wybierając między trybem firmowym a personalnym. Jeśli wybierzesz tryb firmowy, będziesz mógł wprowadzić informacje dotyczące swojej firmy zamiast danych osobowych.',
      step3Setting : 'Przy każdej zmianie widoku, wszystkie wcześniej wprowadzone dane w Twojej wizytówce zostaną usunięte, dlatego konieczne będzie ponowne wprowadzenie tych informacji.',
      step4Setting : ' Masz także możliwość dostosowania języka wizytówki, wybierając pomiędzy angielskim a polskim.',
      dataFirmHeader : 'Dane firmowe',
      step1Firm : 'Jeśli zdecydujesz się ustawić firmowy widok wizytówki, pojawi się sekcja danych firmowych. Tam znajdziesz przyciski umożliwiające edycję poszczególnych informacji.',
      step2Firm : 'Aby wprowadzić zmiany, kliknij na wybrany przycisk, a pojawi się pole edycyjne. Wprowadź odpowiednie dane i potwierdź, klikając zielony przycisk. Od tego momentu informacja zostanie zaktualizowana i będzie widoczna na Twojej wizytówce.',
      useHeader : 'Korzystanie z wizytówki',
      step1Use : 'Jeśli postępowałeś zgodnie z instrukcją obsługi i dostosowałeś zawartość oraz wygląd wizytówki do swoich osobistych preferencji, teraz możesz cieszyć się w pełni skonfigurowaną wizytówką, która spełnia wszystkie Twoje oczekiwania.',
      step2Use : 'Aby udostępnić swoją wizytówkę innym osobom, wystarczy przyłożyć swoją kartę do telefonu, który posiada czytnik NFC. Po przyłożeniu karty, strona z Twoją wizytówką automatycznie załaduje się na tym telefonie.',
      step3Use : 'Na odwrotnej stronie karty znajduje się także kod QR. Jeśli druga osoba nie ma telefonu z czytnikiem NFC, może zeskanować Twoją kartę za pomocą kodu QR. Wystarczy, że skieruje aparat telefonu na kod QR, a zostanie automatycznie przeniesiona do Twojej wizytówki.'
	
 


  											
      
    },
    en: {
      header: 'Using NFC Business Card',
      headerText: 'Simple steps to unlock the full potential of your business card',
      firstRegisterHeader: 'First Registration',
      step1Register: 'Along with the programmed card, you will receive a unique activation code that allows you to assign the business card to your identity. To access the editing options, you need to go through the first registration process.',
      step2Register: 'By bringing the card close to your phone or visiting the link: https://tagu.pl/registration, you will be directed to the registration form, where you will be asked to enter your details (email and password) that will be used for logging into the editing panel of your business card. In addition to the above information, you will also need to enter the unique activation code found on the leaflet.',
      step3Register: 'After completing the registration, you will be redirected to the login page, where you can enter the details you set earlier. From this point on, you can enjoy the editing panel services and customize your business card according to your needs.',
      editPanelHeader: 'Editing Panel',
      step1Edit: 'After logging in, you will see the appearance of your business card. Below the profile picture, there are three squares that allow you to change the theme of your business card.',
      step2Edit: 'By clicking on the "Edit Business Card" button in the top left corner, you will see the content of the editing panel. The panel is divided into several sections where you can edit the content of your business card. By clicking on the section that interests you, you will be presented with the option to edit it.',
      step3Edit: 'In the following steps, we will discuss each section individually.',
      personalHeader: 'Personal Information',
      step1Personal: 'In the personal information section, you can make changes to your first name, last name, and profile picture. This data will be displayed on your business card.',
      step2Personal: 'To change the profile picture, click on the "Add" or "Change Picture" button. After selecting an image, a cropping area will appear.',
      step3Personal: 'By dragging your finger or cursor across the screen, you can adjust the area of the picture that will be visible on the business card. Click the "Save" button below the cropping area to save the changes.',
      step4Personal: 'To change personal information, enter the desired content into the text fields, and then click the "Save" button located below the editing fields. Once you have completed these steps, the personal information will be updated.',
      linkHeader: 'Links',
      step1Link: 'In the links section, the current link to your business card is displayed, which you can copy. There is also an option to change the link ending to a randomly generated one or a custom one.',
      step2Link: 'To change the link ending to an automatically generated one, select the "generated" option. Then, by clicking the "generate" button, you will be presented with a random string of characters that you can set as the link to your business card (e.g., tagu.pl/users/randomstring). To save the modification, press the "Change" button.',
      step3Link: 'To change the link ending to a custom one, select the "custom" option. You will then see a text field where you can enter the link ending (e.g., tagu.pl/users/customending) - it must contain at least 4 characters. After making the changes, click the "Change" button to save the modifications.',
      step4Link: 'You also have the option to add icons from various social platforms. Visitors who click on the icon of their interest will be redirected to the address you specified. To add an icon, click the "Add Icon" button and select the social platform you are interested in from the section dropdown.',
      step5Link: 'Select the icon you want to add to your business card by clicking on the button corresponding to the respective social platform. Enter the link into the text field and click the green-bordered button to save the icon on your business card. If you click on the wrong platform, you can use the red-bordered button to hide the editing field.',
      step6Link: 'You can also edit or remove existing icons. In the "Links" section, all icons on your business card are displayed. If you want to edit, click the "Edit" button and enter a new link. To confirm the changes, click the green-bordered button, and if you want to cancel, select the red-bordered button. To remove an added icon, click the "Remove" button, and it will be deleted from your business card.',
      contactHeader: 'Contact',
      step1Contact: 'You also have the option to configure contact information on your business card, which allows other users to save your contact information after visiting your business card.',
      step2Contact: 'To enter contact information that will be displayed when someone saves your contact, click the "Contact" button and enter the appropriate information.',
      step3Contact: 'You can further secure it by clicking the "Secure Contact" button. Enter a four-digit PIN, then press the "Save PIN" button. After saving the PIN, anyone visiting your business card will need to enter this four-digit PIN to save your contact.',
      step4Contact: 'At any time, you can reset your PIN, enter a new one, or completely disable this feature by clicking the "Secure Contact" button again.',
      photoHeader: 'Photos and Others',
      step1Photo: 'In the "Photos and Others" section, you have the ability to personalize the gallery on your business card by adding three photos. Clicking the "Photo Section" button will display three fields that allow you to upload your chosen images. To add photos, use the "Add Photo" button next to each field and select the appropriate image from your device gallery.',
      step2Photo: 'At any time, you can change a photo to a different one by clicking the "Change Photo" button and selecting a new image from the gallery, or completely remove it. Additionally, you have the option to hide the gallery from visitors by clicking the "Photo Section" button again.',
      step3Photo: 'You can also include an Instagram post on your business card. To do this, click the "Instagram Post" button and copy the link to the chosen Instagram post. Then, paste it into the text field.',
      step4Photo: 'After entering the correct link, save the post on your business card by clicking the "Save" button. From that moment on, a snippet of that post will be displayed on your business card.',
      step5Photo: 'You can also feature a YouTube video on your business card. To do this, click the "Add YouTube Video" button and copy the link to the chosen YouTube video. Then, paste it into the appropriate text field.',
      step6Photo: 'After entering the correct link, save the video on your business card by clicking the "Save" button. From that moment on, the YouTube video will be displayed on your business card.',
      step7Photo : "You can also share Google reviews on your business card to encourage visitors to write reviews about your business. To do this, click on the 'Google Reviews' button and copy the link to your Google reviews about your business (instructions on how to copy the link to your business reviews are available online).",
      step8Photo : "After entering the correct link, save the review on your business card by clicking the 'Save' button. From this point on, a button will be displayed on your business card that, when clicked by a user viewing your business card, will redirect them to reviews of your business.",
      settingHeader: 'Settings',
      step1Setting: 'In the "Settings" section, you have the option to lock your business card from any visitor. Once locked, no visitor will be able to access your business card, even by entering the link in a web search engine. To unlock your business card again, go to the settings section and click the "Unlock Business Card" button.',
      step2Setting: 'You also have the ability to customize the appearance of your business card by choosing between the company mode and the personal mode. If you choose the company mode, you will be able to enter information about your company instead of personal data.',
      step3Setting: 'With each change of view, all previously entered data on your business card will be deleted, so it will be necessary to re-enter this information.',
      step4Setting: 'You also have the option to customize the language of your business card by choosing between English and Polish.',
      dataFirmHeader: 'Company Data',
      step1Firm: 'If you choose to set the company view on your business card, a section of company data will appear. There, you will find buttons that allow you to edit individual pieces of information.',
      step2Firm: 'To make changes, click on the selected button, and an editing field will appear. Enter the appropriate data and confirm by clicking the green button. From that moment on, the information will be updated and visible on your business card.',
      useHeader: 'Using the Business Card',
      step1Use: 'If you have followed the user manual and customized the content and appearance of your business card according to your personal preferences, you can now enjoy a fully configured business card that meets all your expectations.',
      step2Use: 'To share your business card with others, simply place your card on a phone equipped with an NFC reader. After placing the card, the page with your business card will automatically load on that phone.',
      step3Use: "On the back of the card, there is also a QR code. If the other person does not have a phone with an NFC reader, they can scan your card using the QR code. They just need to point the phone's camera at the QR code, and they will be automatically redirected to your business card.",
    }
  };

    const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded1,setIsExpanded1] = useState(false);
    const [isExpanded2,setIsExpanded2] = useState(false);
    const [isExpanded3,setIsExpanded3] = useState(false);
    const [isExpanded4,setIsExpanded4] = useState(false);
    const [isExpanded5,setIsExpanded5] = useState(false);
    const [isExpanded6,setIsExpanded6] = useState(false);
    const [isExpanded7,setIsExpanded7] = useState(false);
    const [isExpanded8,setIsExpanded8] = useState(false);
    const handleArrowClick = () => {
      setIsExpanded(!isExpanded);
      setIsExpanded1(false);
      setIsExpanded2(false);
      setIsExpanded3(false);
      setIsExpanded4(false);
      setIsExpanded5(false);
      setIsExpanded6(false);
      setIsExpanded7(false);
      setIsExpanded8(false);
    };
    const handleArrowClick1 = () => {
        setIsExpanded(false);
        setIsExpanded1(!isExpanded1)
        setIsExpanded2(false)
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded8(false);
        
    }
    const handleArrowClick2 = () => {
        setIsExpanded2(!isExpanded2)
        
        setIsExpanded(false)
        setIsExpanded1(false)
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded8(false);

    }
    const handleArrowClick3 = () => {
        setIsExpanded3(!isExpanded3)
        setIsExpanded(false)
        setIsExpanded2(false);
        setIsExpanded1(false)
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded8(false);
    }
    const handleArrowClick4 = () => {
        setIsExpanded(false)
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded1(false)
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded4(!isExpanded4)
        setIsExpanded8(false);
    }
    const handleArrowClick5 = () => {
        setIsExpanded5(!isExpanded5)
        setIsExpanded(false)
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded1(false)
        setIsExpanded6(false);
        setIsExpanded7(false);
        setIsExpanded8(false);
    }
    const handleArrowClick6 = () => {
        setIsExpanded6(!isExpanded6)
        setIsExpanded(false)
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded8(false);
        setIsExpanded7(false);
        setIsExpanded1(false)
    }
    const handleArrowClick7 = () => {
        setIsExpanded7(!isExpanded7)
        setIsExpanded(false)
        setIsExpanded2(false);
        setIsExpanded3(false);
        setIsExpanded4(false);
        setIsExpanded5(false);
        setIsExpanded6(false);
        setIsExpanded8(false);
        setIsExpanded1(false)
        
    }
    const handleArrowClick8 = () => {
      setIsExpanded8(!isExpanded8)
      setIsExpanded(false);
      setIsExpanded2(false);
      setIsExpanded3(false);
      setIsExpanded4(false);
      setIsExpanded5(false);
      setIsExpanded6(false);
      setIsExpanded7(false);
      setIsExpanded1(false)
      
  }

  return (
    <div translate="no">
      <Container>
        <Row className="justify-content-center">
        
          <Col md={8} lg={6}>
            <div className="text-center">
            
              <h1>{words[language].header}</h1>
              <p className="lead">
                 {words[language].headerText}
              </p>
            </div>
            <div className="text-center" style={{ marginLeft:"10px",marginBottom:"10px" }}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpolandflag.png?alt=media&token=8174f61e-4c20-4e83-a7ec-9f14c4e2c552"
                alt="Polish"
                style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
                onClick={() => handleChangeLanguage('pl')}
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fenglandflag.png?alt=media&token=3149d47d-3619-4e59-8dad-7b61ce48bbf0"
                alt="English"
                style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
                onClick={() => handleChangeLanguage('en')}
              />
        </div>
            <div>
            <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].firstRegisterHeader}</h3>

        {isExpanded && (
          <div className={`step-content ${isExpanded ? 'expanded' : ''}`}>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded && (
                  <p className="step-description">
                    {words[language].step1Register}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Register}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Register}
                </p>
              </Col>
            </Row>
          </div>
        )}

        <div className="arrow" onClick={handleArrowClick}>
          {isExpanded ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
            

              {/* Pozostałe karty */}

              {/* Krok 2 */}
              <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].editPanelHeader}</h3>

        {isExpanded1 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded1 && (
                  <p className="step-description">
                    {words[language].step1Edit}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Edit}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Edit}
                </p>
              </Col>
            </Row>
          </>
        )}

        <div className="arrow" onClick={handleArrowClick1}>
          {isExpanded1 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>

    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].personalHeader}</h3>

        {isExpanded2 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded2 && (
                  <p className="step-description">
                    {words[language].step1Personal}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Personal}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Personal}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                4
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step4Personal}
                </p>
              </Col>
            </Row>
          </>
        )}

        <div className="arrow" onClick={handleArrowClick2}>
          {isExpanded2 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
    
    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].linkHeader}</h3>

        {isExpanded3 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded3 && (
                  <p className="step-description">
                     {words[language].step1Link}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Link}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
              {words[language].step3Link}
                <p className="step-description">
                 
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
              {words[language].step4Link}
                <p className="step-description">
                 
                </p>
              </Col>
            </Row>
             
             <Row className="step-row">
              <Col xs={1} className="step-number">
                4
              </Col>
              <Col xs={11} className="step-content">
              {words[language].step5Link}
                <p className="step-description">
                 
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                5
              </Col>
              <Col xs={11} className="step-content">
              {words[language].step6Link}
                <p className="step-description">
                 
                </p>
              </Col>
            </Row>
            
            
            
          </>
        )}

        <div className="arrow" onClick={handleArrowClick3}>
          {isExpanded3 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>

             

              
              <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].contactHeader}</h3>

        {isExpanded4 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded4 && (
                  <p className="step-description">
                   {words[language].step1Contact}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Contact}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Contact}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                4
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step4Contact}
                </p>
              </Col>
            </Row>
          </>
        )}

        <div className="arrow" onClick={handleArrowClick4}>
          {isExpanded4 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].photoHeader}</h3>

        {isExpanded5 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded5 && (
                  <p className="step-description">
                    {words[language].step1Photo}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                4
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step4Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                5
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step5Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                6
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step6Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                7
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step7Photo}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                8
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step8Photo}
                </p>
              </Col>
            </Row>

          </>
        )}

        <div className="arrow" onClick={handleArrowClick5}>
          {isExpanded5 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].settingHeader}</h3>

        {isExpanded6 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded6 && (
                  <p className="step-description">
                   {words[language].step1Setting}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Setting}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step3Setting}
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                4
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step4Setting}
                </p>
              </Col>
            </Row>
            
          </>
        )}

        <div className="arrow" onClick={handleArrowClick6}>
          {isExpanded6 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].dataFirmHeader}</h3>

        {isExpanded7 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded7 && (
                  <p className="step-description">
                   {words[language].step1Firm} 
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Firm} 
                </p>
              </Col>
            </Row>
            
          </>
        )}

        <div className="arrow" onClick={handleArrowClick7}>
          {isExpanded7 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
    <Card className="step-card">
      <Card.Body>
        <h3 className="step-title">{words[language].useHeader}</h3>

        {isExpanded8 && (
          <>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                1
              </Col>
              <Col xs={11} className="step-content">
                {isExpanded8 && (
                  <p className="step-description">
                   {words[language].step1Use} 
                  </p>
                )}
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                2
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                {words[language].step2Use} 
                </p>
              </Col>
            </Row>
            <Row className="step-row">
              <Col xs={1} className="step-number">
                3
              </Col>
              <Col xs={11} className="step-content">
                
                <p className="step-description">
                  {words[language].step3Use} 
                </p>
              </Col>
            </Row>
            
            
          </>
        )}

        <div className="arrow" onClick={handleArrowClick8}>
          {isExpanded8 ? (
            <FaArrowUp className="arrow-icon" />
          ) : (
            <FaArrowDown className="arrow-icon" />
          )}
        </div>
      </Card.Body>
    </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Instruction;
