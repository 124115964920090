import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
const ChangePass = () => {
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [resetKey, setKey] = useState("");
  const navigate = useNavigate();
  const [language, setLanguage] = useState('pl')
  const [status, setStatus] = useState(null);
  const notify = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }

  const words = {
    pl: {
      reset: 'Zresetuj hasło',
      newPass: 'Nowe hasło',
      repeatPass: 'Powtórz hasło',
      mailKey: 'Klucz z wiadomości email',
      differentPass : "Hasła nie są takie same",
      updatePass : "Hasło zostało zresetowane",
      wrongKey : "Niepoprawny klucz",
      placeholderPass : "Wpisz nowe hasło",
      placeholderRepeat : "Powtórz nowe hasło",
      placeholderKey : "Wprowadź klucz odzyskiwania",

      
    },
    en: {
      
      reset: 'Reset password',
      newPass: 'New password',
      repeatPass: 'Repeat password',
      mailKey: 'Recovery key from email',
      differentPass: "Passwords do not match",
      updatePass: "Password has been reset",
      wrongKey: "Invalid key",
      placeholderPass : "Enter new pass",
      placeholderRepeat : "Repeat new pass",
      placeholderKey : "Enter recovery key",
    }
  };
  

 

  useEffect(() => {
    if(localStorage.getItem('language')) {
      setLanguage(localStorage.getItem('language'))
    }
    
    
    
    const currentUrl = window.location.href;
    const resetToken = currentUrl.split("/zmianahasla/")[1];
    localStorage.setItem("url", resetToken);

    const verifyResetToken = async (token) => {
      try {
        const response = await axios.post('https://api.tagu.pl/verifyResetToken', {}, {
          headers: {
            Authorization: `Bearer ${resetToken}`,
          },
          timeout: 1000, // Timeout ustawiony na 1 sekundę
        });
        setStatus(response.data.status);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/zaloguj");
          window.location.reload(); // Odśwież stronę
        } else {
          console.error(error);
        }
      }
    };

    if (!resetToken) {
      // Przekieruj użytkownika do strony logowania
      navigate("/zaloguj");
      window.location.reload(); // Odśwież stronę
    } else {
      // Zapisz resetToken w localStorage
      
      verifyResetToken(resetToken);
    }
  }, []);

  

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Sprawdź, czy hasło i powtórzenie są takie same
    if (password !== repeat) {
      notify(words[language].differentPass);
      return;
    }

    // Pobierz resetToken z localStorage
    const resetToken = localStorage.getItem("url");

    // Wywołaj endpoint updatePassword z resetToken w nagłówkach
    axios
      .patch(
        "https://api.tagu.pl/updatePassword",
        { password, repeat, resetKey },
        {
          headers: {
            Authorization: `Bearer ${resetToken}`,
          },
        }
      )
      .then((response) => {
        
        // Usuń resetToken z localStorage
        localStorage.removeItem("resetToken");
        localStorage.setItem("message", words[language].updatePass);
        // Przekieruj użytkownika do strony logowania
        navigate("/zaloguj");
      })
      .catch((error) => {
        if(error.response.status === 400) {
          notify(words[language].wrongKey);
        }
        console.error("Error updating password:", error);
      });
  };
  if (status === null) {
    return 
     
        
        <div className='spinner-center-loading'>
          <FontAwesomeIcon icon={faSpinner} spin size="5x"/>
        </div>
    
  }
  if (status) {
    return <div translate="no">
    <div  className="Auth-form-container" style={{ backgroundColor: '#F5F5F5' }}>
      <form className="Auth-form" style={{ backgroundColor: '#EBEBEB' }} onSubmit={handleFormSubmit}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">{words[language].reset}</h3>
        <div className="form-group mt-3">
          <label>{words[language].newPass}: </label>
          <input
            name="password"
            type="password"
            className="form-control mt-1"
            placeholder={words[language].placeholderPass}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div className="form-group mt-3">
          <label>{words[language].repeatPass}:</label>
          <input
            name="repeat"
            type="password"
            
            className="form-control mt-1"
            placeholder={words[language].placeholderRepeat}
            value={repeat}
            onChange={(e) => setRepeat(e.target.value)}
          />
        </div>
        <div className="form-group mt-3">
          <label>{words[language].mailKey}</label>
          <input
            name="key"
            type="text"
            maxLength="15"
            className="form-control mt-1"
            placeholder={words[language].placeholderKey}
            value={resetKey}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        <div className="d-grid gap-2 mt-3">
          <button
            type="submit"
            className="btn btn-primary"
            style={{ backgroundColor: "#001014", borderColor: "#001014" }}
          >
            {words[language].reset}
          </button>
        </div>
        </div>
      </form>
    </div>
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
limit = "2"
pauseOnHover
theme="dark"
/>
  </div>
  }

  return (
    <div>
      
    </div>
    
  );
};

export default ChangePass;
