import "./Login.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
const PasswordRecovery = () => {
    const [email,setEmail] = useState('');
    

    const [language, setLanguage] = useState("pl");
    useEffect(() => {
      const languageFromLocalStorage = localStorage.getItem("language") || 'pl';
      if (languageFromLocalStorage) {
        setLanguage(languageFromLocalStorage);
      }
    }, []);

    const words = {
      pl: {
        noExist: "Taki użytkownik nie istnieje!",
        serverError: "Wystąpił błąd serwera",
        passRecovery: "Odzyskiwanie hasła",
        mailPlaceholder: "Wpisz adres e-mail",
        recoveryButton: "Odzyskaj hasło",
        recoveryInfo: "Na maila przyjdzie link z resetowaniem hasła"
      },
      en: {
        noExist: "Such user does not exist!",
        serverError: "Server error occurred",
        passRecovery: "Password recovery",
        mailPlaceholder: "Enter your email address",
        recoveryButton: "Recover password",
        recoveryInfo: "A password reset link will be sent to your email"
      }
    };

    
    const recoveryHandler = (e) => {
        setEmail(e.target.value);
    }
    const notify2 = () => {toast.info(words[language].recoveryInfo, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }
    const notify3 = (message) => {toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }


  const clickRecoveryHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.tagu.pl/reset-password', {
        email: email, // Zmień na odpowiedni adres e-mail
      });
      notify2();
       // Obsłuż odpowiedź serwera (np. wyświetl komunikat sukcesu)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        notify3(words[language].noExist);
      } else {
        notify3(words[language].serverError);
      }
    }
  };
  

    return (
        <div translate="no">
      <div className="Auth-form-container">
        <form className="Auth-form">
         <div className="Auth-form-content">
          <h3 className="Auth-form-title">{words[language].passRecovery}</h3>
          <div className="form-group mt-3">
            <label>E-mail</label>
            <input
              name="email"
              type="email"
              maxLength="60"
              className="form-control mt-1"
              placeholder={words[language].mailPlaceholder}
              onChange={recoveryHandler}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button onClick={clickRecoveryHandler} type="submit" className="btn btn-primary"  style={{ backgroundColor: '#001014', borderColor: '#001014' }}>
              {words[language].recoveryButton}
            </button>
        </div>
        </div>
        </form>
        </div>
        <ToastContainer
                              position="top-center"
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                              theme="dark"
                            />
        </div>

    )
}
export default PasswordRecovery;