import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import axios from "axios";
import "./Login.css";
import { useNavigate, Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {

    const [language,setLanguage] = useState('pl');

    const [user, setUser] = useState({
        email : "",
        password :"",
        repeat : "",
        language : 'pl',
        registerKey : "",
      })


      useEffect(() => {
        const savedLanguage = localStorage.getItem("language");
        if (savedLanguage) {
          setLanguage(savedLanguage);
        }
      }, []);

      const words = {
        pl: {
    enterCorrect : "Wprowadź poprawny adres e-mail!",
    registerError : "Wystąpił błąd podczas rejestracji!",
    passwordsIncorrect : "Podane hasła się nie pokrywają",
    activeBusiness : "Aktywuj swoją wizytówkę",
    mailPlaceholder : "Wpisz adres e-mail",
    pass : "Hasło",
    enterpass : "Wpisz hasło",
    repeatpass : "Powtórz hasło",
    againPass : "Wpisz hasło jeszcze raz",
    languageOption : "Preferowany język",
    activateKey : "Kod aktywacyjny",
    activatePlaceholder : "Wpisz kod aktywacyjny",
    register : "ZAREJESTRUJ",
    account : "Masz już konto?",
    login : "Zaloguj się",
    existingUser: "Taki użytkownik już istnieje w bazie danych!",
    wrongKey: "Niepoprawny klucz rejestracji",
    registered: "Zarejestrowano użytkownika",
    repeatPass : "Powtórz hasło",
    accept : "Akceptuję",
    terms : "politykę prywatności",
    noAgreed : "Musisz zaakceptować politykę prywatności, aby się zarejestrować."
    

    
    
        },
        en: {
        enterCorrect: "Enter a valid email address!",
      registerError: "An error occurred during registration!",
      passwordsIncorrect: "The passwords provided do not match",
      activeBusiness: "Activate your business card",
      mailPlaceholder: "Enter email address",
      pass: "Password",
      enterpass: "Enter password",
      repeatpass: "Repeat password",
      againPass: "Enter password again",
      languageOption: "Preferred language",
      activateKey: "Activation code",
      activatePlaceholder: "Enter activation code",
      register: "REGISTER",
      account: "Already have an account?",
      login: "Log in",
      existingUser: "Such user already exists in the database!",
      wrongKey: "Invalid registration key",
      registered: "User registered",
      repeatPass : "Repeat password",
      accept : "I accept",
      terms : "privacy policy",
      noAgreed : "You have to accept the privacy policy to register."
          
        }
      };

      const handleChangeLanguage = (language) => {
        // Tutaj umieść kod obsługujący zmianę języka
        setLanguage(language);
        localStorage.setItem("language",language);
        
        
    
      };
      const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);

      const handleCheckboxChange = (e) => {
        setIsPrivacyAgreed(e.target.checked)
        
        
      }
      const notify = (errorMessage) => {
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      const notify2 = (errorMessage) => {
        toast.success(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }


    const handleChange  =  (event) => {
        
        const {name,value} = event.target
        setUser({
          ...user,
          [name]:value
        })

        
    
      }

      const handleLanguageChange = (e) => {
        const { value } = e.target;
        setUser((prevState) => ({
          ...prevState,
          language: value
        }));
      };


      const navigate = useNavigate();
      const register = (event) => {
        event.preventDefault();
        const { email, password, repeat } = user;
      
        // Sprawdzenie, czy email zawiera @
        if (!email || !email.includes("@")) {
          notify(words[language].enterCorrect);
          return;
        }

        if(!isPrivacyAgreed) {
          notify(words[language].noAgreed)
          return;
        }
        const lowercaseUser = {
          ...user,
          email: email.toLowerCase()
        };
      
        if (password && repeat && password === repeat) {
          axios
            .post("https://api.tagu.pl/api/register", lowercaseUser)
            .then((res) => {
              if (res.data.status === true) {
                navigate("/zaloguj");
                localStorage.setItem("messageRegister", words[language].registered);
              } else {
                if(res.data.message==='Użytkownik już istnieje w bazie danych!') {
                  notify(words[language].existingUser)
                }
                else if(res.data.message==='Niepoprawny klucz rejestracji!') {
                  notify(words[language].wrongKey)
                }

              }
            })
            .catch((error) => {
              
              notify(words[language].registerError);
            });
        } else {
          notify(words[language].passwordsIncorrect);
        }
      };
      
      
    return (
        <div translate="no">
      <div>
        
  <div className="Auth-form-container" style={{ backgroundColor: '#F5F5F5' }}>
  
    <form className="Auth-form" style={{ backgroundColor: '#EBEBEB' }}>
    <div style={{ marginLeft:"10px",marginBottom:"10px" }}>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpolandflag.png?alt=media&token=8174f61e-4c20-4e83-a7ec-9f14c4e2c552"
      alt="Polish"
      style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
      onClick={() => handleChangeLanguage('pl')}
    />
    <img
      src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fenglandflag.png?alt=media&token=3149d47d-3619-4e59-8dad-7b61ce48bbf0"
      alt="English"
      style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
      onClick={() => handleChangeLanguage('en')}
    />
  </div>
    <div style={{ marginLeft:"10px",marginBottom:"10px" }}>
    
  </div>
      <div className="Auth-form-content">
     
        <h3 className="Auth-form-title">{words[language].activeBusiness}</h3>
        <div className="form-group mt-3">
          <label>E-mail</label>
          <input
            name="email"
            type="email"
            className="form-control mt-1"
            placeholder={words[language].mailPlaceholder}
            value={user.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-3">
          <label>{words[language].pass}</label>
          <input
            name="password"
            type="password"
            className="form-control mt-1"
            placeholder={words[language].enterpass}
            value={user.password}
            onChange={handleChange}
          />
        </div>

        <div className="form-group mt-3">
          <label>{words[language].repeatPass}</label>
          <input
            name="repeat"
            type="password"
            className="form-control mt-1"
            placeholder={words[language].againPass}
            value={user.repeat}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-3">
          <label>{words[language].languageOption}</label>
          <select
            name="language"
            className="form-control mt-1"
            value={user.language}
            onChange={handleLanguageChange}
          >
            <option value="pl">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpolandflag.png?alt=media&token=8174f61e-4c20-4e83-a7ec-9f14c4e2c552"
                alt="Polish"
                style={{ width: '30px', height: 'auto', marginRight: '5px' }}
              />
              Polski
            </option>
            <option value="en">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fenglandflag.png?alt=media&token=3149d47d-3619-4e59-8dad-7b61ce48bbf0"
                alt="English"
                style={{ width: '30px', height: 'auto', marginRight: '5px' }}
              />
              English
            </option>
          </select>
        </div>
        <div className="form-group mt-3">
          <label>{words[language].activateKey}</label>
          <input
            name="registerKey"
            type="text"
            className="form-control mt-1"
            placeholder={words[language].activatePlaceholder}
            value={user.registerKey}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mt-3">
        <Form.Check
          type="checkbox"
          label={
            <span>
              {words[language].accept}{' '}
              <a href="/polityka-prywatnosci" target="_blank" rel="noopener noreferrer">
                {words[language].terms}
              </a>
            </span>
          }
          onChange={handleCheckboxChange}
        />
      </div>
        <div className="d-grid gap-2 mt-3">
          <button
            onClick={register}
            type="submit"
            className="btn btn-primary"
            style={{ backgroundColor: '#001014', borderColor: '#001014' }}
          >
            {words[language].register}
          </button>
          <p className="forgot-password mt-3">
            {words[language].account} <Link to="/zaloguj">{words[language].login}</Link>
          </p>
        </div>
        
      </div>
    </form>
  </div>
  
</div>
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
limit = "4"
pauseOnHover
theme="dark"
/>
        </div>
    )
}

export default Register;