import VisitorView from './VisitorView';
import ErrorPage from "./ErrorPage";
function HomePage (exist) {
    const profile = {
        profilePicture: 'https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2FTagU%20(150%20×%20150px).png?alt=media&token=deb24444-ccca-4c28-a1a7-b6d66c6ece26&_gl=1*1ky0i53*_ga*MTQzNjE4NzkzNS4xNjgzNDY4OTM4*_ga_CW55HF8NVT*MTY4NTU2NjQ5Ny4xNi4xLjE2ODU1NjY4MDYuMC4wLjA.',
        firstName: 'TagU',
        lastName: '',
        description: 'Zamów swoją wizytówkę na tagu.pl',
        email : '@example.com',
        icons : {
            instagram : 'https://www.instagram.com/tagu.card/',
            own : "sklep.tagu.pl"
        },
        
        exist : true,
        keylink : 'TagU',
        theme : 'red',
        visitorType : 'userView',
        


      };
    if(!exist) {
        
        return <ErrorPage></ErrorPage>
    }
    else {
       
    return (
        <div>
            <VisitorView isHome={true}></VisitorView>
            
        </div>
    )
    }
}

export default HomePage ;