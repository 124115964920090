import React from 'react';
import { Link } from 'react-router-dom';

const Expired = () => {
  const words = {
    pageTitle: {
      pl: 'Twoja wizytówka wygasła',
      en: 'Your business card expired',
    },
    pageContent: {
      pl: 'Twój abonament na korzystanie z wizytówki się skończył',
      en: 'Your subscription for using business card expired',
    },
    orderCardLink: {
      pl: 'Odnów subskrypcję',
      en: 'Renew subscription',
    },
  };

  // Sprawdzenie języka zapisanego w localStorage lub innym mechanizmie
  const language = localStorage.getItem('language') || 'pl';

  return (
    <div translate="no">
      <div className="vertical-center">
        <div className="container">
          <div id="notfound" className="text-center">
            <h1>😮</h1>
            <h2>{words.pageTitle[language]}</h2>
            <p>{words.pageContent[language]}</p>
            <Link to="/">{words.orderCardLink[language]}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expired;
