import React from 'react';

const Terms = () => {
  const headingStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  };

  const paragraphStyle = {
    fontSize: '16px',
    marginBottom: '8px',
  };

  const listItemStyle = {
    fontSize: '16px',
    marginBottom: '4px',
  };
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f8f8f8',
    borderRadius: '5px',
  };

  const responsiveContainerStyle = {
    ...containerStyle,
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      padding: '10px',
    },
  };

  const footerStyle = {
    marginTop: '20px',
    fontSize: '12px',
    textAlign: 'center',
  };

  return (
    <div style={responsiveContainerStyle}>
      <h1 style={headingStyle}>POLITYKA PRYWATNOŚCI</h1>

      <p style={paragraphStyle}>
        Niniejsza polityka prywatności ma na celu dokładne określenie zasad gromadzenia, przetwarzania i ochrony danych osobowych naszych klientów przez naszą stronę internetową. Dbamy o twoją prywatność i podejmujemy wszelkie możliwe środki, aby zapewnić bezpieczeństwo Twoich danych osobowych. Zachęcamy Cię do dokładnego zapoznania się z poniższymi informacjami.
      </p> <br></br>

      <h2 style={headingStyle}>Administrator danych osobowych</h2>

      <p style={paragraphStyle}>
        Administratorem Twoich danych osobowych jest PHUG MARIO Mariusz Paściak, z siedzibą pod adresem Pocztowa 13, 43-600 Jaworzno, zarejestrowanym jako indywidualna działalność gospodarcza, posiadającym numer NIP 6320009000 oraz numer REGON 271034948. Możesz skontaktować się z nami w sprawach związanych z ochroną danych osobowych za pośrednictwem danych kontaktowych podanych na stronie naszego sklepu internetowego znajdującego się pod adresem <a href="https://sklep.tagu.pl" target="_blank">https://sklep.tagu.pl</a>  

      </p> <br></br>

      <h2 style={headingStyle}>Cele przetwarzania danych osobowych</h2>

      <p style={paragraphStyle}>Gromadzimy i przetwarzamy Twoje dane osobowe na naszej stronie internetowej z wizytówkami online w celu:</p>

      <ul>
        <li style={listItemStyle}>
          A. <strong> Rejestracji konta użytkowników: </strong> gromadzimy dane osobowe potrzebne do utworzenia konta użytkownika na naszej stronie, weryfikacji tożsamości i umożliwienia dostępu do funkcji i usług (art. 6 ust.1 lit. b RODO).
        </li>
        <li style={listItemStyle}>
          B. <strong> Wyświetlania wizytówek online: </strong> przetwarzamy dane osobowe, takie jak informacje kontaktowe i inne dane udostępnione przez użytkownika, w celu wyświetlania wizytówek online na naszej stronie (art. 6 ust.1 lit. b RODO).
        </li>
        <li style={listItemStyle}>
          C. <strong> Zapewnienia funkcjonalności strony internetowej: </strong> korzystamy z danych osobowych w celu zapewnienia funkcjonalności strony internetowej, takich jak wyszukiwanie wizytówek, sortowanie wyników i udostępnianie informacji o użytkownikach (art. 6 ust.1 lit. b RODO).
        </li>
        <li style={listItemStyle}>
          D. <strong> Kontaktu i komunikacji z użytkownikami: </strong> wykorzystujemy dane osobowe do udzielania odpowiedzi na zapytania użytkowników, obsługi reklamacji, udzielania wsparcia technicznego i rozwiązywania problemów związanych z korzystaniem ze strony internetowej (art. 6 ust.1 lit .b RODO). 

        </li>
        <li style={listItemStyle}>
          E. <strong> Personalizacji doświadczenia użytkownika: </strong> używamy danych demograficznych i preferencji użytkowników w celu dostosowania wyświetlanych wizytówek i rekomendacji do zainteresowań użytkowników (art. 6 ust.1 lit .b RODO) . 


        </li>
        <li style={listItemStyle}>
          F. <strong> Marketingu i promocji: </strong> w oparciu o zgodę użytkownika, możemy przesyłać informacje o nowych funkcjach, promocjach i ofertach specjalnych dotyczących naszej strony internetowej (art. 6 ust.1 lit .a RODO). 

        </li>
        <li style={listItemStyle}>
          G. <strong> Przestrzegania obowiązującego prawa: </strong> przetwarzamy dane osobowe w celu spełnienia naszych zobowiązań wynikających z obowiązującego prawa, takich jak prawa dotyczące ochrony danych i prawa konsumenckie (art. 6 ust.1 lit .c RODO). 


        </li>

        </ul> <br></br>
        <h2 style={headingStyle}>Podstawa prawna przetwarzania danych osobowych</h2>
        <p style={paragraphStyle}>Przetwarzamy twoje dane osobowe na podstawie jednej lub kilku następujących podstaw prawnych: 
        </p>
        <ul>
        <li style={listItemStyle}>
          A. <strong> Wykonanie umowy: </strong> Przetwarzanie danych osobowych jest niezbędne do wykonania umowy o świadczenie usług drogą elektroniczną, w tym udostępnianie wizytówek online użytkownikom po zakupie (art. 6 ust.1 lit. b RODO).
        </li>
        <li style={listItemStyle}>
          B. <strong> Nasz uzasadniony interes: </strong> Przetwarzamy dane osobowe w celu realizacji naszych uzasadnionych interesów, takich jak doskonalenie usług, personalizacja doświadczenia użytkownika i zapewnienie funkcjonalności edycji wizytówek online po zalogowaniu (art. 6 ust.1 lit. f RODO).
        </li>
        <li style={listItemStyle}>
          C. <strong> Zgoda: </strong> Jeśli oferujemy dodatkowe opcje personalizacji wizytówek lub inne działania marketingowe, oparte na zgodzie użytkowników, przetwarzanie danych osobowych odbywa się na podstawie udzielonej zgody. Poprzez udostępnienie swoich danych osobowych i korzystanie z tych opcji, użytkownicy wyrażają dobrowolną zgodę na przetwarzanie ich danych osobowych. Użytkownicy mają prawo wycofać zgodę w dowolnym momencie, co nie wpływa na zgodność z prawem przetwarzania dokonanego przed wycofaniem zgody (art. 6 ust.1 lit. a RODO).
        </li>
      </ul>
      <h2 style={headingStyle}>Okres przechowywania danych osobowych</h2>

      <ul>
        <li style={listItemStyle}>
          A. Dane osobowe będą przetwarzane przez okres, w którym osoba pozostaje aktywnym Użytkownikiem Serwisu (posiada konto Użytkownika), a po tym czasie przez okres niezbędny do zachowania zgodności z przepisami prawa, dochodzenia lub obrony przed ewentualnymi roszczeniami, jednak nie dłużej niż 3 lata liczone od dnia rozwiązania umowy o świadczeniu usług drogą elektroniczną.
        </li>
        <li style={listItemStyle}>
          B. Dane przetwarzane na podstawie zgody będą przetwarzane do czasu wycofania udzielonej zgody z zastrzeżeniem, że wycofanie tej zgody nie ma wpływu na zgodność przetwarzania danych, którego dokonano przed tym wycofaniem.
        </li>
      </ul> <br></br>

      <h2 style={headingStyle}>Prawa osoby, której dane dotyczą</h2>

      <ul>
        <li style={listItemStyle}>
          A. <strong> Dostępu do swoich danych osobowych: </strong> możesz poprosić o informacje na temat danych osobowych, które gromadzimy i przetwarzamy dotyczące Ciebie.
        </li>
        <li style={listItemStyle}>
          B. <strong> Poprawiania swoich danych osobowych: </strong> jeśli Twoje dane są nieprawidłowe lub niekompletne, możesz poprosić o ich poprawienie lub uaktualnienie.
        </li>
        <li style={listItemStyle}>
          C. <strong> Usuwania swoich danych osobowych: </strong> w niektórych przypadkach możesz poprosić o usunięcie lub anonimizację swoich danych osobowych.
        </li>
        <li style={listItemStyle}>
          D. <strong> Ograniczania przetwarzania: </strong> w niektórych sytuacjach możesz poprosić o ograniczenie przetwarzania Twoich danych osobowych.
        </li>
        <li style={listItemStyle}>
          E. <strong> Przenoszenia danych: </strong> w przypadkach, gdy przetwarzamy Twoje dane na podstawie zgody lub w ramach wykonania umowy, możesz poprosić o otrzymanie swoich danych osobowych w strukturalnym, powszechnie używanym formacie.
        </li>
        <li style={listItemStyle}>
          F. <strong>Wycofywania zgody: </strong>  jeśli przetwarzamy Twoje dane na podstawie zgody, możesz ją w każdej chwili wycofać.
        </li>
      </ul> <br></br>
      <h2 style={headingStyle}>Ochrona danych osobowych</h2>

      <p>
        Zobowiązujemy się do zapewnienia odpowiedniego poziomu ochrony danych osobowych na naszej stronie z wizytówkami online. Wprowadziliśmy odpowiednie środki techniczne i organizacyjne, aby chronić Twoje dane przed nieuprawnionym dostępem, utratą, uszkodzeniem lub nieuprawnionym ujawnieniem.
      </p>

      <p>
        Dokładamy wszelkich starań, aby zapewnić bezpieczeństwo Twoich danych. W tym celu stosujemy szyfrowanie niektórych danych, wykorzystując zaawansowane technologie i protokoły bezpieczeństwa. Ponadto, nasza strona jest zabezpieczona certyfikatem SSL (Secure Sockets Layer), co oznacza, że komunikacja między Twoją przeglądarką a naszym serwerem jest szyfrowana i odbywa się w sposób bezpieczny.
      </p>

      <p>
        Nasi pracownicy i podwykonawcy są zobowiązani do przestrzegania poufności danych osobowych i są objęci odpowiednimi zabezpieczeniami, aby zapobiec nieuprawnionemu ujawnieniu lub wykorzystaniu danych.
      </p> <br></br>
      <h2 style={headingStyle}>Udostępnianie danych osobowych</h2>

      <p>
        Wizytówki online udostępniane na naszej stronie internetowej są publicznie dostępne w sieci. Oznacza to, że każdy, kto zna unikalny link, kod QR lub zeskanuje kartę NFC, może uzyskać dostęp do wizytówki Użytkownika.
      </p>

      <ul style={listItemStyle}>
        <li>
          <strong>A. Publiczne udostępnianie wizytówek:</strong> W przypadku wizytówek online, które są publicznie dostępne w sieci, dane osobowe są udostępniane publicznie i mogą być przeglądane przez innych użytkowników oraz osoby korzystające z naszej strony.
        </li>
        <li>
          <strong>B. Zgoda użytkownika:</strong> Przed udostępnieniem wizytówki online publicznie, wymagamy zgody użytkownika na udostępnienie i przetwarzanie jego danych osobowych w celu wyświetlania wizytówki na naszej stronie. Użytkownik ma możliwość udzielenia lub wycofania zgody w dowolnym momencie.
        </li>
        <li>
          <strong>C. Kontrola użytkownika:</strong> Zapewniamy użytkownikom możliwość zarządzania swoimi wizytówkami online, w tym edycję, aktualizację lub usunięcie danych osobowych zawartych na wizytówce. Użytkownik ma kontrolę nad tym, jakie informacje są udostępniane publicznie.
        </li>
        <li>
          <strong>D. Ograniczenia odpowiedzialności:</strong> Informujemy, że nie ponosimy odpowiedzialności za wykorzystanie danych osobowych udostępnionych publicznie przez użytkowników naszej strony. Każdy użytkownik powinien zachować ostrożność i rozważać, jakie informacje chce udostępnić publicznie.
        </li>
      </ul> <br></br>
      <h2 style={headingStyle}>Udostępniane publicznie dane w wizytówkach online na naszej stronie</h2>

      <p>
        Wizytówki online na naszej stronie internetowej są publicznie dostępne w sieci. Użytkownicy samodzielnie decydują, jakie dane chcą umieścić w swoich wizytówkach online. Mogą one zawierać różne kategorie danych udostępnionych przez użytkownika, takie jak:
      </p>

      <ul style={listItemStyle}>
        <li>
          <strong>a. Dane firmowe:</strong> Informacje dotyczące firmy, takie jak nazwa firmy, adres siedziby, numer identyfikacyjny NIP, numer REGON, opis działalności, imię i nazwisko przedsiębiorcy, numery rachunkowe. Użytkownicy mają możliwość wprowadzenia tych danych, które są istotne i potrzebne do prezentacji ich firmy na wizytówce online. Wprowadzenie tych informacji umożliwia innym użytkownikom i osobom korzystającym z naszej strony internetowej zapoznanie się z podstawowymi danymi dotyczącymi firmy.
        </li>
        <li>
          <strong>b. Dane personalne:</strong> Dane identyfikacyjne użytkownika, takie jak imię i nazwisko. Mogą również zawierać opis osoby, który użytkownik dobrowolnie udostępnia w wizytówce online. Opis osoby może zawierać informacje na temat doświadczenia zawodowego, osiągnięć, umiejętności, zainteresowań itp. Ten dodatkowy opis pomaga lepiej przedstawić użytkownika i jego działalność, jednak należy zachować ostrożność i nie udostępniać wrażliwych informacji, takich jak wrażliwe dane osobowe i poufne informacje. Użytkownicy są odpowiedzialni za zawartość opisu i powinni upewnić się, że nie narusza on prywatności ani praw osób trzecich. Przed umieszczeniem jakiejkolwiek informacji w opisie osoby warto przemyśleć, jakie dane są odpowiednie do publikacji publicznej i czy nie naruszają prywatności lub bezpieczeństwa.
        </li>
        <li>
          <strong>c. Dane kontaktowe:</strong> Informacje umożliwiające kontakt z Użytkownikiem, takie jak adres e-mail, numer telefonu, adres korespondencyjny itp. W celu zabezpieczenia tych danych i kontrolowania dostępu do nich, oferujemy opcję ustawienia czterocyfrowego PIN-u. Użytkownik ma możliwość skonfigurowania PIN-u, który musi zostać wpisany za każdym razem przed zapisaniem kontaktu. Dzięki temu dodatkowemu zabezpieczeniu, dostęp do danych kontaktowych jest ograniczony tylko do osób, które posiadają prawidłowy PIN. Użytkownicy są odpowiedzialni za zachowanie swojego PIN-u w sposób bezpieczny i poufny, aby zapobiec nieautoryzowanemu dostępowi do ich danych kontaktowych. Przy ustawianiu PIN-u zalecamy wybranie unikalnego i trudnego do odgadnięcia kodu, aby zapewnić maksymalne bezpieczeństwo swoich danych kontaktowych.
        </li>
        <li>
          <strong>d. Inne dane udostępnione przez Użytkownika:</strong> Oprócz podstawowych informacji, Użytkownik ma możliwość udostępnienia dodatkowych danych w swojej wizytówce online. Mogą to być takie elementy jak zdjęcie profilowe, linki do stron internetowych oraz profile społecznościowe. Użytkownik ma również możliwość dodania galerii, w której można umieścić trzy zdjęcia. Te dodatkowe informacje pozwalają Użytkownikom bardziej spersonalizować swoją wizytówkę i przedstawić swoje osiągnięcia, projekty lub działania w atrakcyjnej i zrozumiałej formie dla innych Użytkowników odwiedzających naszą stronę internetową.
        </li>
      </ul> <br></br>
      <h2 style={headingStyle}>Pliki cookies i technologie śledzące</h2>

      <p>
        Nasza strona internetowa korzysta z plików cookies i innych technologii śledzących w celu poprawy jakości świadczonych usług, personalizacji treści, analizy ruchu na stronie oraz śledzenia działań użytkowników. Pliki cookies są małymi plikami tekstowymi przechowywanymi na urządzeniu użytkownika (takim jak komputer, tablet czy smartfon), które gromadzą informacje o preferencjach, interakcjach i innych danych, umożliwiających dostosowanie i ulepszenie doświadczenia korzystania z wizytówek online.
      </p>

      <p>
        Poprzez wykorzystanie plików cookies i technologii śledzenia, możemy dostosowywać naszą ofertę, treści oraz rekomendacje do indywidualnych zainteresowań, preferencji i historii korzystania z wizytówek online. Analiza ruchu na stronie i śledzenie działań użytkowników pozwala nam lepiej zrozumieć, w jaki sposób korzystają oni z naszych wizytówek online, co umożliwia doskonalenie funkcjonalności, usprawnienie nawigacji oraz podniesienie jakości obsługi.
      </p>

      <p>
        Pragniemy podkreślić, że masz możliwość zarządzania ustawieniami plików cookies w przeglądarce internetowej. Możesz samodzielnie zaakceptować, odrzucić lub usunąć pliki cookies zgodnie z Twoimi preferencjami. Warto jednak mieć na uwadze, że wyłączenie lub ograniczenie plików cookies może mieć wpływ na funkcjonalność niektórych elementów naszej witryny internetowej. Może to utrudnić dostęp do niektórych funkcji lub ograniczyć personalizację treści, które dostarczamy do Ciebie.
      </p>
       <br></br>

      <h2 style={headingStyle}>Zmiany w polityce prywatności</h2>

      <p>Zastrzegamy sobie prawo do wprowadzania zmian w naszej polityce prywatności w dowolnym momencie. Aktualizacje polityki będą publikowane na naszej stronie internetowej, dlatego zalecamy regularne zapoznawanie się z tym dokumentem. 
        </p> 
      <p>
        Polityka prywatności została ostatnio zaktualizowana w dniu <strong>26.06.2023</strong>. 
      </p>

       <br></br>
      <h2 style={headingStyle}>Postanowienie końcowe</h2>

        <p>
        Niniejsza polityka prywatności ma na celu zapewnienie klarownych informacji dotyczących sposobu gromadzenia, wykorzystania i ochrony Twoich danych osobowych. Naszym priorytetem jest ochrona Twojej prywatności i zapewnienie bezpieczeństwa Twoich danych. Prosimy o dokładne zapoznanie się z treścią tej polityki w celu zrozumienia naszych praktyk i podejścia do ochrony Twoich danych.
        </p>

        <p>
        Chociaż podejmujemy wszelkie rozsądne środki w celu ochrony Twoich danych osobowych, należy pamiętać, że nie możemy zagwarantować absolutnego bezpieczeństwa tych danych. Przez korzystanie z naszej strony internetowej i dobrowolne udostępnianie nam swoich danych, zdajesz sobie sprawę, że czynisz to na własne ryzyko i akceptujesz ewentualne konsekwencje związane z tym działaniem. Nie ponosimy odpowiedzialności za bezpośrednie lub pośrednie szkody wynikające z utraty danych, naruszenia prywatności lub nieuprawnionego dostępu do Twoich danych, oraz za szkody wynikające z nieprawidłowego działania stron trzecich, z którymi nasza strona może być powiązana poprzez linki lub inne formy połączeń.
        </p>

        <p>
        Zachęcamy do regularnego zapoznawania się z naszą polityką prywatności w celu śledzenia ewentualnych zmian i aktualizacji. Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące naszej polityki prywatności, prosimy o kontakt z nami. Dziękujemy za zaufanie, jakim obdarzasz naszą stronę internetową i podejścia do ochrony danych osobowych.
        </p>
        <footer style={footerStyle}>
        &copy; {new Date().getFullYear()} TagU. 
      </footer>

        </div>
  )
}
  export default Terms;

