import React, { useState, useEffect } from 'react';
import VisitorView from './VisitorView';
import Login from './Login';
import Register from './Register';
import HomePage from './HomePage';
import BlockedPage from "./BlockedPage";
import { createBrowserRouter, RouterProvider, useParams,useNavigate } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import UserDashboard from './UserDashboard';
import axios from 'axios';
import PasswordRecovery from "./PasswordRecovery";
import ChangePass from "./ChangePass";
import Instruction from "./Instruction"
import Terms from "./Terms";
import Expired from "./Expired";
import SomethingWentWrong from './SomethingWentWrong';


function App() {
  /*wszyscy uzytkownicy */
  

  /*uzytkownik o id podanym w sciezce */
  
  
    
    /*generowanie sciezek sposrod wszystkich uzytkownikow za pomoca funkcji map - wczesniej pobralismy wszystkich uzytkownikow i przetrzymujemy ich w state */
    const router = createBrowserRouter([
      { path: "/", errorElement : <SomethingWentWrong/>, element: <HomePage  /> },
      { path: "/zaloguj", element: <Login   />, errorElement:<SomethingWentWrong/> },
      { path: "/rejestracja", element: <Register />, errorElement:<SomethingWentWrong/> },
      { path: "/panel-edycji", element: <UserDashboard   />, errorElement:<SomethingWentWrong/> },
      {path :"/users/:userID", element : <VisitorView   ></VisitorView>, errorElement:<SomethingWentWrong/>},
      {path :"/error", element : <ErrorPage ></ErrorPage>, errorElement:<SomethingWentWrong/>},
      { path: "*", element: <ErrorPage  />, errorElement:<SomethingWentWrong/>},
      {path: "/odzyskiwanie",element: <PasswordRecovery  />, errorElement:<SomethingWentWrong/>},
      {path : "/zmianahasla/:token", element : <ChangePass />, errorElement:<SomethingWentWrong/>},
      {path : "/zablokowany", element : <BlockedPage ></BlockedPage>, errorElement:<SomethingWentWrong/>},
      {path : "/instrukcja", element : <Instruction></Instruction>, errorElement:<SomethingWentWrong/>},
      {path: "/polityka-prywatnosci", element : <Terms></Terms>, errorElement:<SomethingWentWrong/>},
      {path : "/wygasniecie",element : <Expired></Expired>, errorElement:<SomethingWentWrong/>},
      
    ]);

    
  
    
    
  
    /* reszta kodu i return */
    return (
      
      <div className="App">
        
        
          <RouterProvider router={router}>
           
          </RouterProvider>
       
      </div>
      
    );
  }
  
  export default App;
