import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    storageBucket: "tagu-3f134.appspot.com", // Replace with your Firebase Storage bucket
  };

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Get the Firebase Storage instance
const storage = getStorage(app);

export { storage };