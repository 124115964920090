import React from 'react';
import { Link } from 'react-router-dom';

const BlockedPage = () => {
  const words = {
    pl: {
      title: '😮',
      heading: 'ZABLOKOWANY DOSTĘP',
      message: 'Użytkownik zablokował dostęp do wizytówki',
      linkText: 'Zamów swoją kartę',
    },
    en: {
      title: '😮',
      heading: 'BLOCKED ACCESS',
      message: 'The user has blocked access to the business card',
      linkText: 'Order your card',
    },
  };

  const language = localStorage.getItem('language') || 'pl';
  const { title, heading, message, linkText } = words[language];

  return (
    <div translate="no">
      <div className="vertical-center">
        <div className="container">
          <div id="notfound" className="text-center">
            <h1>{title}</h1>
            <h2>{heading}</h2>
            <p>{message}</p>
            <Link to="/">{linkText}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockedPage;