import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Login.css";
import { useNavigate, Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
const Login = () => {
  
 
  const [user, setUser] = useState({
    email : "",
    password :""
  })
  const navigate = useNavigate();
  const notify = (errorMessage) => {
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  const notify2 = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
  const handleChange  =  (event) => {
    
    const {name,value} = event.target
    setUser({
      ...user,
      [name]:value
    })

  }
  const [language,setLanguage] = useState('pl');
  useEffect(() => {
    
    
    const message = localStorage.getItem("message");
    const messageRegister = localStorage.getItem("messageRegister");
    if (message) {
      notify2(message);
      localStorage.removeItem("message");
    }
    if (messageRegister) {
      notify2(messageRegister);
      localStorage.removeItem("messageRegister");
    }
  }, []);

  const words = {
    pl: {
      wrongData : "Nieprawidłowy email lub hasło!",
headerLogin : "Zaloguj się do swojej wizytówki",
enterEmail : "Wpisz adres email",
pass : "Hasło",
placeholderPass : "Wpisz hasło",
loginButton : "Zaloguj się",
forgot: "Zapomniałeś hasła?",
reset : "Resetuj hasło"

    },
    en: {
  wrongData: "Invalid email or password!",
  headerLogin: "Log in to your business card",
  enterEmail: "Enter email address",
  pass: "Password",
  placeholderPass: "Enter password",
  loginButton: "Log in",
  forgot: "Forgot password?",
  reset: "Reset password"
      
    }
  };

  useEffect(() => {
    const languageFromLocalStorage = localStorage.getItem("language");
    if (languageFromLocalStorage) {
      setLanguage(languageFromLocalStorage);
    }
  }, []);

  const handleChangeLanguage = (language) => {
    // Tutaj umieść kod obsługujący zmianę języka
    setLanguage(language);
    localStorage.setItem("language",language);
    
    

  };
  

  
  
  const login = (event) => {
    event.preventDefault();
    

    



    
  
    
    const lowercaseUser = {
      ...user,
      email: user.email.toLowerCase()
    };
    axios.post("https://api.tagu.pl/login", lowercaseUser, {
      
    })
      .then(res => {
        if (res.data.status === true) {
          localStorage.setItem("token", res.data.token);
          navigate('/panel-edycji');
        } else {
          notify(words[language].wrongData);
        }
      });
  };

  
  const backToPage = (event) =>{
    event.preventDefault();
    navigate(-1)
  }


  return (
    
    <div translate="no" className="Auth-form-container" style={{ backgroundColor: '#F5F5F5' }}>
     
  <form onSubmit={login} className="Auth-form" style={{ backgroundColor: '#EBEBEB' }}>
  <div style={{ marginLeft:"10px",marginBottom:"10px" }}>
    <img
      src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fpolandflag.png?alt=media&token=8174f61e-4c20-4e83-a7ec-9f14c4e2c552"
      alt="Polish"
      style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
      onClick={() => handleChangeLanguage('pl')}
    />
    <img
      src="https://firebasestorage.googleapis.com/v0/b/tagu-3f134.appspot.com/o/tag%2Fenglandflag.png?alt=media&token=3149d47d-3619-4e59-8dad-7b61ce48bbf0"
      alt="English"
      style={{ width: '25px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
      onClick={() => handleChangeLanguage('en')}
    />
  </div>
  <a style={{border:"none", marginLeft:"25px" }} onClick={backToPage}>
      <FontAwesomeIcon icon={faArrowLeft}/>
      </a>
      
    <div className="Auth-form-content" style={{ backgroundColor: '#EBEBEB' }}>
     
      <h3 className="Auth-form-title">{words[language].headerLogin}</h3>
      <div className="form-group mt-3">
        <label>E-mail</label>
        <input
          name="email"
          type="email"
          className="form-control mt-1"
          placeholder={words[language].enterEmail}
          value={user.email}
          onChange={handleChange}
        />
      </div>

      <div className="form-group mt-3">
        <label>{words[language].pass}</label>
        <input
          name="password"
          type="password"
          className="form-control mt-1"
          placeholder={words[language].placeholderPass}
          value={user.password}
          onChange={handleChange}
        />
      </div>

      <div className="d-grid gap-2 mt-3">
        <input value={words[language].loginButton}  type="submit" className="btn btn-primary" style={{ backgroundColor: '#001014', borderColor: '#001014' }}>
          
        </input>
        <p className="forgot-password mt-3">
          {words[language].forgot} <Link to="/odzyskiwanie">{words[language].reset}</Link>
        </p>
        
        


        
      </div>
    </div>
  </form>
      <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
limit = "4"
pauseOnHover
theme="dark"
/>
    </div>
  );
};

export default Login;